
import MxInfoModal from '@/components/global/MxInfoModal.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
    name: 'MxInfoButton',
    components: {
        MxInfoModal
    }
})
export default class MxInfoButton extends Vue {

    /* Props
    ============================================*/

    @Prop({type: String, required: false, default: '#EEE'})
    readonly color: string;

    @Prop({type: String, required: false, default: '#777'})
    readonly textColor: string;

    @Prop({type: String, required: true})
    readonly modalTitle: string;

    @Prop({type: [String, Number], required: false, default: '600px'})
    readonly modalWidth: string | number;

    /* Data
    ============================================*/

    showInfoModal: boolean = false;

}

