// <copyright file="SupportRequest.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { IFeedbackRequest, FeedbackType } from '@/types/resource.types';

export class FeedbackRequest implements IFeedbackRequest {
    id: number;
    userId: number;
    projectFileId: number;
    type: FeedbackType;
    feedback: string = '';
    isHelpful?: Boolean;
}
