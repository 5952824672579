// <copyright file="http.utils.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

/**
 * Determines if
 * @param response Fetch response
 */
export function isJsonResponse(response: Response): boolean {
    return (response.headers.get('content-type')?.indexOf('application/json') ?? -1) > -1;
}
