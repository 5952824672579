// <copyright file="BackendService.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import AuthJsonRequestFactory from '@/http/AuthJsonRequestFactory';
import BackendErrorHandler from '@/http/BackendErrorHandler';
import BackendJsonRequestFactory from '@/http/BackendJsonRequestFactory';
import BackendMultipartFormRequestFactory from '@/http/BackendMultipartFormRequestFactory';
import FetchClient from '@/http/FetchClient';
import { IFetchClient } from '@/types/http.types';

/**
 * Base class for backend services
 */
export abstract class BackendService {

    protected readonly jsonClient: IFetchClient;
    protected readonly formClient: IFetchClient;
    protected readonly authClient: IFetchClient;

    constructor(
        jsonClient: IFetchClient = new FetchClient(
            new BackendJsonRequestFactory(),
            new BackendErrorHandler()
        ),
        authClient: IFetchClient = new FetchClient(
            new AuthJsonRequestFactory(),
            new BackendErrorHandler()
        ),
        formClient: IFetchClient = new FetchClient(
            new BackendMultipartFormRequestFactory(),
            new BackendErrorHandler()
        )
    ) {
        this.jsonClient = jsonClient;
        this.formClient = formClient;
        this.authClient = authClient;
    }

}
