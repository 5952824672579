// <copyright file="ProjectFileService.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import BackendMultipartFormRequestFactory from '@/http/BackendMultipartFormRequestFactory';
import ProjectFileUploadQueue from '@/upload/ProjectFileUploadQueue';
import ResourceService from './ResourceService';
import { appendQueryParams } from '@/utils/string.utils';
import { createChunkFormData } from '@/utils/file.utils';
import {
    FileChunkStatus,
    FileStatus,
    IFileExaminationReportOptions,
    IFileExaminationResult,
    IFileSignature,
    IFileStructureData,
    IProjectFile,
    IProjectFileChunk,
    IProjectFileComment,
    IProjectFileRating,
    IPublicFileLog, 
    IPathEvaluationResult
} from '@/types/resource.types';
import { IPaginatedRequest, IPaginatedResult } from '@/types/services.types';

export class ProjectFileService extends ResourceService<IProjectFile> {

    constructor() {
        super('/projectfile');
    }

    public async getForUser(userId: number | string, req: IPaginatedRequest)
    : Promise<IPaginatedResult<IProjectFile>> {
        let url = appendQueryParams(`/user/${userId}/projectfiles`, req);
        let res = await this.jsonClient.get<IPaginatedResult<IProjectFile>>(url);
        return res.data;
    }

    /* Uploading
    ============================================*/

    public async uploadFileChunk(
        chunk: IProjectFileChunk,
        uploadQueue?: ProjectFileUploadQueue,
        signal?: AbortSignal,
    ): Promise<IProjectFileChunk> {
        let reqFactory = new BackendMultipartFormRequestFactory();
        try {
            uploadQueue?.increment();
            let formData = createChunkFormData(chunk);
            let url = `${this.path}/${chunk.fileId}/upload`;
            let request = reqFactory.create(url, 'POST', formData);
            await fetch(request, { signal });
            uploadQueue?.decrement();
        } catch(e) {
            chunk.status = FileChunkStatus.Error;
            uploadQueue?.decrement();
            throw e;
        }
        return chunk;
    }

    public async completeUpload(file: IProjectFile): Promise<IProjectFile> {
        let url = `${this.path}/${file.id}/complete`;
        let res = await this.jsonClient.post<IProjectFile>(url, file);
        file.status = FileStatus.UploadComplete;
        return res.data;
    }

    /* Analysis
    ============================================*/

    public async getAnalysisResult(fileId: number | string): Promise<IFileExaminationResult> {
        let url = `${this.path}/${fileId}/examination-result`;
        let res = await this.jsonClient.get<IFileExaminationResult>(url);
        return res.data;
    }

    public async generateExaminationReportPdf(
        url: string,
        options: IFileExaminationReportOptions
    ): Promise<any> {
        let res = await this.jsonClient.get(url);
        return res.dataText;
    }

    public async requestAdvancedAnalysis(fileId: number): Promise<void> {
        let url = `${this.path}/${fileId}/examination-result/advanced-analysis`;
        await this.jsonClient.post<void>(url, {});
    }

    public async getPublicFileLog(fileId: number): Promise<IPublicFileLog[]> {
        let url = `${this.path}/${fileId}/log`;
        let res = await this.jsonClient.get<IPublicFileLog[]>(url);
        return res.data;
    }

    public async getSignature(fileId: number | string): Promise<IFileSignature> {
        let url = `${this.path}/${fileId}/signature-structure`;
        let res = await this.jsonClient.get<IFileSignature>(url);
        return res.data;
    }

    public async getStructuralReport(fileId: number | string): Promise<string> {
        let url = `${this.path}/${fileId}/structural-report`;
        let res = await this.jsonClient.get<string>(url);
        return res.dataText as string;
    }
    public async getC2PAManifest(fileId: number | string): Promise<string> {
        console.log(fileId);
        let url = `${this.path}/${fileId}/c2pa-manifest`;
        let res = await this.jsonClient.get<string>(url);
        return res.dataText as string;
    }
    public async getAutomatedAnalysis(fileId: number | string): Promise<IPathEvaluationResult> {
        console.log(fileId);
        let url = `${this.path}/${fileId}/automated-analysis`;
        let res = await this.jsonClient.get<IPathEvaluationResult>(url);
        return res.data;
    }

    public async getProprietaryStructure(fileId: number | string, structureId: number | string)
    : Promise<IFileStructureData> {
        let url = `${this.path}/${fileId}/proprietary-structure/${structureId}`;
        let res = await this.jsonClient.get<IFileStructureData>(url);
        return res.data;
    }

    public async getUnknownStructure(fileId: number | string, structureId: number | string)
    : Promise<IFileStructureData> {
        let url = `${this.path}/${fileId}/unknown-structure/${structureId}`;
        let res = await this.jsonClient.get<IFileStructureData>(url);
        return res.data;
    }

    /* File Comments
    ============================================*/

    public async getComments(fileId: number, query: IPaginatedRequest)
    : Promise<IPaginatedResult<IProjectFileComment>> {
        let path = this._createCommentPath(fileId);
        let url = appendQueryParams(path, query);
        let res = await this.jsonClient.get<IPaginatedResult<IProjectFileComment>>(url);
        return res.data;
    }

    public async addComment(comment: IProjectFileComment)
    : Promise<IProjectFileComment> {
        let url = this._createCommentPath(comment.projectFileId);
        let res = await this.jsonClient.post<IProjectFileComment>(url, comment);
        return res.data;
    }

    public async updateComment(comment: IProjectFileComment)
    : Promise<IProjectFileComment> {
        let url = this._createCommentPath(comment.projectFileId, comment.id);
        let res = await this.jsonClient.put<IProjectFileComment>(url, comment);
        return res.data;
    }

    public async deleteComment(comment: IProjectFileComment): Promise<boolean> {
        let url = this._createCommentPath(comment.projectFileId, comment.id);
        let res = await this.jsonClient.delete(url);
        return res.ok;
    }

    private _createCommentPath(fileId: number | string, commentId?: number | string): string {
        let path = `${this.path}/${fileId}/comment`;
        return commentId ? `${path}/${commentId}` : path;
    }

    /* File Ratings
    ============================================*/
    public async addRating(rating: IProjectFileRating)
    : Promise<IProjectFileRating> {
        let path = `${this.path}/${rating.projectFileId}/rating`;
        let res = await this.jsonClient.post<IProjectFileRating>(path, rating);
        return res.data;
    }

    public async updateRating(rating: IProjectFileRating)
    : Promise<IProjectFileRating> {
        let path = `${this.path}/${rating.projectFileId}/rating/${rating.id}`;
        let res = await this.jsonClient.put<IProjectFileRating>(path, rating);
        return res.data;
    }
}

// Default "singleton" instance
export default new ProjectFileService();
