// <copyright file="json.utils.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

/**
 * Copies a value using JSON.stringify and JSON.parse
 * @param value - The value to copy
 */
export function jsonCopy<T>(value: T): T {
    return JSON.parse(JSON.stringify(value)) as T;
}

/**
 * Checks if two values are equal when JSON.stringify'd
 * @param objA - Any value
 * @param objB - The value to compare
 */
export function jsonEquals(objA: any, objB: any): boolean {
    return JSON.stringify(objA) === JSON.stringify(objB);
}
