
import FileFlagStatusChip from './FileFlagStatusChip.vue';
import fileService from '@/services/ProjectFileService';
import FileStatusChip from './FileStatusChip.vue';
import HasDataTable from '@/components/hoc/HasDataTable';
import MxDataTable from '@/components/global/MxDataTable.vue';
import UserGroupSelector from '@/components/users/UserGroupSelector.vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { FileAquisitionMethod, FileStatus, IProjectFile } from '@/types/resource.types';
import { IPaginatedRequest, IPaginatedResult } from '@/types/services.types';
import { paths } from '@/router/route-paths';

@Component({
    name: 'ProjectsTable',
    components: {
        FileStatusChip,
        FileFlagStatusChip,
        MxDataTable,
        UserGroupSelector
    }
})
export default class ProjectsTable extends HasDataTable {

    /* Props
    ============================================*/

    @Prop({type: Object, required: false})
    readonly initialRequest: IPaginatedRequest;

    @Prop({type: Boolean, required: false})
    readonly userOnly: boolean;

    /* Data
    ============================================*/

    processingComplete: FileStatus = FileStatus.ProcessingComplete;
    loadingFiles: boolean = false;
    filesResult: IPaginatedResult<IProjectFile> | null = null;
    userFilesOnly: boolean = true;

    /* Computed
    ============================================*/

    get filterByUser(): boolean {
        return this.userFilesOnly || this.userOnly;
    }

    get headers() {
        return fileTableHeadersSmall;
    }

    /* Methods
    ============================================*/

    getItemClass(file: IProjectFile) {
        if(file.status === FileStatus.ProcessingComplete) return 'pointer';
        return '';
    }

    goToFile(file: IProjectFile) {
        if(!file.id) return;
        if(file.status !== FileStatus.ProcessingComplete) return;
        this.$router.push(paths.FILE_ANALYSIS(file.id));
    }

    goToProject(file: IProjectFile) {
        if(file.status === FileStatus.Draft) {
            this.$router.push(paths.PROJECT_DRAFT(file.projectId));
        }
        this.$router.push(paths.PROJECT_SUMMARY(file.projectId));
    }

    async searchFiles(req: IPaginatedRequest) {
        try {
            this.loadingFiles = true;
            let filesResult = this.filterByUser
                ? await fileService.getForUser(this.$user.id, req)
                : await fileService.getList(req);
            this.filesResult = filesResult;
        } catch(e) {
            this.$logger.logError(e);
            this.$toast.error('There was a problem loading the file list. Please try again.');
        } finally {
            this.loadingFiles = false;
        }
    }

    getFileSource(aquisitionType: FileAquisitionMethod) {
        switch(aquisitionType) {
            case FileAquisitionMethod.DirectUpload:
                return 'Upload';
            case FileAquisitionMethod.DirectUrl:
                return 'Url';
            case FileAquisitionMethod.YouTubeDL:
                return 'YouTube';
            case FileAquisitionMethod.Facebook:
                return 'Facebook';
            default:
                return 'Direct Upload';
        }
    }

    @Watch('userFilesOnly')
    onUserFilesOnlyChanged() {
        this.reloadTable();
    }

}

export const fileTableHeadersSmall: DataTableHeader<IProjectFile>[] = [
    {
        text: 'Uploaded',
        value: 'created',
        width: 120
    },
    {
        text: 'Filename',
        value: 'originalFilename'
    },
    {
        text: 'Flag Status',
        value: 'flagStatus',
        width: 120
    },
    {   align: 'end',
        text: 'Status',
        value: 'status',
        width: 130
    }
];

