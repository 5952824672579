// <copyright file="PermissionService.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { BackendService } from './BackendService';
import { IGroupPermissionDetails } from '@/types/resource.types';

export class PermissionService extends BackendService {

    public async getAll() : Promise<IGroupPermissionDetails[]> {
        let res = await this.jsonClient.get<IGroupPermissionDetails[]>('/permission');
        return res.data;
    }

}

// Default "singleton" instance
export default new PermissionService();
