
import MxInfoButton from '@/components/global/MxInfoButton.vue';
import FeedbackForm from './FeedbackForm.vue';
import BetaChip from '../BetaChip.vue';
import MxModal from '@/components/global/MxModal.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { FeedbackType, IMedexInsight, MedexInsightType, IPathEvaluationResult } from '@/types/resource.types';
import fileService from '@/services/ProjectFileService';
import ProjectFileExaminationView from './ProjectFileExaminationView.vue';

@Component({
    name: 'InsightsCard',
    components: {
        MxInfoButton,
        MxModal,
        FeedbackForm,
        BetaChip
    }
})
export default class InsightsCard extends Vue {

    /* Props
    ============================================*/
    @Prop({type: Array, required: true})
    readonly insights: IMedexInsight[];

    @Prop({type: Boolean, required: true})
    readonly showFeedbackForm: boolean = true;

    @Prop({type: Number, required: true})
    readonly projectFileId: number;

    /* Computed
    ============================================*/

    /* Data
    ============================================*/
    structuralIdentifiers: String = "None";
    feedbackType : FeedbackType = FeedbackType.MedexInsights;
    showModal: boolean = false;
    analysis: IPathEvaluationResult | undefined = undefined;
    /* Methods
    ============================================*/
    getDescription(type: MedexInsightType){
        switch(type){
            case MedexInsightType.BRAND_INDICATOR:
                return "Brand Indicator";
            case MedexInsightType.DEVICE_INDICATOR:
                return "Device Indicator";
            case MedexInsightType.LAST_GENERATION_INDICATOR:
                return "Last Generation Indicator";
            case MedexInsightType.NOT_ORIGINAL_DEVICE_INDICATOR:
                return "Camera Original Indicator";
            case MedexInsightType.AUTOMATED_INSIGHT:
                return "Automated Insight";

        }
    }
    getPassFail(value : boolean | undefined){
        if(value === true) return "Pass";
        return "Fail"
    }
    get indicators(){
        return this.insights.filter((x)=>x.insightType != MedexInsightType.AUTOMATED_INSIGHT);
    }
    get showInsights(){
        return this.insights  && this.insights.length > 0;
    }
    get getAutomatedInsight(){
        if(!this.showInsights) return false;
        for(let i = 0; i < this.insights.length; i++){
            let insight = this.insights[i];
            if(insight.insightType == MedexInsightType.AUTOMATED_INSIGHT) return insight;
        }
        return;
    }
    async loadAnalysis(){
        try{
            if(!this.analysis){
                this.$loading = true;
                this.analysis = await fileService.getAutomatedAnalysis(this.projectFileId);
                console.log(this.analysis);
            }
            this.showModal = true;
            console.log("Show Modal");
        } 
        catch(e) {
            this.$logger.logError(e);
        } finally {
            this.$loading = false;
        }
    }
}

