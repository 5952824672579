// <copyright file="ProjectService.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import BackendMultipartFormRequestFactory from '@/http/BackendMultipartFormRequestFactory';
import ResourceService from './ResourceService';
import { appendQueryParams } from '@/utils/string.utils';
import { IPaginatedRequest, IPaginatedResult } from '@/types/services.types';
import { IProject, IProjectFile, IProjectSummary, IProjectFileResultSummary } from '@/types/resource.types';

export class ProjectService extends ResourceService<IProject> {

    constructor() {
        super('/project');
    }

    public async getForUser(userId: number | string, req: IPaginatedRequest)
    : Promise<IPaginatedResult<IProject>> {
        let url = appendQueryParams(`/user/${userId}/projects`, req);
        let res = await this.jsonClient.get<IPaginatedResult<IProject>>(url);
        return res.data;
    }

    async submitProject(project: IProject): Promise<IProject> {
        let url = `/project/${project.id}/submit`;
        let res = await this.jsonClient.put<IProject>(url, project);
        return res.data;
    }

    async getSummary(projectId: string | number): Promise<IProjectSummary> {
        let url = `/project/${projectId}/summary/`;
        let res = await this.jsonClient.get<IProjectSummary>(url);
        return res.data;
    }

    async getSummaryFiles(projectId: number | string, req: IPaginatedRequest)
    : Promise<IPaginatedResult<IProjectFileResultSummary>> {
        let url = `/project/${projectId}/files/results`;
        url = appendQueryParams(url, req);
        let res = await this.jsonClient.get<IPaginatedResult<IProjectFileResultSummary>>(url);
        return res.data;
    }

    async uploadProjectFileMetadata(
        projectId: number,
        file: File,
        signal?: AbortSignal
    ): Promise<IProjectFile> {
        let reqFactory = new BackendMultipartFormRequestFactory();
        let url = `/project/${projectId}/file`;
        let formData = new FormData();
        formData.append('file', file);
        let req = reqFactory.create(url, 'POST', formData);
        let res = await fetch(req, { signal });
        let data = await res.json() as IProjectFile;
        return data;
    }

}

// Default "singleton" instance
export default new ProjectService();
