
import authService from '@/services/AuthService';
import HasForm from '@/components/hoc/HasForm';
import MxLogo from '@/components/global/MxLogo.vue';
import { Component } from 'vue-property-decorator';
import { paths } from '@/router/route-paths';

@Component({
    name: 'ResetPasswordView',
    components: {
        MxLogo
    }
})
export default class ResetPasswordView extends HasForm {

    /* Data
    ============================================*/

    confirmPassword: string = '';
    loading: boolean = false;
    password: string = '';
    submitted: boolean = false;
    paths = paths;
    token: string = '';
    userId: number = 0;

    /* Login Methods
    ============================================*/

    async submitForm(): Promise<void> {
        try {
            this.userId = parseInt((this.$route.query?.userid ?? '0') as string);
            this.token = this.$route.query?.token as string;
            this.loading = true;
            await authService.resetPassword(this.userId, this.token, this.password);
            this.submitted = true;
        } catch(error) {
            this.$toast.error('An unexpected error occurred. Please try again later.');
            this.$logger.logError(error, 'error', {
                userId: this.userId,
                token: this.token ?? null
            });
        } finally {
            this.loading = false;
        }
    }

    confirmPasswordRule(value: string): boolean | string {
        if(this.password !== value) {
            return 'Passwords do not match';
        }
        return true;
    }

}

