
import * as validationRules from '@/utils/validation.utils';
import MxModal from '@/components/global/MxModal.vue';
import userGroupService from '@/services/UserGroupService';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Get } from 'vuex-pathify';
import { IUserGroup } from '@/types/resource.types';
import { VForm } from '@/types/vue.types';

@Component({
    name: 'UserGroupEditModal',
    components: {
        MxModal
    }
})
export default class UserGroupEditModal extends Vue {

    $refs!: {
        _form: VForm;
    }

    @Prop({type: Object as () => IUserGroup, required: false})
    readonly group: IUserGroup;

    @Prop({type: Boolean, required: false})
    readonly value: boolean;

    /* Computed
    ============================================*/

    @Get('userGroups')
    readonly userGroups: IUserGroup[];

    get isNew(): boolean {
        return !this.group?.id;
    }

    get title(): string {
        if(this.isNew) return 'Add New User Group';
        return 'Edit User Group';
    }

    /* Data
    ============================================*/

    loading: boolean = false;

    /* Methods
    ============================================*/

    closeModal(): void {
        this.$emit('input', false);
    }

    permissionsRule(value: number[]): string | true {
        if(value?.length) return true;
        return 'Please choose at least one permission for this group';
    }

    requiredRule(value: string): string | true {
        return validationRules.required(value);
    }

    async saveGroup(): Promise<void> {
        try {
            if(!this.$refs._form.validate()) return;
            this.loading = true;
            if(this.isNew) {
                await userGroupService.create(this.group);
                this.$store.dispatch('getUserGroups');
            } else {
                await userGroupService.update(this.group.id, this.group);
            }
            this.closeModal();
            this.$emit('saved');
        } catch(e) {
            this.$toast.error('Something went wrong. Please try again.');
            this.$logger.logError(e);
        } finally {
            this.loading = false;
        }
    }

}

