// <copyright file="validation.utils.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

export function required(value: string) : string | true {
    if(!value) {
        return 'This field is required';
    };
    return true;
}

export function email(email: string) : string | true {
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(email) === false) {
        return 'Please enter a valid email address';
    };
    return true;
}

export function phone(value: string) : string | true {
    let re = /^(\+?1-?)?(\([2-9]([02-9]\d|1[02-9])\)|[2-9]([02-9]\d|1[02-9]))-?[2-9]([02-9]\d|1[02-9])-?\d{4}$/;
    value = value.replace(/\s+/g, '');
    if(value.length <= 9 || re.test(value) === false) {
        return 'Please enter a valid phone number';
    };
    return true;
}

export function min(value: string, limit: number) : string | true {
    limit = Number(limit);
    if(value.length < limit) {
        return `Must be at least ${limit} characters`;
    };
    return true;
}

export function max(value: string, limit: number) : string | true {
    limit = Number(limit);
    if(value.length < limit) {
        return `Must be no more than ${limit} characters`;
    };
    return true;
}

export function exact(value: string, limit: number) : string | true {
    limit = Number(limit);
    if(value.length !== limit) {
        return `Must be exactly ${limit} characters`;
    };
    return true;
}

export function zip(value: string) : string | true {
    let re = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    if(re.test(value) === false) {
        return 'Please enter a valid Zip Code';
    };
    return true;
}

export function url(value: string) : string | true {
    let re = /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    if(re.test(value) === false) {
        return 'Please enter a valid URL';
    };
    return true;
}

export function regex(value: string, regexString: RegExp, errorMessage: string = '') : string | true {
    let regex = new RegExp(regexString);
    if(regex.test(value) === false) {
        return errorMessage || 'Invalid format';
    };
    return true;
}

export function password(value: string) : string | true {
    if(value.length < 8) {
        return 'Password must be at least 8 characters long';
    }
    //let regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/;
    let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;
    //(?=.*[^\da-zA-Z])
    if(!regex.test(value)) {
        return 'Password must have at least one letter, number, and special character';
    }
    return true;
}

export function medexFile(value: string) : string | true {
    if(!value || typeof value !== 'string') return 'File type is unsupported';
    if(!value.toLowerCase().match(/^.*\.(mp4|m4v|mov|3gp|3g2|avi|wmv|asf)$/)) return 'File type is unsupported';
    return true;
}
