
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { createBreadcrumbs } from '@/utils/router.utils';
import { IBreadcrumb } from '@/types/router.types';
import { paths } from '@/router/route-paths';
import { Route } from 'vue-router';
import { routeConfigs } from '@/router/routes';

@Component({
    name: 'Breadcrumbs',
})
export default class Breadcrumbs extends Vue {

    /* Data
    ============================================*/

    crumbs: IBreadcrumb[] = [];
    paths = paths;

    /* Lifecycle Hooks
    ============================================*/

    beforeMount() {
        this.crumbs = createBreadcrumbs(this.$route, routeConfigs);
    }

    /* Watchers
    ============================================*/

    @Watch('$route')
    onActiveChanged($routeNew: Route, $routeOld: Route) {
        if($routeNew?.name === $routeOld?.name) return;
        this.crumbs = createBreadcrumbs($routeNew, routeConfigs);
    }

}

