import { render, staticRenderFns } from "./FileOriginatingDevice.vue?vue&type=template&id=4ef0d5d2&scoped=true"
import script from "./FileOriginatingDevice.vue?vue&type=script&lang=ts"
export * from "./FileOriginatingDevice.vue?vue&type=script&lang=ts"
import style0 from "./FileOriginatingDevice.vue?vue&type=style&index=0&id=4ef0d5d2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ef0d5d2",
  null
  
)

export default component.exports