// <copyright file="HasForm.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import Component from 'vue-class-component';
import Vue from 'vue';
import { VForm } from '@/types/vue.types';

/**
 * Higher order component for with a validatable form reference
 */
@Component
export default class HasForm extends Vue {

    $refs!: {
        _form: VForm;
    }

    formIsValid: boolean = false;

    /* Methods
    ============================================*/

    validateForm(): boolean {
        return this.$refs._form.validate();
    }

}
