
import MxModal from '@/components/global/MxModal.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
    name: 'MxInfoModal',
    components: {
        MxModal
    }
})
export default class MxInfoModal extends Vue {

    /* Props
    ============================================*/

    /**
     * Optional modal title
     */
    @Prop({type: String, required: true})
    readonly title: string;

    /**
     * Show loading bar
     */
    @Prop({type: Boolean, required: false})
    readonly loading: boolean;

    @Prop({type: [String, Number], required: false, default: '600px'})
    readonly maxWidth: string | number;

}

