// <copyright file="ProjectUploadHandler.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import logger from '@/log/AppLogger';
import ProjectFileUploader from './ProjectFileUploader';
import ProjectFileUploadQueue from '@/upload/ProjectFileUploadQueue';
import {
    FileAquisitionMethod,
    FileUploadProgress,
    FileUploadStatus,
    IProject,
    IProjectFile,
} from '@/types/resource.types';
import { ProjectFile } from '@/models/ProjectFile';
import { ProjectFileService } from '@/services/ProjectFileService';

export default class ProjectUploadHandler {

    private _files: File[];
    private _fileService = new ProjectFileService()
    private _project: IProject;
    private _uploaders: ProjectFileUploader[] = [];
    private _started: boolean = false;

    constructor(project: IProject, files: File[]) {
        this._files = files;
        this._project = project;
    }

    public async doUpload(): Promise<IProjectFile[] | false> {
        if(this._started) return false;
        this._started = true;
        logger.trace('Starting upload(s)');
        this._uploaders = await this._createUploaders();
        logger.trace('Created upload(s)');
        await this._uploadFiles();
        let results = this._uploaders
            .filter(x => x.getStatus() === FileUploadStatus.Complete)
            .map(x => x.getProjectFile());
        return results;
    }

    public getProgress(): FileUploadProgress[] {
        return this._uploaders.map(upload => upload.getProgress());
    }

    public cancelUploads(): void {
        this._uploaders.forEach(x => x.cancel());
        this._uploaders = [];
    }

    /* Private Methods
    ============================================*/

    private _createUploaders(): Promise<ProjectFileUploader[]> {
        let tasks = [];
        let queue = new ProjectFileUploadQueue();
        for(let i=0; i<this._files.length; i++) {
            tasks.push(this._createUploader(this._files[i], queue));
        }
        return Promise.all(tasks);
    }

    private async _createUploader(file: File, queue: ProjectFileUploadQueue): Promise<ProjectFileUploader> {
        let { id } = this._project;
        let projectFile: IProjectFile = new ProjectFile(id, file, FileAquisitionMethod.DirectUpload);
        projectFile = await this._fileService.create(projectFile);
        return new ProjectFileUploader(file, projectFile, queue);
    }

    private async _uploadFiles(): Promise<IProjectFile[]> {
        return await Promise.all(this._uploaders.map(uploader => {
            return new Promise<IProjectFile>((resolve) => {
                uploader.onCompleteOrError(() => {
                    resolve(uploader.getProjectFile());
                });
                uploader.start();
            });
        }));
    }

}
