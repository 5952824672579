
import CreateProjectDetails from './CreateProjectDetails.vue';
import CreateProjectForm from './CreateProjectForm.vue';
import projectService from '@/services/ProjectService';
import UploadProjectFilesModal from './upload/UploadProjectFilesModal.vue';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { IProject } from '@/types/resource.types';
import { paths } from '@/router/route-paths';
import { Project } from '@/models/Project';
import { Sync } from 'vuex-pathify';

@Component({
    name: 'CreateProjectView',
    components: {
        CreateProjectDetails,
        CreateProjectForm,
        UploadProjectFilesModal
    }
})
export default class CreateProjectView extends Vue {

    /* Props
    ============================================*/

    @Sync('create-project/editing')
    editing: boolean;

    @Sync('create-project/project')
    project: IProject;

    /* Data
    ============================================*/

    loading: boolean = false;
    paths = paths;
    showUploadModal: boolean = false;

    /* Computed
    ============================================*/

    get isDraft(): boolean {
        return !!this.project?.id;
    }

    get showEditForm(): boolean {
        if(this.loading) return false;
        if(this.editing) return true;
        return this.project && !this.project.id;
    }

    /* Methods
    ============================================*/

    addFiles() {
        this.showUploadModal = true;
    }

    createNewProject() {
        let project = new Project();
        let { firstName, lastName } = this.$user;
        project.userId = this.$user.id;
        project.examinerName = `${firstName} ${lastName}`;
        this.project = project;
    }

    async loadProject() {
        if(this.$route.params?.id) {
            this.loadDraftProject();
        } else {
            this.createNewProject();
        }
    }

    async loadDraftProject() {
        try {
            this.loading = true;
            this.project = await projectService.get(this.$route.params.id);
        } catch(e) {
            this.$toast.error('An error occurred while loading the project. Please try again.');
            this.$router.push(paths.PROJECTS);
        } finally {
            this.loading = false;
        }
    }

    /* Lifecycle Hooks
    ============================================*/

    async mounted() {
        this.loadProject();
    }

}

