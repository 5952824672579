
import FileModificationStatusChip from '@/components/projects/summary/FileModificationStatusChip.vue';
import SignatureMatchTypeChip from '@/components/projects/summary/SignatureMatchTypeChip.vue';
import FileStatusChip from '@/components/files/FileStatusChip.vue';
import MxDataTable from '@/components/global/MxDataTable.vue';
import projectService from '@/services/ProjectService';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { FileStatus, IProjectFile, IProjectFileResultSummary } from '@/types/resource.types';
import { IPaginatedRequest, IPaginatedResult } from '@/types/services.types';
import { paths } from '@/router/route-paths';

@Component({
    name: 'ProjectSummaryFilesTable',
    components: {
        FileModificationStatusChip,
        FileStatusChip,
        MxDataTable,
        SignatureMatchTypeChip
    }
})
export default class ProjectSummaryFilesTable extends Vue {

    /* Props
    ============================================*/

    @Prop({type: [Number, String], required: true})
    readonly projectId: number | string;
    @Prop({type: Boolean, required: false})
    readonly clearInterval: boolean;


    /* Data
    ============================================*/

    headers: DataTableHeader<IProjectFileResultSummary>[] = fileResultTableheaders;
    loading: boolean = false;
    result: IPaginatedResult<IProjectFileResultSummary> | null = null;
    filterOption: string = 'All Project Files';
    filterOptions: string[] = [
        'All Project Files',
        'Supported Files Only',
        'Non-Submitted Files Only'
    ]

    /* Methods
    ============================================*/

    goToFile(file: IProjectFileResultSummary) {
        this.$router.push(paths.FILE_ANALYSIS(file.projectFileId));
    }
    fileIsComplete(file: IProjectFileResultSummary) {
        return file.status === FileStatus.ProcessingComplete;
    }

    async getResult(req: IPaginatedRequest) {
        try {
            this.loading = true;
            let result = await projectService.getSummaryFiles(this.projectId, req);
            this.result = result;
        } catch(e) {
            this.$logger.logError(e);
            this.$toast.error('There was a problem loading the file list. This error has been logged for review');
        } finally {
            this.loading = false;
        }
    }

}

export const fileResultTableheaders: DataTableHeader<IProjectFileResultSummary>[] = [
    {
        text: 'Filename',
        value: 'filename'
    },
    {
        text: 'Match',
        value: 'signatureMatchType',
        sortable: false
    },
    {
        text: 'Signature',
        value: 'signature2Description',
        sortable: true
    },
    {
        text: 'Brand',
        value: 'brand',
        sortable: true
    },
    {
        text: 'Model',
        value: 'model',
        sortable: true
    },
    {
        text: 'Last Generation',
        value: 'lastGeneration',
        sortable: true
    },
    {
        text: 'Structural Consistency',
        value: 'modificationStatus',
        align: 'end',
        sortable: true
    },
    {
        text: 'Status',
        value: 'status'
    },
    {
        align: 'end',
        text: '',
        value: 'actions',
        sortable: false
    }
];

export const fileTableheaders: DataTableHeader<IProjectFile>[] = [
    {
        text: 'Filename',
        value: 'filename'
    },
    {
        text: 'Originating Device',
        value: 'originatingDevice',
        sortable: false
    },
    {
        text: 'Last Generation',
        value: 'lastGeneration',
        sortable: false
    },
    {
        text: 'Status',
        value: 'status'
    },
    {
        text: 'Structural Consistency Analysis',
        value: 'modificationStatus',
        align: 'end',
        sortable: false
    },
    {
        align: 'end',
        text: '',
        value: 'actions',
        sortable: false
    }
];

