// <copyright file="SupportService.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { BackendService } from './BackendService';
import { IFeedbackRequest, FeedbackType, ISupportRequest } from '@/types/resource.types';

export class SupportService extends BackendService {

    public async sendSupportRequest(request: ISupportRequest): Promise<boolean> {
        let res = await this.jsonClient.post<boolean>('/support/request', request);
        return res.data;
    }

    public async sendFeedback(request: IFeedbackRequest): Promise<boolean> {
        let res = await this.jsonClient.post<boolean>('/support/feedback', request);
        return res.data;
    }
    public async hasFeedback(userId: number, fileId : number, type: FeedbackType): Promise<boolean> {
        let res = await this.jsonClient.get<boolean>('/support/feedback?userId=' + userId + '&fileId=' + fileId + '&type=' + type);
        return res.data;
    }

}

export default new SupportService();
