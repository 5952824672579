// <copyright file="BackendErrorHandler.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import eventBus from '@/events/eventBus';
import events from '@/events/events';
import logger from '@/log/AppLogger';
import { appConfig } from '@/config/app.config';
import { IFetchResponseErrorHandler, IServerError } from '@/types/http.types';
import { isJsonResponse } from '@/utils/http.utils';

export default class BackendErrorHandler implements IFetchResponseErrorHandler {

    handleBadResponse(res: Response): void | Promise<void> {

        if(res.status === 403) {
            logger.trace('User received permissions error');
            eventBus.$emit(events.PERMISSION_ERROR);
            return;
        }

        if(res.status === 401) {
            logger.trace('User\'s session expired');
            eventBus.$emit(events.SESSION_TIMEOUT);
        }

        if(appConfig.IS_DEV && res.status === 500 && isJsonResponse(res)) {
            res.json().then((error: IServerError) => {
                eventBus.$emit(events.SERVER_ERROR, error);
            });
        }

        throw new Error(res.statusText);
    }

}
