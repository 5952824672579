
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
    name: 'BetaChip',
})
export default class BetaChip extends Vue {

    /* Data
    ============================================*/

}

