
import HasForm from '../hoc/HasForm';
import userService from '@/services/UserService';
import { Component, Prop } from 'vue-property-decorator';
import { IUser } from '@/types/resource.types';
import { paths } from '@/router/route-paths';

@Component({
    name: 'AcceptInviteForm',
})
export default class AcceptInviteForm extends HasForm {

    /* Props
    ============================================*/

    @Prop({type: String, required: true})
    readonly token: string;

    @Prop({type: Object as () => IUser, required: true})
    readonly user: IUser;

    /* Data
    ============================================*/

    loading: boolean = false;
    password: string = '';
    confirmPassword: string = '';

    /* Login Methods
    ============================================*/

    async submitForm(): Promise<void> {
        try {
            this.loading = true;
            let res = await userService.acceptInvite({
                userId: this.user.id,
                token: this.token,
                password: this.password
            });
            this.$store.commit('SET_USER', res.user);
            this.$router.push(paths.HOME);
        } catch(error) {
            this.$toast.error('An unexpected error occurred. Please try again later.');
        } finally {
            this.loading = false;
        }
    }

    confirmPasswordRule(value: string): boolean | string {
        if(this.password !== value) {
            return 'Passwords do not match';
        }
        return true;
    }

}

