
import ProjectStatusChip from '@/components/projects/ProjectStatusChip.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IProject, IProjectSummary } from '@/types/resource.types';

@Component({
    name: 'ProjectDetailsSummary',
    components: {
        ProjectStatusChip
    }
})
export default class ProjectDetailsSummary extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Object as () => IProjectSummary, required: true})
    readonly summary: IProjectSummary;

    /* Computed
    ============================================*/

    get project(): IProject {
        return this.summary?.project;
    }

}

