
import AcceptInviteForm from '@/components/login/AcceptInviteForm.vue';
import MxLogo from '@/components/global/MxLogo.vue';
import userService from '@/services/UserService';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { IUser } from '@/types/resource.types';

@Component({
    name: 'InvitationView',
    components: {
        AcceptInviteForm,
        MxLogo
    }
})
export default class InvitationView extends Vue {

    /* Props
    ============================================*/

    /* Data
    ============================================*/

    error: string | null = null;
    loading: boolean = false;
    user: IUser | null = null;
    token: string | null = null;
    userId: number | null;

    /* Computed
    ============================================*/

    /* Methods
    ============================================*/

    async verifyToken() {
        this.loading = true;
        this.user = null;
        this.token = null;
        try {
            this.userId = parseInt((this.$route.query?.userid ?? '0') as string);
            this.token = this.$route.query?.token as string;
            this.user = await userService.verifyInvite({
                token: this.token ?? null,
                userId: this.userId
            });
        } catch(e) {
            this.$logger.logError(e, 'error', {
                userId: this.userId,
                token: this.token
            });
            this.error = 'Invalid invite token. Please contact an administrator for a new invite';
        } finally {
            this.loading = false;
        }
    }

    /* Lifecycle Hooks
    ============================================*/

    beforeMount() {
        this.verifyToken();
    }

    /* Watchers
    ============================================*/

    @Watch('$route')
    onRouteChanged() {
        this.verifyToken();
    }

}

