// <copyright file="InfoService.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { BackendService } from './BackendService';
import { IAppInfo } from '@/types/resource.types';

export class InfoService extends BackendService {

    public async getAppInfo(): Promise<IAppInfo> {
        let res = await this.jsonClient.get<IAppInfo>('/info/app');
        return res.data;
    }

}

export default new InfoService();
