
import fileService from '@/services/ProjectFileService';
import MxModal from '@/components/global/MxModal.vue';
import ProjectFileRating from '@/models/ProjectFileRating';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IProjectFile, IProjectFileRating } from '@/types/resource.types';
import { jsonCopy } from '@/utils/json.utils';


@Component({
    name: 'FileRating',
    components: {
        MxModal
    }
})
export default class FileRating extends Vue {

    /* Props
    ============================================*/
    @Prop({type: Object as () => IProjectFile, required: true})
    readonly file: IProjectFile;
    
    /* Data
    ============================================*/
    showModal = false;
    defaultRating = 3
    fileRating: IProjectFileRating | null = null;
    loading: boolean = false;

    /* Methods
    ============================================*/
    toggleModal(){
       
        if(! this.file?.rating ){
            this.fileRating = new ProjectFileRating(this.$user.id, this.file.id);
        }else{
            this.fileRating = jsonCopy(this.file.rating);
        }

        this.showModal = true;
        
    }

    async saveRating(){
        try {
            if(!this.fileRating) return;

            if(!this.fileRating?.rating){
                this.$toast.error('Please select 1 to 5 star rating');
                return;
            }

            this.loading = true;
            
            if(this.fileRating.id){
                await fileService.updateRating(this.fileRating as IProjectFileRating);
            }else{
                await fileService.addRating(this.fileRating as IProjectFileRating);
            }

            this.showModal = false;

            this.$emit('ratingUpdated');
        } catch(e) {
            this.$logger.logError(e);
            this.$toast.error('Could not save rating. This error has been logged.');
        } finally {
            this.loading = false;
        }
    }

}

