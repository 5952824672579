
import LoginForm from '@/components/login/LoginForm.vue';
import MxLogo from '@/components/global/MxLogo.vue';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { paths } from '@/router/route-paths';

@Component({
    name: 'LoginView',
    components: {
        LoginForm,
        MxLogo
    }
})
export default class LoginView extends Vue {
    forgotPassword: boolean = true;
    paths = paths;
}

