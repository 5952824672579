// <copyright file="router.utils.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import PaginatedRequest from '@/models/PaginatedRequest';
import { appendQueryParams } from './string.utils';
import { AuthorizedRouteConfig, IBreadcrumb, IMenuItem } from '@/types/router.types';
import { Dictionary, Route, RouteConfig } from 'vue-router/types/router';
import { IPaginatedRequest } from '@/types/services.types';
import { ISubscriber, IUser } from '@/types/resource.types';
import { mergeDefinedProperties } from './object.utils';
import { userHasPermission } from './auth.utils';
import { paths } from '@/router/route-paths';

/**
 * Determines whether or a route config should be visible to a user as a menu item
 * @param route
 * @param user
 */
export function showMenuItemForRoute(route: AuthorizedRouteConfig, user: IUser, subscriber: ISubscriber): boolean {
    if(!route.menuName || !route.menuIcon) return false;
    if(route.meta.permission === undefined) return false;
    
    if(route.path === paths.CLIENT_DOWNLOAD && subscriber?.allowClientDownload != true) {
        return false;
    }
    return userHasPermission(user, route.meta.permission);
}

/**
 * Creates an array of menu item definitions from vue-router configurations
 * @param routes
 * @param user
 */
export function createMenuItemsFromRoutes(routes: AuthorizedRouteConfig[], user: IUser, subscriber: ISubscriber): IMenuItem[] {
    if(!routes?.length) return [];
    return routes
        .filter(route => showMenuItemForRoute(route, user, subscriber))
        .map(route => {
            return {
                icon: route.menuIcon as string,
                label: route.menuName as string,
                path: route.path,
            };
        });
}

/**
 * Recursively creates an array of breadcrumbs for a given route
 */
export function createBreadcrumbs(
    route: Route | RouteConfig,
    routeConfigs: Record<string, AuthorizedRouteConfig>,
    crumbs?: IBreadcrumb[])
: IBreadcrumb[] {
    crumbs = crumbs || [];
    if(!route) return crumbs;
    crumbs.unshift({
        name: route.name as string,
        path: route.path,
        icon: route.meta?.breadcrumbIcon
    });
    let parentRoute = routeConfigs[route.meta?.parent];
    if(!parentRoute) return crumbs;
    return createBreadcrumbs(parentRoute, routeConfigs, crumbs);
}

export function createRequestFromQueryString(
    query: Dictionary<string | (string | null)[]>,
    initialRequest?: IPaginatedRequest
) : IPaginatedRequest {
    let req = new PaginatedRequest();
    // TODO: Find a better way to do this
    Object.keys(query).forEach(key => {
        if(key.toLowerCase() === 'page') req.page = parseInt(query[key] as string);
        if(key.toLowerCase() === 'perpage') req.perPage = parseInt(query[key] as string);
        if(key.toLowerCase() === 'orderby') req.orderBy = query[key] as string;
        if(key.toLowerCase() === 'desc') req.desc = (query[key] as string).toLowerCase() === 'true';
        if(key.toLowerCase() === 'keyword') req.keyword = query[key] as string;
        if(key.toLowerCase() === 'groupid') req.groupId = parseInt(query[key] as string);
    });
    return req;
}

export function mergeRequests(req: IPaginatedRequest, priorityReq: IPaginatedRequest): IPaginatedRequest {
    return mergeDefinedProperties(req, priorityReq);
}

export function createQueryStringFromRequest(req: IPaginatedRequest): string {
    return appendQueryParams('', req);
}
