
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IFileSignatureStructure, SequenceLabelType } from '@/types/resource.types';

@Component({
    name: 'FileSignatureStructure',
})
export default class FileSignatureStructure extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Object, required: false})
    readonly structure: IFileSignatureStructure;

    @Prop({type: Number, required: false})
    readonly parentHashId: number;

    /* Computed
    ============================================*/

    // vue-template-compiler strips whitespace by default.
    // We want to keep this behavior, but need a workaround here for indenting
    // the file signature structure in a way that can be copied and pasted.
    get space(): string {
        return '&nbsp;&nbsp;';
    }

    get color(): string {
        if(!this.structure.sequenceHashId) return '';
        switch(this.structure.sequenceHashLabelType) {
            case SequenceLabelType.Device:
                return 'error';
            case SequenceLabelType.Brand:
                return 'warning';
            case SequenceLabelType.LastGeneration:
                return 'primary';
            default:
                return '';
        }
    }

    get hideLabel(): boolean {
        if (this.structure.depth <= 0) return false;
        return this.structure.sequenceHashId === this.parentHashId;
    }

    get tooltipText(): string {
        if (this.hideLabel) return '';
        const text = this.structure.sequenceHashDescription + ' has only been seen in';
        switch(this.structure.sequenceHashLabelType) {
            case SequenceLabelType.Device:
                return `${text} ${this.structure.sequenceHashLabel}`;
            case SequenceLabelType.Brand:
                return `${text} ${this.structure.sequenceHashLabel} devices`;
            case SequenceLabelType.LastGeneration:
                return `${text} videos that were last touched by ${this.structure.sequenceHashLabel}`;
            default:
                return '';
        }
    }

    /* Props
    ============================================*/

    getPercentage(percent: number): string {
        if(!percent || percent < 0) return '0%';
        return (percent * 100).toFixed(2) + '%';
    }

}

