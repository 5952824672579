
import FileUploadForm from './FileUploadForm.vue';
import FileUploadProgressBar from './FileUploadProgressBar.vue';
import MxModal from '@/components/global/MxModal.vue';
import ProjectUploadHandler from '@/upload/ProjectUploadHandler';
import Vue from 'vue';
import { appConfig } from '@/config/app.config';
import { Component, Watch } from 'vue-property-decorator';
import {
    FileUploadProgress,
    IProject
} from '@/types/resource.types';
import { Get, Sync } from 'vuex-pathify';

@Component({
    name: 'UploadProjectFilesModal',
    components: {
        FileUploadForm,
        FileUploadProgressBar,
        MxModal
    }
})
export default class UploadProjectFilesModal extends Vue {

    /* Data
    ============================================*/

    cancelled: boolean = false;
    loading: boolean = false;
    maxFiles: number = appConfig.UPLOADS.MAX_FILES;
    progress: FileUploadProgress[] = [];
    progressTimer: ReturnType<typeof setInterval>;
    uploadsFinished: boolean = false;
    unsupportedFiles: string[] = [];

    /* Computed
    ============================================*/

    @Sync('create-project/fileList')
    fileList: File[];

    @Get('create-project/project')
    readonly project: IProject;

    @Sync('create-project/uploading')
    uploading: boolean;

    get hasTooManyFiles(): boolean {
        return this.totalFiles > appConfig.UPLOADS.MAX_FILES;
    }

    get totalFiles(): number {
        let projectFileCount = this.project?.files?.length ?? 0;
        let fileCount = this.fileList?.length ?? 0;
        return projectFileCount + fileCount;
    }

    /* Methods
    ============================================*/

    cancelUploads() {
        handler?.cancelUploads();
        this.uploading = false;
        this.cancelled = true;
        handler = null;
        this.$emit('click:close');
    }

    async uploadProjectFiles() {
        if(this.hasTooManyFiles) return;
        try {
            if(!this.fileList?.length) return;
            this.uploading = true;
            handler = new ProjectUploadHandler(this.project, this.fileList);
            this.monitorUploadProgress(handler);
            let files = await handler.doUpload();
            this.uploadsFinished = true;
            if(files) this.$emit('uploadComplete', files);
        } catch(e) {
            this.$logger.logError(e);
            this.$toast.error('Upload encountered an unexpected error. This error has been logged. Please try again');
        } finally {
            handler = null;
            this.uploading = false;
        }
    }

    monitorUploadProgress(handler: ProjectUploadHandler) {
        this.progress = handler.getProgress();
        this.progressTimer = setInterval(() => {
            this.progress = handler.getProgress();
            if(!this.uploading) clearInterval(this.progressTimer);
        }, 500);
    }

    updateFileList(list: FileList) {
        let newList = [...this.fileList];
        this.unsupportedFiles = [];
        let unsupportedFiles = [];
        for(let i=0; i<list.length; i++) {
            if(newList.find(x => x.name === list[i].name )) continue;
            if(this.$validation.medexFile(list[i].name) !== true) {
                unsupportedFiles.push(list[i].name);
                continue;
            }
            newList.push(list[i]);
        }
        this.unsupportedFiles = unsupportedFiles;
        this.fileList = newList;
    }

    reset() {
        // this.aquisitionType = null;
        this.fileList = [];
        this.loading = false;
        this.progress = [];
        this.uploading = false;
        clearInterval(this.progressTimer);
    }

    removeFile(file: File) {
        this.fileList = this.fileList.filter(x => x.name !== file.name);
    }

    /* Watchers
    ============================================*/

    @Watch('value')
    onValueChanged(valueNew: boolean) {
        if(valueNew) return;
        // Reset modal on hide (allow time for animation)
        setTimeout(() => {
            this.reset();
        }, 1000);
    }

}

// Keep this out of the reactive scope
let handler: ProjectUploadHandler | null = null;
