
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IProjectFileComment } from '@/types/resource.types';
import { jsonCopy } from '@/utils/json.utils';

@Component({
    name: 'FileComment',
})
export default class FileComment extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Object, required: true})
    readonly comment: IProjectFileComment;

    @Prop({type: Boolean, required: false})
    readonly editing: boolean;

    /* Data
    ============================================*/

    draft: IProjectFileComment | null = null;

    /* Computed
    ============================================*/

    get dateFormatString(): string {
        return 'MMM d, YYYY hh:mma';
    }

    get editedBy(): string {
        if(!this.comment?.editedBy) return '';
        if(this.comment.editedBy === this.$user.firstName + ' ' + this.$user.lastName) {
            return 'You';
        }
        return this.comment.editedBy;
    }

    get isAuthor(): boolean {
        if(!this.comment || !this.$user) return false;
        return this.$user.id === this.comment.userId;
    }

    /* Methods
    ============================================*/

    cancelDraft() {
        this.draft = null;
        this.$emit('endEditing');
    }

    editComment() {
        this.$emit('startEditing');
        this.draft = jsonCopy(this.comment);
    }

    formatDate(date: string) {
        let { dateFormat, dateParse } = this.$options.filters ?? {};
        if(!dateFormat || !dateParse) return '';
        return dateFormat(dateParse(date), this.dateFormatString);
    }

    saveDraft() {
        this.$emit('click:save', jsonCopy(this.draft));
        this.$emit('endEditing');
        this.draft = null;
    }

}

