
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
    name: 'MxModal'
})
export default class MxModal extends Vue {

    /**
     * When true, content is vertically centered
     */
    @Prop({type: Boolean, required: false})
    readonly centered: boolean;

    /**
     * Min height of the content body
     */
    @Prop({type: [String, Number], required: false, default: '200'})
    readonly minHeight: string | number;

    /**
     * Max width as a string or number of pixels
     */
    @Prop({type: [String, Number], required: false, default: '600px'})
    readonly maxWidth: string | number;

    /**
     * Optional modal title
     */
    @Prop({type: String, required: false, default: ''})
    readonly title: string;

    /**
     * Shows modal when true
     */
    @Prop({type: Boolean, required: true})
    readonly value: boolean;

    /**
     * Show loading bar
     */
    @Prop({type: Boolean, required: false})
    readonly loading: boolean;

    /**
     * When true, cannot dismiss modal by clicking outside
     */
    @Prop({type: Boolean, required: false})
    readonly persistent: boolean;

    /* Computed
    ============================================*/

    get width(): string {
        if(typeof this.maxWidth === 'string') return this.maxWidth;
        if(typeof this.maxWidth === 'number') return `${this.maxWidth}px`;
        return '600px';
    }

}
