
import downloadService from '@/services/ClientDownloadService';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { IClientDownload } from '@/types/resource.types';

@Component({
    name: 'ClientDownload'
})

export default class ClientDownload extends Vue {
    clientInfo: IClientDownload | null = null;

    async loadClientDownloadInfo() {
        try {
            this.clientInfo = await downloadService.getClientDownload();
        } catch(e) {
            this.$toast.error(`
                There was a problem loading the client download information.
                This error has been logged for review.
            `);
            this.$logger.logError(e);
        }
    }
    async downloadKey(){
        const keyv = await downloadService.getClientKey();
        console.log(keyv);
        if(keyv != undefined){
            const blob = new Blob([keyv], {type: 'text/html'});
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'MxKey.txt';
            link.click();
            URL.revokeObjectURL(link.href);
        }
    }
    async downloadLicense(){
        const keyv = await downloadService.getClientLicense();
        console.log(keyv);
        if(keyv != undefined){
            const blob = new Blob([keyv], {type: 'text/html'});
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'MxLicense.txt';
            link.click();
            URL.revokeObjectURL(link.href);
        }
    }

    /* Lifecycle Hooks
    ============================================*/

    async created() {
        await this.loadClientDownloadInfo();
    }
}

