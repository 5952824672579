// <copyright file="resource.utils.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { IDeviceBrand, IDeviceBrandGeneration, IModelGeneration } from '@/types/resource.types';
import { searchStringArray } from './array.utils';

export function searchDeviceList(keyword: string, list: IDeviceBrand[]): IDeviceBrand[] {
    if(!keyword) return list;
    let brands: IDeviceBrand[] = [];
    let q = keyword.toLowerCase();
    list.forEach(brand => {
        let matches = brand.models.filter(m => m.toLowerCase().indexOf(q) > -1);
        if(matches.length)  {
            brands.push({
                brandName: brand.brandName,
                models: matches
            });
        }
    });
    return brands;
}
export function searchDeviceGenList(keyword: string, list: IDeviceBrandGeneration[]): IDeviceBrandGeneration[] {
    if(!keyword) return list;
    let brands: IDeviceBrandGeneration[] = [];
    let q = keyword.toLowerCase();
    list.forEach(brand => {
        let modelGenerations : IModelGeneration[] = [];
        for(let i = 0; i < brand.modelGenerations.length; i++){
            let thisModelGen = brand.modelGenerations[i];
            if(brand.brandName.toLowerCase().indexOf(q) > -1 || thisModelGen.modelName.toLowerCase().indexOf(q) > -1){
                modelGenerations.push(thisModelGen);
                continue;
            }
            let matches = thisModelGen.generations.filter(m => m.toLowerCase().indexOf(q) > -1);
            if(matches.length)  {
                modelGenerations.push(
                    {
                        modelName: thisModelGen.modelName,
                        generations: matches
                    }
                );
            }   
        }
        if(modelGenerations.length > 0){
            brands.push({
                brandName: brand.brandName,
                modelGenerations: modelGenerations
            });
        }
    });
    return brands;
}