// <copyright file="guards.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import authOidService from '@/services/AuthOidService';
import authService from '@/services/AuthService';
import store from '@/store';
import { IAuthorizedRoute } from '@/types/router.types';
import { IUser, Permission } from '@/types/resource.types';
import { paths } from './route-paths';
import { userHasPermission } from '@/utils/auth.utils';

/**
 * Ensures a user is logged in with a valid token before allowing them to access protected routes
 * @param to The route to go to
 * @param _from The current route (unused)
 * @param next Callback method
 */
export async function authorizationGuard (
    to: IAuthorizedRoute,
    from: IAuthorizedRoute,
    next: any
): Promise<void> {
    let user = store.state.user;
    let subscriber = store.state.subscriber;
    let { permission } = to.meta;

    if(to.path === paths.LOGIN) {
        // Redirect already logged in users
        if(user) {
            if(to.query.redirectTo) {
                return next({ path: to.query.redirectTo });
            }
            return next({ path: paths.HOME });
        }
        return next({ path: paths.HOME });
    }

    if(to.path === paths.UNAUTHORIZED) {
        return next();
    }
    if(permission == null) {
        return next();
    }

    // If no user is set in Vuex state, attempt to locate and verify the session
    if(user == null) {
        return await verifiedSessionGuard(to, from, next);
    }

    if(to.path === paths.CLIENT_DOWNLOAD && subscriber?.allowClientDownload != true) {
        return next({
            path: paths.UNAUTHORIZED
        });
    }

    // If user is found and a permission is defined, ensure the user has specified permission
    if(user && !userHasPermission(user as IUser, permission as Permission)) {
        return next({
            path: paths.UNAUTHORIZED
        });
    }

    // Default behavior
    next();
}

export async function verifiedSessionGuard (
    to: IAuthorizedRoute,
    _from: IAuthorizedRoute,
    next: any
): Promise<void> {
    try {        
        let rawResult = await authService.getCurrentUser();
        console.log("user result returned");
        if(rawResult.status === 403) {
            return next({
                path: paths.UNAUTHORIZED
            });
        }

        let res = rawResult.data;
        if(!res?.user) throw 'User not found';
        store.commit('SET_USER', res.user);
        store.commit('SET_SUBSCRIBER', res.subscriber);
        next();
    } catch(e) {
        let res = await authOidService.getUser();
        await authOidService.login();
    }
}

/**
 * Updates document metadata based on route
 * @param to The route to go to
 */
export function updateTitle(to: IAuthorizedRoute): void {
    if(to.meta && to.meta.title) {
        document.title = to.meta.title + ' | Medex';
    } else if(to.name) {
        document.title = to.name + ' | Medex';
    } else {
        document.title = 'Medex | Device Classifier';
    }
};
