
import HasForm from '@/components/hoc/HasForm';
import projectService from '@/services/ProjectService';
import UserGroupSelector from '@/components/users/UserGroupSelector.vue';
import { Component } from 'vue-property-decorator';
import { IProject } from '@/types/resource.types';
import { Sync } from 'vuex-pathify';

@Component({
    name: 'CreateProjectForm',
    components: {
        UserGroupSelector
    }
})
export default class CreateProjectForm extends HasForm {

    /* Data
    ============================================*/

    loading: boolean = false;

    /* Computed
    ============================================*/

    @Sync('create-project/editing')
    editing: boolean;

    @Sync('create-project/project')
    project: IProject;

    /* Methods
    ============================================*/

    async submitForm() {
        if(!this.validateForm()) return;
        try {
            this.loading = true;
            let savedProject = this.project.id
                ? await projectService.update(this.project.id, this.project)
                : await projectService.create(this.project);
            this.project = savedProject;
            this.editing = false;
        } catch(e) {
            this.$toast.error(`
                There was an error creating the project.
                An admin has been notified. Please try again later.
            `);
            this.$logger.logError(e);
        } finally {
            this.loading = false;
        }
    }

}

