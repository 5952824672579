
import MxModal from '@/components/global/MxModal.vue';
import userGroupService from '@/services/UserGroupService';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Get } from 'vuex-pathify';
import { IAutocompleteResult, IGroupPermissionDetails, IUserGroupPermission, Permission } from '@/types/resource.types';
import { jsonCopy } from '@/utils/json.utils';

@Component({
    name: 'AddGroupUserModal',
    components: {
        MxModal
    }
})
export default class AddGroupUserModal extends Vue {

    $refs!: {
        _autocomplete: HTMLElement;
    }

    /* Props
    ============================================*/

    @Prop({type: Number, required: false})
    readonly groupId: number;

    @Prop({type: Boolean, required: true})
    readonly value: boolean;

    /* Data
    ============================================*/

    loading: boolean = false;
    keyword: string = '';
    selectedPermissions: Permission[] = [];
    user: IAutocompleteResult | null = null;
    users: IAutocompleteResult[] = [];

    /* Computed
    ============================================*/

    @Get('permissionsList')
    readonly permissionsList: IGroupPermissionDetails[];

    get userPermissions(): IUserGroupPermission[] {
        if(!this.user || !this.groupId) return [];
        let userId = this.user.id;
        let userGroupId = this.groupId;
        return this.selectedPermissions.map(permission => {
            return { permission, userId, userGroupId };
        });
    }

    /* Methods
    ============================================*/

    clearUser() {
        this.user = null;
        this.$nextTick(() => {
            this.$refs._autocomplete?.focus();
        });
    }

    closeModal(): void {
        this.$emit('input', false);
    }

    async addGroupUser() {
        if(!this.user) return;
        this.loading = true;
        try {
            await userGroupService.addGroupUser(this.groupId, {
                id: this.user.id as number,
                permissions: this.userPermissions
            });
            this.$emit('saved');
            this.closeModal();
        } catch(e) {
            this.$logger.logError(e);
            this.$toast.error('Failed to add group user. This error has been logged');
        }
        this.loading = false;
    }

    async searchAddableUsers(keyword: string) {
        this.loading = true;
        try {
            this.users = await userGroupService.searchAddableUsers(this.groupId, keyword);
        } catch(e) {
            this.$logger.logError(e);
        }
        this.loading = false;
    }

    mounted() {
        if(!this.permissionsList?.length) return;
        this.selectedPermissions = this.permissionsList.map(x => x.permission);
    }

    /* Lifecycle Hooks
    ============================================*/

    @Watch('keyword')
    onKeywordChanged(newKeyword: string, oldKeyword: string) {
        if(!newKeyword || newKeyword.length < 2) return;
        if(newKeyword === oldKeyword) return;
        this.searchAddableUsers(newKeyword);
    }

}

