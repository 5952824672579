// <copyright file="UsesResultsStore.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import fileService from '@/services/ProjectFileService';
import projectService from '@/services/ProjectService';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Get, Sync } from 'vuex-pathify';
import { IFileExaminationResult, IProject, IProjectFile, IProjectSummary } from '@/types/resource.types';

@Component
export default class UsesResultsStore extends Vue {

    /* Computed
    ============================================*/

    @Get('results/file')
    public readonly file: IProjectFile | null;

    @Sync('results/fileResult')
    public fileResult: IFileExaminationResult | null;

    @Get('results/groupId')
    public readonly groupId: number;

    @Get('results/project')
    public readonly project: IProject | null;

    @Sync('results/projectSummary')
    public projectSummary: IProjectSummary | null;

    /* Data
    ============================================*/

    isLoadingProjectSummary: boolean = false;
    isLoadingFileResult: boolean = false;

    /* Methods
    ============================================*/

    async loadProject(projectId: number | string): Promise<boolean> {
        let success = false;
        if(!projectId || this.isLoadingProjectSummary) return false;
        try {
            this.projectSummary = null; // Ensures child components re-mount
            this.$loading = true;
            this.isLoadingProjectSummary = true;
            let result = await projectService.getSummary(projectId);
            this.projectSummary = result;
            success = true;
        } catch(e) {
            this.$toast.error('An error occurred while loading the project summary');
        } finally {
            this.isLoadingProjectSummary = false;
            this.$loading = false;
        }
        return success;
    }
    async loadProjectSummary(projectId: number | string): Promise<boolean> {
        let success = false;
        if(!projectId || this.isLoadingProjectSummary) return false;
        try {
            let status = this.projectSummary?.project.status;
            let knownFiles = this.projectSummary?.filesWithKnownSignature;
            let supportedFiles = this.projectSummary?.filesWithKnownSignature;
            
            this.$loading = true;
            this.isLoadingProjectSummary = true;
            let result = await projectService.getSummary(projectId);
            if(status != result.project.status ||
                knownFiles != result.filesWithKnownSignature ||
                supportedFiles != result.filesSupported){
                this.projectSummary = null; // Ensures child components re-mount
                this.projectSummary = result;
            }
            success = true;
        } catch(e) {
            this.$toast.error('An error occurred while loading the project summary');
        } finally {
            this.isLoadingProjectSummary = false;
            this.$loading = false;
        }
        return success;
    }
    async loadFileResult(fileId: number | string): Promise<boolean> {
        let success = false;
        if(!fileId || this.isLoadingFileResult) return false;
        try {
            this.$loading = true;
            this.isLoadingFileResult = true;
            if(fileId != this.file?.id) {
                this.fileResult = null; // Ensures child components re-mount
            }
            let result = await fileService.getAnalysisResult(fileId);
            this.fileResult = result;
            success = true;
        } catch(e) {
            this.$toast.error('An error occurred while loading file results');
        } finally {
            this.isLoadingFileResult = false;
            this.$loading = false;
        }
        return success;
    }


    async updateFile(file: IProjectFile): Promise<void> {
        this.$loading = true;
        try {
            await fileService.update(file.id, file);
            await this.loadFileResult(file.id);
            this.$toast.success('File was successfully updated', {
                icon: 'mdi-check'
            });
        } catch(e) {
            this.$toast.error('Could not updated file, this error has been logged', {
                icon: 'mdi-alert'
            });
            this.$logger.logError(e);
        } finally {
            this.$loading = false;
        }
    }

    /* Watchers
    ============================================*/

    @Watch('file')
    onFileChanged(newFile: IProjectFile): void {
        if(!newFile) return;
        if(newFile.projectId !== this.project?.id) {
            //this.loadProject(newFile.projectId);
        }
    }
}
