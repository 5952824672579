
import AddGroupUserModal from './AddGroupUserModal.vue';
import groupService from '@/services/UserGroupService';
import HasDataTable from '../hoc/HasDataTable';
import MxDataTable from '@/components/global/MxDataTable.vue';
import PaginatedRequest from '@/models/PaginatedRequest';
import UserEditModal from '@/components/users/UserEditModal.vue';
import UserGroupSelector from '@/components/users/UserGroupSelector.vue';
import { Component, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { Get } from 'vuex-pathify';
import { IPaginatedRequest, IPaginatedResult } from '@/types/services.types';
import { IUser, IUserGroup, Permission } from '@/types/resource.types';
import { jsonCopy } from '@/utils/json.utils';
import { userTableHeaders } from './UserManagement.vue';

@Component({
    name: 'UserGroupUserManagement',
    components: {
        AddGroupUserModal,
        MxDataTable,
        UserEditModal,
        UserGroupSelector
    }
})
export default class UserManagement extends HasDataTable {

    /* Computed
    ============================================*/

    @Get('userGroups')
    userGroups: IUserGroup[];

    get canDeleteUsers(): boolean {
        return this.$hasGroupPermission(this.groupId, Permission.DeleteGroupUsers);
    }

    get canEditUsers(): boolean {
        return this.$hasGroupPermission(this.groupId, Permission.EditGroupUsers);
    }

    get selectedGroup() {
        return this.userGroups.find(x => x.id == this.groupId);
    }

    get userGroupSelectItems() {
        if(!this.userGroups) return [];
        return this.userGroups.map(x => {
            return {
                text: x.name,
                value: x.id
            };
        });
    }

    /* Data
    ============================================*/

    activeUser: IUser | null = null;
    groupId: number | null = null;
    headers: DataTableHeader<IUser>[] = userTableHeaders;
    initialRequest: IPaginatedRequest | null = null;
    loading: boolean = false;
    showAddUserModal: boolean = false;
    showUserModal: boolean = false;
    result: IPaginatedResult<IUser> | null = null;

    /* Methods
    ============================================*/

    async getUsers(request: IPaginatedRequest): Promise<void> {
        try {
            if(!request?.groupId) return;
            this.groupId = request.groupId;
            this.loading = true;
            this.result = await groupService.getUsers(request);
        } catch(e){
            this.$toast.error('Something went wrong, an admin has been notified.');
            this.$logger.logError(e);
        } finally {
            this.loading = false;
        }
    }

    async confirmDelete(user: IUser): Promise<boolean | undefined> {
        let { firstName, lastName } = user;
        let message = `Are you sure you want to remove ${firstName} ${lastName} from this group?`;
        return await this.$confirm(message, {
            icon: 'mdi-alert',
            title: 'Confirm Delete'
        });
    }

    async editUser(user: IUser) {
        try {
            this.loading = true;
            if(!this.groupId) return;
            let userRes = await groupService.getUser(this.groupId, user.id);
            this.activeUser = jsonCopy(userRes);
            this.showUserModal = true;
        } catch(e) {
            this.$toast.error('Failed to load user, please try again.');
            this.$logger.logError(e);
        } finally {
            this.loading = false;
        }
    }

    async deleteUser(user: IUser): Promise<void> {
        try {
            let confirmed = await this.confirmDelete(user);
            if(!confirmed) return;
            if(!this.groupId) return;
            await groupService.deleteGroupUser(this.groupId, user.id);
            await this.reloadTable();
        } catch(e) {
            this.$toast.error('Failed to delete user, please try again.');
            this.$logger.logError(e);
        } finally {
            this.loading = false;
        }
    }

    reloadUsers() {
        this.reloadTable();
    }

    setDefaultGroupId(groups: IUserGroup[]) {
        if(!groups?.length) return;
        this.groupId = groups[0].id;
        return groups[0].id;
    }

    /* Lifecycle hooks
    ============================================*/

    created() {
        let initialRequest = new PaginatedRequest();
        initialRequest.orderBy = 'firstName';
        this.initialRequest = initialRequest;
    }

    mounted(): void {
        this.setDefaultGroupId(this.userGroups);
    }

    /* Watchers
    ============================================*/

    @Watch('userGroups')
    onUserGroupsChanged(groups: IUserGroup[]): void {
        this.setDefaultGroupId(groups);
    }
}
