
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { FileUploadProgress, FileUploadStatus } from '@/types/resource.types';

@Component({
    name: 'FileUploadProgressBar',
})
export default class FileUploadProgressBar extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Object as () => FileUploadProgress, required: true})
    readonly progress: FileUploadProgress;

    /* Computed
    ============================================*/

    get backgroundColor(): string {
        switch(this.progress.status) {
            case FileUploadStatus.Error:
                return '#fff4f4';
            case FileUploadStatus.Complete:
                return '#e6f7ec';
            default:
                return '#f4fcff';
        }
    }

    get color(): string {
        switch(this.progress.status) {
            case FileUploadStatus.Error:
                return 'error';
            case FileUploadStatus.Complete:
                return 'success';
            default:
                return 'primary';
        }
    }

    get icon(): string {
        switch(this.progress.status) {
            case FileUploadStatus.Error:
                return 'mdi-alert-circle';
            case FileUploadStatus.Complete:
                return 'mdi-check-circle';
            default:
                return 'mdi-timer-sand';
        }
    }

    get bufferValue(): number {
        return Math.ceil((this.progress.currentChunk / this.progress.totalChunks) * 100);
    }

    get uploading() {
        return this.progress.status == FileUploadStatus.Uploading ||
            this.progress.status == FileUploadStatus.Retrying;
    }

    get uploadFailed() {
        return this.progress.status == FileUploadStatus.Error;
    }

    get text(): string {
        switch(this.progress.status) {
            case FileUploadStatus.NotStarted:
                return 'Preparing...';
            case FileUploadStatus.Uploading:
            case FileUploadStatus.Retrying:
                return 'Uploading';
            case FileUploadStatus.Complete:
                return 'Complete';
            case FileUploadStatus.Offline:
            case FileUploadStatus.Paused:
                return 'Paused';
            case FileUploadStatus.Error:
                return 'Failed';
            case FileUploadStatus.Completing:
                return 'Finalizing';
            default:
                return '';
        }
    }

}

