
import UserGroup from '@/models/UserGroup';
import UserGroupEditModal from '@/components/users/UserGroupEditModal.vue';
import userGroupService from '@/services/UserGroupService';
import Vue from 'vue';
import { Component,  Watch } from 'vue-property-decorator';
import { Get } from 'vuex-pathify';
import { IUserGroup, Permission } from '@/types/resource.types';
import { jsonCopy } from '@/utils/json.utils';

@Component({
    name: 'UserGroupManagement',
    components: {
        UserGroupEditModal
    }
})
export default class UserGroupManagement extends Vue {

    @Get('userGroups')
    readonly userGroups: IUserGroup[];

    /* Data
    ============================================*/

    activeGroup: IUserGroup | null = null;
    headers = [
        {
            text: 'Group Name',
            value: 'name'
        },
        {
            text: 'Group Description',
            value: 'description'
        },
        {
            align: 'right',
            text: 'Actions',
            value: 'actions',
            sortable: false
        }
    ];
    loading: boolean = false;
    showEditModal: boolean = false;
    sortBy: string = 'name'

    /* Methods
    ============================================*/

    canEditGroup(group: IUserGroup): boolean {
        return this.$hasGroupPermission(group.id, Permission.EditGroupSettings);
    }

    async getGroups(): Promise<void> {
        try {
            this.loading = true;
            await this.$store.dispatch('getUserGroups');
        } catch(e){
            this.$toast.error('Something went wrong, an admin has been notified.');
            this.$logger.logError(e);
        } finally {
            this.loading = false;
        }
    }

    createGroup(): void {
        const group = new UserGroup();
        this.activeGroup = group;
        this.showEditModal = true;
    }

    async confirmDelete(group: IUserGroup): Promise<boolean | undefined> {
        let message = `Are you sure you want to delete the group "${group.name}"`;
        return await this.$confirm(`${message}? This action cannot be undone!`, {
            icon: 'mdi-alert',
            title: 'Confirm Delete'
        });
    }

    async editGroup(group: IUserGroup) {
        try {
            this.loading = true;
            let groupRes = await userGroupService.get(group.id);
            this.loading = false;
            this.activeGroup = jsonCopy(groupRes);
            this.showEditModal = true;
        } catch(e) {
            this.$toast.error('Failed to load group, please try again.');
            this.$logger.logError(e);
        } finally {
            this.loading = false;
        }
    }

    async deleteGroup(group: IUserGroup): Promise<void> {
        try {
            let confirmed = await this.confirmDelete(group);
            if(!confirmed) return;
            await userGroupService.delete(group.id);
            await this.getGroups();
        } catch(e) {
            this.$toast.error('Failed to delete group, please try again.');
            this.$logger.logError(e);
        } finally {
            this.loading = false;
        }
    }

    /* Lifecycle hooks
    ============================================*/

    mounted(): void {
        if(this.userGroups?.length) return;
        this.getGroups();
    }

    /* Watchers
    ============================================*/

    @Watch('page')
    onPageChanged(): void {
        this.getGroups();
    }

    @Watch('perPage')
    onPerPageChanged(): void {
        this.getGroups();
    }

}
