// <copyright file="Project.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { FileAquisitionMethod, IProject, ProjectStatus } from '@/types/resource.types';

export class Project implements IProject {
    id: number = 0;
    userId: number = 0;
    userGroupId: number = 0;
    caseId: string = '';
    examinerName: string = '';
    labId: string = '';
    created?: string;
    updated?: string;
    status: ProjectStatus = ProjectStatus.Draft;
    files = [];
}
