
import * as validationRules from '@/utils/validation.utils';
import authService from '@/services/AuthService';
import HasForm from '../hoc/HasForm';
import MxLogo from '@/components/global/MxLogo.vue';
import { Component } from 'vue-property-decorator';
import { paths } from '@/router/route-paths';

@Component({
    name: 'ForgotPasswordView',
    components: {
        MxLogo
    }
})
export default class ForgotPasswordView extends HasForm {

    /* Data
    ============================================*/

    email: string = '';
    loading: boolean = false;
    paths = paths;
    submitted: boolean = false;

    /* Login Methods
    ============================================*/

    async submitForm(): Promise<void> {
        try {
            this.loading = true;
            await authService.sendForgotPasswordEmail(this.email);
            this.submitted = true;
        } catch(e) {
            this.$logger.logError(e);
            this.$toast.error('An unexpected error occurred. Please try again later.');
        } finally {
            this.loading = false;
        }
    }

    emailRule(value: string): true | string {
        return validationRules.email(value);
    }

}

