
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import MxModal from '@/components/global/MxModal.vue';
import MxTextEditor from '@/components/global/MxTextEditor.vue';
import supportService from '@/services/SupportService';
import { IFeedbackRequest, FeedbackType } from '@/types/resource.types';
import { FeedbackRequest } from '@/models/FeedbackRequest';

@Component({
    name: 'FeedbackForm',
    components: {
        MxModal,
        MxTextEditor
    }
})
export default class FeedbackForm extends Vue {

    /* Props
    ============================================*/

    /* Computed
    ============================================*/
    /* Data
    ============================================*/
    showModal : boolean  = false;
    existingFeedback: boolean = true;

    /* Methods
    ============================================*/
    async helpful(){
        if(!this.request) this.request = new FeedbackRequest();
        this.request.isHelpful = true;
        await this.submit();
    }
    notHelpful(){
        if(!this.request) this.request = new FeedbackRequest();
        this.request.isHelpful = false;
        this.showModal = true;
    }

    request: IFeedbackRequest | null = new FeedbackRequest();
    
    @Prop({type: Number, required: true})
    readonly type: FeedbackType;

    @Prop({type: Number, required: true})
    readonly projectFileId: number;

    @Prop({type: String, required: true})
    readonly label: string;

    /* Methods
    ============================================*/

    resetRequest() {
        this.request = null; // Force a remount to reset vuetify form validation
        this.$nextTick(() => {
            this.request = new FeedbackRequest();
        });
    }

    async loadExistingFeedback(){
        try {
            this.$loading = true;
            this.existingFeedback = await supportService.hasFeedback(this.$user.id, this.projectFileId, this.type);
        } catch(e) {
        } finally {
            this.$loading = false;
        }
    }

    async submit() {
        if(!this.request) return;
        this.request.userId = this.$user.id;
        this.request.type = this.type;
        this.request.projectFileId = this.projectFileId;
        try {
            this.$loading = true;
            await supportService.sendFeedback(this.request);
            this.resetRequest();
            this.$toast.success('Result feedback successfully sent', {
                icon: 'mdi-check'
            });
            this.showModal = false;
            this.existingFeedback = true;
        } catch(e) {
            this.$toast.error('Your request failed to send. An administrator was notified');
        } finally {
            this.$loading = false;
        }
    }

    async mounted() {
        await this.loadExistingFeedback();
        (this.$refs as any)._subjectField?.focus();
    }
}

