
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { FileFlagStatus } from '@/types/resource.types';

@Component({
    name: 'FileFlagStatusChip',
})
export default class FileFlagStatusChip extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Number, required: false})
    readonly status: FileFlagStatus;

    @Prop({type: Boolean, required: false})
    readonly iconOnly: boolean;

    /* Data
    ============================================*/

    get color(): string {
        switch(this.status) {
            case FileFlagStatus.Matched:
                return 'success';
            case FileFlagStatus.Flagged:
                return 'error';
            default:
                return '';
        }
    }

    get icon(): string | null {
        switch(this.status) {
            case FileFlagStatus.Matched:
            case FileFlagStatus.Flagged:
                return 'mdi-flag';
            default:
                return null;
        }
    }

    get text(): string {
        switch(this.status) {
            case FileFlagStatus.Matched:
                return 'New Match';
            case FileFlagStatus.Flagged:
                return 'Flagged';
            default:
                return 'None';
        }
    }

}

