
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ProjectStatus } from '@/types/resource.types';

@Component({
    name: 'ProjectStatusChip',
})
export default class ProjectStatusChip extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Boolean, required: false})
    readonly text: boolean;

    @Prop({type: Number, required: false})
    readonly status: ProjectStatus;

    @Prop({type: Boolean, required: false})
    readonly small: boolean;

    /* Data
    ============================================*/

    get color(): string {
        switch(this.status) {
            case ProjectStatus.InProgress:
                return 'primary';
            case ProjectStatus.Complete:
                return 'success';
            case ProjectStatus.Error:
                return 'error';
            default:
                return '';
        }
    }

    get icon(): string {
        switch(this.status) {
            case ProjectStatus.Draft:
                return 'mdi-file-edit';
            case ProjectStatus.InProgress:
                return 'mdi-progress-clock';
            case ProjectStatus.Complete:
                return 'mdi-check-circle';
            case ProjectStatus.Error:
                return 'mdi-alert';
            default:
                return '';
        }
    }

    get statusText(): string {
        switch(this.status) {
            case ProjectStatus.Draft:
                return 'Draft';
            case ProjectStatus.InProgress:
                return 'In Progress';
            case ProjectStatus.Complete:
                return 'Complete';
            case ProjectStatus.Error:
                return 'Error';
            default:
                return '';
        }
    }

    /* Computed
    ============================================*/

    /* Methods
    ============================================*/

    /* Lifecycle Hooks
    ============================================*/

    /* Watchers
    ============================================*/

}

