// <copyright file="auth.utils.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { IUser, Permission } from '@/types/resource.types';

export function userHasPermission(user: IUser, permission: Permission| null = Permission.Public): boolean {
    if(user.isAdmin || permission == Permission.Public) return true;
    return !!user.permissions.find(x => x.permission == permission);
}

export function userHasGroupPermission(user: IUser, groupId: number | null = null, perm: Permission | null = null): boolean {
    if(user.isAdmin || perm == null || perm == Permission.Public) return true;
    if(!groupId) return userHasPermission(user, perm);
    let matchingPermission = user.permissions.find(userPermission => {
        return userPermission.permission === perm
            && userPermission.userGroupId === groupId;
    });
    return !!matchingPermission;
}
