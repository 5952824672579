
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { FileModificationStatus } from '@/types/resource.types';

@Component({
    name: 'FileModificationStatusChip',
})
export default class FileModificationStatusChip extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Number, required: false})
    readonly status: FileModificationStatus;

    /* Data
    ============================================*/

    get color(): string {
        switch(this.status) {
            case FileModificationStatus.PossiblyModified:
                return 'warning';
            case FileModificationStatus.NotModified:
                return 'success';
            case FileModificationStatus.Modified:
                return 'error';
            default:
                return 'grey';
        }
    }

    get icon(): string {
        switch(this.status) {
            case FileModificationStatus.PossiblyModified:
                return 'mdi-alert';
            case FileModificationStatus.NotModified:
                return 'mdi-check';
            case FileModificationStatus.Modified:
                return 'mdi-alert';
            default:
                return '';
        }
    }

    get text(): string {
        switch(this.status) {
            case FileModificationStatus.PossiblyModified:
                return 'Possibly Modified';
            case FileModificationStatus.NotModified:
                return 'Not Modified';
            case FileModificationStatus.Modified:
                return 'Modified';
            default:
                return 'Unknown';
        }
    }

    /* Computed
    ============================================*/

    /* Methods
    ============================================*/

    /* Lifecycle Hooks
    ============================================*/

    /* Watchers
    ============================================*/

}

