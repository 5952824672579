
import deviceService from '@/services/DeviceService';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { IDeviceBrand, IDeviceList, IDeviceBrandGeneration, IModelGeneration } from '@/types/resource.types';
import { searchDeviceList,searchDeviceGenList } from '@/utils/resource.utils';
import { searchStringArray } from '@/utils/array.utils';

@Component({
    name: 'DeviceListView',
})
export default class DeviceListView extends Vue {

    /* Data
    ============================================*/

    activeTab: number = 0;
    deviceList: IDeviceList | null = null;
    deviceKeyword: string = '';
    genKeyword: string = '';
    dgenKeyword: string = '';
    tabs: string[] = [
        'Device List',
        'Last Generations',
        'Device Generations'
    ]

    /* Computed
    ============================================*/

    get filteredGenerationList(): string[] | null {
        if(!this.deviceList?.deviceBrands) return null;
        return searchStringArray(this.genKeyword, this.deviceList.lastGenerations);
    }

    get filteredDeviceGenerationList(): IDeviceBrandGeneration[] | null {
        if(!this.deviceList?.deviceGenerations) return null;
        return searchDeviceGenList(this.dgenKeyword, this.deviceList?.deviceGenerations);
    }
    get filteredDeviceList(): IDeviceBrand[] | null {
        if(!this.deviceList?.deviceBrands) return null;
        return searchDeviceList(this.deviceKeyword, this.deviceList.deviceBrands);
    }

    /* Methods
    ============================================*/

    async loadDeviceList() {
        try {
            this.deviceList = await deviceService.getDeviceList();
        } catch(e) {
            this.$toast.error(`
                There was a problem loading the device list.
                This error has been logged for review.
            `);
            this.$logger.logError(e);
        }
    }
    async downloadList() {
        var content = await deviceService.getDeviceListCsv();
        var date = new Date();
        let fn = 'medex-reference-inventory-' + date.toLocaleDateString() + '.csv';
        this.saveContent(content, fn);
    }
    async downloadGenList() {
        var content = await deviceService.getGenerationListCsv();
        var date = new Date();
        let fn = 'medex-reference-generations-' + date.toLocaleDateString() + '.csv';
        this.saveContent(content, fn);
    }
    async downloadDeviceGenList() {
        var content = await deviceService.getDeviceGenerationListCsv();
        var date = new Date();
        let fn = 'medex-reference-device-generations-' + date.toLocaleDateString() + '.csv';
        this.saveContent(content, fn);
    }
    saveContent(fileContents:string, filename: string){
        var blob = new Blob([fileContents], {type: 'application/csv'});
        var url  = window.URL || window.webkitURL;
        var downloadUrl = url.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = filename;
        link.href = downloadUrl;
        link.click();
    }

    /* Lifecycle Hooks
    ============================================*/

    async created() {
        this.loadDeviceList();
    }

}

