
import FileResultCard from './FileResultCard.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IFileStructure } from '@/types/resource.types';

@Component({
    name: 'FileProprietaryData',
    components: {
        FileResultCard
    }
})
export default class FileProprietaryData extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Array, required: true, default: () => []})
    readonly structures: IFileStructure[];

}

