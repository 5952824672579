
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Get } from 'vuex-pathify';
import { InputValidationRule } from 'vuetify';
import { IUserGroup, Permission } from '@/types/resource.types';

@Component({
    name: 'UserGroupSelector',
})
export default class UserGroupSelector extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Boolean, required: false})
    readonly outlined: boolean;

    @Prop({type: String, required: false, default: 'User Group'})
    readonly label: string;

    @Prop({type: Boolean, required: false})
    readonly required: boolean;

    /**
     * The groupId as a v-model
     */
    @Prop({type: Number, required: false})
    readonly value: number;

    /**
     * Display option to select "All groups"
     */
    @Prop({type: Boolean, required: false, default: false})
    readonly allGroupsOption: boolean;

    /**
     * When true, uses the first group id as the default
     */
    @Prop({type: Boolean, required: false, default: false})
    readonly useDefault: boolean;

    /**
     * When set, will only display groups for which the user has the given permission
     */
    @Prop({type: Number, required: false})
    readonly permission: Permission;

    /* Computed
    ============================================*/

    @Get('userGroups')
    userGroups: IUserGroup[];

    get permittedGroups(): IUserGroup[] {
        if(!this.permission) return [...this.userGroups];
        return this.userGroups.filter(x => this.$hasGroupPermission(x.id, this.permission));
    }

    get rules(): InputValidationRule[] {
        if(!this.required) return [];
        return [this.$validation.required];
    }

    get userGroupSelectItems(): UserGroupSelectItem[] {
        if(!this.permittedGroups) return [];
        var groups = this.permittedGroups.map(x => {
            return {
                text: x.name,
                value: x.id
            };
        });
        if(!this.allGroupsOption) return groups;
        return [{
            text: 'All User Groups',
            value: 0
        }].concat(groups);
    }

    /* Methods
    ============================================*/

    setDefaultGroup() {
        if(!this.userGroups?.length || !this.useDefault) return;
        let id = this.userGroups[0].id;
        this.$emit('input', id);
    }

    /* Lifecycle Hooks
    ============================================*/

    mounted() {
        if(this.userGroups?.length) {
            this.setDefaultGroup();
        } else {
            this.$store.dispatch('getUserGroups');
        }
    }

    /* Watchers
    ============================================*/

    @Watch('userGroups')
    onUserGroupsChanged(userGroupsNew: any, userGroupsOld: any) {
        if(!userGroupsNew?.length || !!this.value) return;
        this.setDefaultGroup();
    }

}

export type UserGroupSelectItem = {
    text: string,
    value: number
};

