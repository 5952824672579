
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { FileAquisitionMethod } from '@/types/resource.types';

@Component({
    name: 'AquisitionMethodSelector',
})
export default class AquisitionMethodSelector extends Vue {
    buttons: AquisitionTypeButton[] = [
        {
            disabled: false,
            icon: 'upload',
            iconColor: 'black',
            text: 'Direct Upload',
            value: FileAquisitionMethod.DirectUpload
        },
        {
            disabled: false,
            icon: 'xml',
            iconColor: 'black',
            text: 'Medex Client Upload',
            value: FileAquisitionMethod.MetadataUpload
        },
        {
            disabled: true,
            icon: 'facebook',
            iconColor: '#3B5998',
            text: 'Facebook URL',
            value: FileAquisitionMethod.Facebook
        },
        {
            disabled: true,
            icon: 'youtube',
            iconColor: '#E62117',
            text: 'YouTube URL',
            value: FileAquisitionMethod.YouTubeDL
        },
    ];
}

export type AquisitionTypeButton = {
    disabled: boolean,
    icon: string,
    iconColor: string,
    text: string,
    value: FileAquisitionMethod
};
