
import HasDataTable from '../hoc/HasDataTable';
import MxDataTable from '@/components/global/MxDataTable.vue';
import projectService from '@/services/ProjectService';
import ProjectStatusChip from './ProjectStatusChip.vue';
import UserGroupSelector from '@/components/users/UserGroupSelector.vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { IPaginatedRequest, IPaginatedResult } from '@/types/services.types';
import { IProject, ProjectStatus } from '@/types/resource.types';
import { paths } from '@/router/route-paths';

@Component({
    name: 'ProjectsTableSmall',
    components: {
        MxDataTable,
        ProjectStatusChip,
        UserGroupSelector,
    }
})
export default class ProjectsTableSmall extends HasDataTable {

    /* Props
    ============================================*/

    @Prop({type: Object, required: false})
    readonly initialRequest: IPaginatedRequest;

    @Prop({type: Boolean, required: false})
    readonly userOnly: boolean;

    /* Data
    ============================================*/

    loadingProjects: boolean = false;
    result: IPaginatedResult<IProject> | null = null;
    userProjectsOnly: boolean = true;

    /* Computed
    ============================================*/

    get filterByUser(): boolean {
        return this.userProjectsOnly || this.userOnly;
    }

    get headers() {
        return projectTableHeadersSmall;
    }

    /* Methods
    ============================================*/

    getItemClass() {
        return 'pointer';
    }

    goToProject(project: IProject) {
        if(project.status === ProjectStatus.Draft) {
            this.$router.push(paths.PROJECT_DRAFT(project.id));
        }
        else {
            this.$router.push(paths.PROJECT_SUMMARY(project.id));
        }
    }

    async searchProjects(req: IPaginatedRequest) {
        try {
            this.loadingProjects = true;
            let result = this.filterByUser
                ? await projectService.getForUser(this.$user.id, req)
                : await projectService.getList(req);
            this.result = result;
        } catch(e) {
            this.$logger.logError(e);
            this.$toast.error('There was a problem loading the project list. This error has been logged.');
        } finally {
            this.loadingProjects = false;
        }
    }

    @Watch('userProjectsOnly')
    onUserProjectsOnlyChanged() {
        this.reloadTable();
    }

}

export const projectTableHeadersSmall: DataTableHeader<IProject>[] = [
    {
        text: 'Created',
        value: 'created',
        width: 120
    },
    {
        text: 'Examiner',
        value: 'examinerName'
    },
    {
        text: 'Case ID',
        value: 'caseId'
    },
    {
        align: 'end',
        text: 'Status',
        value: 'status'
    }
];

