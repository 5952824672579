// <copyright file="airbrake.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { appConfig } from '@/config/app.config';
import { Filter } from '@airbrake/browser/dist/filter/filter';
import { INotice, IOptions, Notifier } from '@airbrake/browser';

export const airbrakeConfig: IOptions = {
    environment: process.env.NODE_ENV,
    projectId: appConfig.AIRBRAKE_PROJECT_ID,
    projectKey: appConfig.AIRBRAKE_PROJECT_KEY,
    keysBlocklist: [
        'password',
        'newPassword'
    ],
};

let devErrorFilter: Filter = (notice: INotice) => {
    return appConfig.IS_DEV ? null : notice;
};

let notFoundErrorFilter: Filter = (notice: INotice) => {
    if((notice.error?.message ?? '')
        .toLowerCase()
        .indexOf('not found') > -1
    ){
        return null;
    }
    return notice;
};

const airbrake = new Notifier(airbrakeConfig);
airbrake.addFilter(devErrorFilter);
airbrake.addFilter(notFoundErrorFilter);
export default airbrake;
