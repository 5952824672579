
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Get } from 'vuex-pathify';
import { ISubscriber } from '@/types/resource.types';
import { paths } from '@/router/route-paths';

@Component({
    name: 'DesktopAppBar',
})
export default class DesktopAppBar extends Vue {

    /* Computed
    ============================================*/

    get userInitials(): string {
        if(this.$user === null) return '';
        return this.$user.firstName?.charAt(0)
            + this.$user.lastName?.charAt(0);
    }

    @Get('subscriber')
    readonly subscriber: ISubscriber;

    /* Methods
    ============================================*/

    goToSettings() {
        this.$router.push(paths.ACCOUNT_SETTINGS);
    }

}

