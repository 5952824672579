
import HasDataTable from '../hoc/HasDataTable';
import MxDataTable from '@/components/global/MxDataTable.vue';
import PaginatedRequest from '@/models/PaginatedRequest';
import User from '@/models/User';
import UserEditModal from '@/components/users/UserEditModal.vue';
import userService from '@/services/UserService';
import { Component } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { Get } from 'vuex-pathify';
import { IPaginatedRequest, IPaginatedResult } from '@/types/services.types';
import { IUser, IUserGroup, IAddUserResource } from '@/types/resource.types';
import { jsonCopy } from '@/utils/json.utils';

@Component({
    name: 'UserManagement',
    components: {
        MxDataTable,
        UserEditModal
    }
})
export default class UserManagement extends HasDataTable {

    /* Computed
    ============================================*/

    @Get('userGroups')
    userGroups: IUserGroup[];

    get seatsAvalable(){
        if(!this.addUserResource || this.addUserResource.quantity == null) return "";
        if(!this.addUserResource.canAdd) return "<span style='color: #d16767'>No seats available to add</span>";
        if(this.addUserResource.quantity == 1) return "<span style='color: #45a164'>1 seat available to add</span>";
        return "<span style='color: #45a164'>" + this.addUserResource.quantity + " seats avalable to add</span>";
    }
    get userGroupSelectItems() {
        if(!this.userGroups) return [];
        let items = this.userGroups.map(x => {
            return {
                text: x.name,
                value: x.id
            };
        });
        if(this.$user?.isAdmin) {
            return [{
                text: 'All Users',
                value: 0
            }].concat(items);
        }
        return items;
    }

    /* Data
    ============================================*/

    activeUser: IUser | null = null;
    headers: DataTableHeader<IUser>[] = userTableHeaders;
    loading: boolean = false;
    showUserModal: boolean = false;
    addUserResource: IAddUserResource | null = null;
    result: IPaginatedResult<IUser> | null = null;
    initialRequest: IPaginatedRequest | null = null;

    /* Methods
    ============================================*/

    async getUsers(query: IPaginatedRequest): Promise<void> {
        try {
            this.loading = true;
            this.result= await userService.getList(query);
        } catch(e){
            this.$toast.error('Something went wrong, an admin has been notified.');
            this.$logger.logError(e);
        } finally {
            this.loading = false;
        }
    }

    async createUser(): Promise<void> {
        this.addUserResource = await userService.getAddUserResource();
        if(!this.addUserResource?.canAdd) return;
        this.activeUser = new User();
        this.showUserModal = true;
    }

    async confirmDelete(user: IUser): Promise<boolean | undefined> {
        if(this.$user.id === user.id) {
            let warning = 'You can not delete your own account. Please contact an administrator';
            await this.$confirm(warning, {
                icon: 'mdi-alert',
                title: 'Confirm Delete'
            });
            return false;
        }
        let message = `Are you sure you want to delete the user account for ${user.firstName} ${user.lastName}`;
        return await this.$confirm(`${message}? This action cannot be undone!`, {
            icon: 'mdi-alert',
            title: 'Confirm Delete'
        });
    }

    async editUser(user: IUser) {
        try {
            this.loading = true;
            let userRes = await userService.get(user.id);
            this.activeUser = jsonCopy(userRes);
            this.showUserModal = true;
            this.addUserResource = await userService.getAddUserResource();
            this.loading = false;
        } catch(e) {
            this.$toast.error('Failed to load user, please try again.');
            this.$logger.logError(e);
        } finally {
            this.loading = false;
        }
    }

    async deleteUser(user: IUser): Promise<void> {
        try {
            let confirmed = await this.confirmDelete(user);
            if(!confirmed) return;
            await userService.delete(user.id);
            this.addUserResource = await userService.getAddUserResource();
            this.reloadTable();
        } catch(e) {
            this.$toast.error('Failed to delete user, please try again.');
            this.$logger.logError(e);
        } finally {
            this.loading = false;
        }
    }

    async reloadUsersTable() {
        this.addUserResource = await userService.getAddUserResource();
        this.reloadTable();
    }

    /* Lifecycle Hooks
    ============================================*/

    async created() {
        let initialRequest = new PaginatedRequest();
        initialRequest.orderBy = 'firstName';
        this.initialRequest = initialRequest;
        this.addUserResource = await userService.getAddUserResource();
    }

}

export const userTableHeaders: DataTableHeader<IUser>[] = [
    {
        text: 'First Name',
        value: 'firstName'
    },
    {
        text: 'Last Name',
        value: 'lastName'
    },
    {
        text: 'Email',
        value: 'email'
    },
    {
        align: 'center',
        text: 'Active',
        value: 'verified'
    },
    {
        align: 'center',
        text: 'Is Admin',
        value: 'isAdmin'
    },
    {
        align: 'end',
        text: 'Actions',
        value: 'actions',
        sortable: false
    }
];
