import { appConfig } from '@/config/app.config';
import { User, UserManager, WebStorageStateStore } from 'oidc-client';
 
export class AuthOidService {
    private userManager: UserManager;
 
    constructor() {
        const STS_DOMAIN: string = appConfig.AUTH_URL;
        const settings: any = {
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            authority: STS_DOMAIN,
            client_id: 'MedexAppApi',
            redirect_uri: appConfig.FRONTEND_URL,
            automaticSilentRenew: true,
            silent_redirect_uri: appConfig.FRONTEND_URL + '/silent-renew.html',
            response_type: 'code',
            scope: 'openid profile MedexAppApi',
            post_logout_redirect_uri: appConfig.FRONTEND_URL,
            filterProtocolClaims: true
        };
 
        this.userManager = new UserManager(settings);
    }
 
    public getUser(): Promise<User|null> {
        return this.userManager.getUser();
    }
 
    public login(): Promise<void> {
        return this.userManager.signinRedirect();
    }
 
    public async logout():Promise<void> {
        var response = await this.userManager.signoutRedirect();
        this.userManager.clearStaleState();
    }
 
    public getAccessToken(): Promise<string> {
        return this.userManager.getUser().then((data: any) => {
            return data.access_token;
        });
    }
}

export default new AuthOidService();