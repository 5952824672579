
import * as validationRules from '@/utils/validation.utils';
import MxModal from '@/components/global/MxModal.vue';
import userGroupService from '@/services/UserGroupService';
import userService from '@/services/UserService';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Get } from 'vuex-pathify';
import {
    IGroupPermissionDetails,
    IUser,
    IUserGroup,
    IUserGroupPermission,
    IUserGroupUser,
    Permission
} from '@/types/resource.types';
import { VForm } from '@/types/vue.types';

@Component({
    name: 'UserEditModal',
    components: {
        MxModal
    }
})
export default class UserEditModal extends Vue {

    $refs!: {
        _form: VForm;
    }

    @Prop({type: Number, required: false})
    readonly groupId: number;

    @Prop({type: Object as () => IUser, required: false})
    readonly user: IUser;

    @Prop({type: Boolean, required: false})
    readonly value: boolean;

    /* Computed
    ============================================*/

    @Get('userGroups')
    readonly userGroups: IUserGroup[];

    @Get('permissionsList')
    readonly permissionsList: IGroupPermissionDetails[];

    get isGroupUser(): boolean {
        return !this.isSelf && !!this.groupId;
    }

    get isNew(): boolean {
        return !this.user?.id;
    }

    get isSelf(): boolean {
        return this.user?.id === this.$user.id;
    }

    get showInviteAlert(): boolean {
        if(this.isGroupUser) return false;
        return this.isNew === false
            && this.user.verified === false
            && this.reinvited === false;
    }

    get title(): string {
        if(this.isSelf) return 'Edit Personal Info';
        if(this.isNew) return 'Add New User';
        if(this.isGroupUser) {
            let group = this.userGroups.find(x => x.id == this.groupId);
            if(group?.name) return `Edit User Permissions for ${group.name.replace(/group/ig, '')} group`;
        }
        return 'Edit User';
    }

    get userGroupUsers(): IUserGroupUser[] {
        let userId = this.user?.id ?? 0;
        return this.selectedGroupIds.map(userGroupId => {
            return { userId, userGroupId };
        });
    }

    get userPermissions(): IUserGroupPermission[] {
        let userId = this.user?.id ?? 0;
        let userGroupId = this.groupId;
        return this.selectedPermissions.map(permission => {
            return { permission, userId, userGroupId };
        });
    }

    /* Data
    ============================================*/

    loading: boolean = false;
    reinvited: boolean = false;
    selectedGroupIds: number[] = [];
    selectedPermissions: Permission[] = [];
    userRole: string = 'DefaultUser';

    /* Methods
    ============================================*/

    closeModal(): void {
        this.$emit('input', false);
    }

    emailRule(value: string): string | true {
        return validationRules.email(value);
    }

    groupRule(value: string[]): string | true {
        if(!value?.length) return 'Please select at least one user group';
        return true;
    }

    requiredRule(value: string): string | true {
        return validationRules.required(value);
    }

    async submit(): Promise<void> {
        try {
            if(!this.$refs._form.validate()) return;
            this.loading = true;
            if(this.isGroupUser) {
                await this.saveGroupUser();
            } else {
                await this.saveUser();
            }
            if(this.isSelf) {
                let updatedUser = await userService.get(this.$user.id);
                this.$store.commit('SET_USER', updatedUser);
            }
            this.$emit('saved');
            this.closeModal();
        } catch(e) {
            this.$toast.error('Something went wrong. Please try again.');
            this.$logger.logError(e);
        } finally {
            this.loading = false;
        }
    }

    async saveUser() {
        this.user.userGroups = this.userGroupUsers;
        return this.isNew
            ? await userService.create(this.user)
            : await userService.update(this.user.id, this.user);
    }

    async saveGroupUser() {
        this.user.permissions = this.userPermissions;
        return await userGroupService.updateGroupUser(this.groupId, this.user);
    }

    async sendInvite() {
        this.loading = true;
        try {
            await userService.sendInvite(this.user);
            this.reinvited = true;
        } catch(e) {
            this.$toast.error('Failed to re-send invite, please try again later.');
        } finally {
            this.loading = false;
        }
    }

    passwordRule(value: string): boolean | string {
        if(!value) return true;
        return this.$validation.password(value);
    }

    confirmPasswordRule(value: string): boolean | string {
        if(this.user.newPassword !== value) {
            return 'Passwords do not match';
        }
        return true;
    }


    /* Watchers
    ============================================*/

    @Watch('user')
    onUserChanged(newUser: IUser): void {
        this.reinvited = false;
        this.selectedGroupIds = newUser?.userGroups
            ? newUser.userGroups.map(x => x.userGroupId)
            : [];
        if(this.groupId && newUser?.permissions) {
            this.selectedPermissions = newUser.permissions
                .filter(x => x.userGroupId == this.groupId)
                .map(x => x.permission);
        }
    }

}

