// <copyright file="AuthJsonRequestFactory.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { appConfig } from '@/config/app.config';
import { IFetchRequestFactory } from '@/types/http.types';

export default class AuthJsonRequestFactory implements IFetchRequestFactory {

    create(url: string, method: string, body?: any): Request {
        let headers: Record<string, string> = {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        };
        if(url.indexOf('://') < 0) url = appConfig.AUTH_URL + url;
        return new Request(url, {
            credentials: 'include',
            method,
            body: body ? JSON.stringify(body) : undefined,
            mode: 'cors',
            headers
        });
    }

}
