
import * as validationRules from '@/utils/validation.utils';
import authService from '@/services/AuthService';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { IAuthResponse, ISignupRequest } from '@/types/services.types';
import { paths } from '@/router/route-paths';
import { VForm } from '@/types/vue.types';

@Component({
    name: 'LoginForm',
})
export default class LoginForm extends Vue {

    $refs!: {
        _form: VForm;
    }

    /* Data
    ============================================*/

    error: string | null = null;
    isNewAccount: boolean = false;
    formIsValid: boolean = false;
    loading: boolean = false;
    req: ISignupRequest = {
    	email: '',
    	firstName: '',
    	lastName: '',
    	password: '',
    }

    /* Login Methods
    ============================================*/

    async submitForm(): Promise<void> {
        this.error = null;
        this.loading = true;
        if(this.isNewAccount) {
            await this.doSignup();
        } else {
            await this.doLogin();
        }
        this.loading = false;
    }

    async doLogin(): Promise<void> {
        try {
            let { email, password } = this.req;
            let response = await authService.signIn({email, password});
            this.handleLoginResponse(response);
        } catch(error) {
            this.$toast.error('An unexpected error occurred. Please try again later.');
        }
    }

    async doSignup(): Promise<void> {
        try {
            let res = await authService.signUp(this.req);
            this.handleLoginResponse(res);
        } catch(error) {
            this.$toast.error('An unexpected error occurred. Please try again later.');
        }
    }

    handleFailedlogin(res: IAuthResponse): void {
        if(res.isLockedOut) {
            this.error = `
                Your account has been temporarily locked due to too many failed sign-in requests.
                Please try again in 5 minutes.
            `;
        }
        else {
            this.error = 'Invalid login credentials. Please check your username and password';
        }
    }

    handleLoginResponse(res: IAuthResponse): void {
        if(!res.succeeded) return this.handleFailedlogin(res);
        if(this.$route.query?.redirectTo) {
            this.$router.push(this.$route.query.redirectTo as string);
        } else {
            this.$router.push(paths.HOME);
        }
    }

    emailRule(value: string): true | string {
        return validationRules.email(value);
    }

    requiredRule(value: string): true | string {
        return validationRules.required(value);
    }

}

