
import fileService from '@/services/ProjectFileService';
import MxModal from '@/components/global/MxModal.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IPublicFileLog } from '@/types/resource.types';

@Component({
    name: 'PublicFileLogModal',
    components: {
        MxModal
    }
})
export default class PublicFileLogModal extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Number, required: true})
    readonly fileId: number;

    /* Data
    ============================================*/

    logs: IPublicFileLog[] = [];
    loading: boolean = false;

    /* Methods
    ============================================*/

    async getLogs() {
        try {
            this.loading = true;
            this.logs = await fileService.getPublicFileLog(this.fileId);
        } catch(e) {
            this.$toast.error('Failed to load file data, this error has been logged', {
                icon: 'mdi-alert',
                color: 'error'
            });
            this.$logger.logError(e);
        }
        this.loading = false;
    }

    /* Watchers
    ============================================*/

    created() {
        this.getLogs();
    }

}

