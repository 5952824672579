// <copyright file="index.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import createProjectStore from './create-project.store';
import pathify from 'vuex-pathify';
import resultsStore from '@/store/results.store';
import rootStore from '@/store/root.store';
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { IRootState } from '@/types/store.types';

Vue.use(Vuex);

export default new Vuex.Store({
    ...rootStore,
    modules: {
        'create-project': createProjectStore,
        'results': resultsStore
    },
    plugins: [pathify.plugin]
} as StoreOptions<IRootState>);
