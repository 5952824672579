// <copyright file="data.utils.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import PaginatedRequest from '@/models/PaginatedRequest';
import { DataOptions } from 'vuetify';
import { IPaginatedRequest } from '@/types/services.types';

export function createRequestFromDataOptions(options: DataOptions): PaginatedRequest {
    var req = new PaginatedRequest();
    req.page = options.page;
    req.perPage = options.itemsPerPage;
    if(options.sortBy?.length) {
        req.orderBy = options.sortBy[0];
    }
    if(options.sortDesc?.length) {
        req.desc = options.sortDesc[0];
    }
    return req;
}

export function createDataOptionsFromRequest(req: IPaginatedRequest): DataOptions {
    let options: DataOptions = {
        page: 1,
        itemsPerPage: 15,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
    };
    options.page = req.page;
    options.itemsPerPage = req.perPage;
    if(req.orderBy) {
        options.sortBy = [req.orderBy];
    }
    if(typeof req.desc === 'boolean') {
        options.sortDesc = [req.desc];
    }
    return options;
}

export function requestMatchesDataOptions(query: IPaginatedRequest, options?: DataOptions | null): boolean {
    if(!options || !query) return false;
    return (query.page === options.page)
        && (query.perPage === options.itemsPerPage)
        && ((!!options.sortDesc?.length) && options.sortDesc[0] === query.desc)
        && ((!!options.sortBy?.length) && options.sortBy[0] === query.orderBy);
}
