// <copyright file="PaginatedRequest.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { IPaginatedRequest } from '@/types/services.types';

export default class PaginatedRequest implements IPaginatedRequest {
    perPage: number;
    page: number = 1;
    orderBy: string = '';
    desc: boolean;
    keyword?: string;
    groupId?: number;

    constructor(perPage: number = 15) {
        this.perPage = perPage;
    }
}
