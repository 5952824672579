// <copyright file="FetchClient.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import AppLogger from '@/log/AppLogger';
import { AnyObject } from '@/types/generic.types';
import { HttpMethod, IDeserializedFetchResponse, IFetchClient, IFetchRequestFactory, IFetchResponseErrorHandler } from '@/types/http.types';
import { IAppLogger } from '@/types/log.types';
import { isJsonResponse } from '@/utils/http.utils';

/**
 * The FetchClient is a wrapper around the browser's Fetch API that abstracts the
 * deserialization and error handling of a response
 */
export default class FetchClient implements IFetchClient {

    private readonly _reqFactory: IFetchRequestFactory;
    private readonly _errHandler: IFetchResponseErrorHandler;

    constructor(
        requestFactory: IFetchRequestFactory,
        errorHandler: IFetchResponseErrorHandler
    ) {
        this._reqFactory = requestFactory;
        this._errHandler = errorHandler;
    }

    public async fetch<T>(
        url: string | Request,
        method: HttpMethod = 'GET',
        body?: AnyObject
    ): Promise<IDeserializedFetchResponse<T>> {
        let req;
        req = (typeof url === 'string')
            ? this._reqFactory.create(url, method, body)
            : url;
        let res = await fetch(req) as IDeserializedFetchResponse<T>;
        if(!res.ok) this._errHandler.handleBadResponse(res);
        if(isJsonResponse(res)) res.data = await res.json();
        else res.dataText = await res.text();
        return res;
    }

    get<T>(url: string): Promise<IDeserializedFetchResponse<T>> {
        return this.fetch<T>(url, 'GET');
    }

    post<T>(url: string, body?: AnyObject): Promise<IDeserializedFetchResponse<T>> {
        return this.fetch<T>(url, 'POST', body);
    }

    put<T>(url: string, body?: AnyObject): Promise<IDeserializedFetchResponse<T>> {
        return this.fetch<T>(url, 'PUT', body);
    }

    delete<T>(url: string): Promise<IDeserializedFetchResponse<T>> {
        return this.fetch<T>(url, 'DELETE');
    }

}
