// <copyright file="ProjectFileChunk.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { FileChunkStatus, IProjectFile, IProjectFileChunk } from '@/types/resource.types';

export default class ProjectFileChunk implements IProjectFileChunk {
    data: Blob | null;
    fileId: number;
    filename: string;
    originalFilename: string;
    part: number = 0;
    parts: number = 0;
    projectId: number;
    status: FileChunkStatus = FileChunkStatus.Queued;
    uploadId: string;
    retryCount: number = 0;

    constructor(file: IProjectFile, data: Blob | null = null) {
        this.data = data;
        this.fileId = file.id;
        this.filename = file.filename as string;
        this.originalFilename = file.originalFilename;
        this.projectId = file.projectId;
        this.uploadId = file.uploadId;
    }
}
