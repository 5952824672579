
import FileResultCard from './FileResultCard.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
    name: 'FileMetadata',
    components: {
        FileResultCard
    }
})
export default class FileMetadata extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Object, required: true})
    readonly metadata: Record<string, string>;

    /* Data
    ============================================*/

    get values(): FileMetadataItem[] {
        let self = this;
        if(!self.metadata) {};
        let data: FileMetadataItem[] = [];
        Object.keys(self.metadata).forEach(key => {
            let index = parseInt(key.split(':')[0]);
            if(!isNaN(index)) {
                data[index - 1] = {
                    name: key.split(': ')[1],
                    value: self.metadata[key]
                };
            }
        });
        return data;
    }

    /* Computed
    ============================================*/

    /* Methods
    ============================================*/

    /* Lifecycle Hooks
    ============================================*/

    /* Watchers
    ============================================*/

}

type FileMetadataItem = {
    name: string;
    value: string;
}

