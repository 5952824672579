
import MxInfoModal from '@/components/global/MxInfoModal.vue';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Get } from 'vuex-pathify';
import { IAppInfo } from '@/types/resource.types';

@Component({
    name: 'DashboardAppInfo',
    components: {
        MxInfoModal
    }
})
export default class DashboardAppInfo extends Vue {

    @Get('appInfo')
    readonly appInfo: IAppInfo | null;

    /* Data
    ============================================*/

    showInfoModal: boolean = false;

}

