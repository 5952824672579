
import FileComment from './FileComment.vue';
import fileService from '@/services/ProjectFileService';
import PaginatedRequest from '@/models/PaginatedRequest';
import ProjectFileComment from '@/models/ProjectFileComment';
import FileResultCardCollapse from './FileResultCardCollapse.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IPaginatedRequest, IPaginatedResult } from '@/types/services.types';
import { IProjectFile, IProjectFileComment } from '@/types/resource.types';

@Component({
    name: 'FileExaminationComments',
    components: {
        FileComment,
        FileResultCardCollapse
    }
})
export default class FileExaminationComments extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Object, required: true})
    readonly file: IProjectFile;

    /* Data
    ============================================*/

    newComment: IProjectFileComment | null = null;
    commentResult: IPaginatedResult<IProjectFileComment> | null = null;
    commentRequest: IPaginatedRequest = new PaginatedRequest(25);
    loading: boolean = false;

    /* Computed
    ============================================*/

    get title(): string {
        if(!this.commentResult) return 'Comments';
        return `Comments (${this.commentResult.totalCount})`;
    }

    /* Methods
    ============================================*/

    async confirmDelete(): Promise<boolean | undefined> {
        let message = 'Are you sure you want to delete this comment?';
        return await this.$confirm(message, {
            icon: 'mdi-alert',
            title: 'Confirm Delete'
        });
    }

    createNewComment() {
        this.newComment = new ProjectFileComment(this.$user.id, this.file.id);
    }

    async addComment() {
        try {
            this.loading = true;
            if(!this.newComment?.body) return;
            await fileService.addComment(this.newComment as IProjectFileComment);
            await this.loadComments();
            this.createNewComment();
        } catch(e) {
            this.$logger.logError(e);
            this.$toast.error('Could not add new comment. Please try again.');
        } finally {
            this.loading = false;
        }
    }

    async deleteComment(comment: IProjectFileComment) {
        try {
            let confirmed = await this.confirmDelete();
            if(!confirmed) return;
            this.loading = true;
            await fileService.deleteComment(comment);
            await this.loadComments();
        } catch(e) {
            this.$logger.logError(e);
            this.$toast.error('Could not delete comment. Please try again.');
        } finally {
            this.loading = false;
        }
    }

    async loadComments() {
        try {
            this.loading = true;
            this.commentRequest.orderBy = 'created';
            this.commentRequest.desc = true;
            let res = await fileService.getComments(this.file.id, this.commentRequest);
            this.commentResult = res;
        } catch(e) {
            this.$toast.error('An error occurred while loading comments');
            this.$logger.logError(e);
        } finally {
            this.loading = false;
        }
    }

    async updateComment(comment: IProjectFileComment) {
        try {
            this.loading = true;
            await fileService.updateComment(comment);
            await this.loadComments();
        } catch(e) {
            this.$logger.logError(e);
            this.$toast.error('Could not edit comment. Please try again.');
        } finally {
            this.loading = false;
        }
    }

    /* Lifecycle Hooks
    ============================================*/

    beforeMount() {
        this.loadComments();
    }

    mounted() {
        this.createNewComment();
    }

    /* Watchers
    ============================================*/

}

