
import FileResultCard from './FileResultCard.vue';
import MxModal from '@/components/global/MxModal.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IFileTestResult, IFileTestResults } from '@/types/resource.types';

@Component({
    name: 'FileModificationOverview',
    components: {
        FileResultCard,
        MxModal,
    }
})
export default class FileModificationOverview extends Vue {
    /* Props
    ============================================*/

    @Prop({ type: Object, required: true })
    readonly testResults: IFileTestResults;

    /* Data
    ============================================*/

    modificationDetailsOpen: boolean = false;
    validationDetailsOpen: boolean = false;

    /* Computed
    ============================================*/

    get allModificationTestsPassed(): boolean {
        return this.failedModificationResults.length === 0;
    }

    get allValidationTestsPassed(): boolean {
        return this.failedValidationResults.length === 0;
    }

    get modificationResults(): IFileTestResult[] {
        return this.testResults?.modificationResults ?? [];
    }

    get validationResults(): IFileTestResult[] {
        return this.testResults?.validationResults ?? [];
    }

    get failedModificationResults() {
        return this.modificationResults.filter(x => x.passed === false);
    }

    get failedValidationResults() {
        return this.validationResults.filter(x => x.passed === false);
    }

    /* Methods
    ============================================*/

    /* Lifecycle Hooks
    ============================================*/

    /* Watchers
    ============================================*/
}
