
import Breadcrumbs from '@/components/navigation/Breadcrumbs.vue';
import eventBus from '@/events/eventBus';
import events from '@/events/events';
import MainNavigation from '@/components/navigation/MainNavigation.vue';
import ServerErrorModal from '@/components/error/ServerErrorModal.vue';
import Vue from 'vue';
import { AnyObject } from '@/types/generic.types';
import { appConfig } from '@/config/app.config';
import { Component } from 'vue-property-decorator';
import { paths } from '@/router/route-paths';

@Component({
    name: 'GlobalErrorDialogs',
    components: {
        Breadcrumbs,
        MainNavigation,
        ServerErrorModal
    }
})
export default class GlobalErrorDialogs extends Vue {

    appConfig: AnyObject = appConfig;
    showingPermissionWarning: boolean | undefined = false;

    async showPermissionWarning() {
        if(this.showingPermissionWarning) return;
        this.showingPermissionWarning = true;
        await this.$confirm('You do not have sufficient permissions to perform this action. Please contact an administrator for assistance.', {
            icon: 'mdi-alert',
            title: 'Insufficient Permissions',
            buttonFalseText: '',
            buttonTrueText: 'Okay'
        });
        this.showingPermissionWarning = false;
    }

    async showAutoLoggedOutModal() {
        if(this.showingPermissionWarning) return;
        this.showingPermissionWarning = true;
        await this.$confirm('Your session has expired due to inactivity and you have been automatically logged out of your account. Please login again to continue.', {
            icon: 'mdi-alert',
            title: 'Session Expired',
            buttonFalseText: '',
            buttonTrueText: 'Return to Login',
            buttonTrueColor: '#000000'
        });
        const redirect = encodeURIComponent(this.$route?.path ?? '/');
        window.location.href = `${paths.LOGIN}?redirectTo=${redirect}`;
    }

    registerEventListeners() {
        eventBus.$on(events.PERMISSION_ERROR, () => {
            this.showPermissionWarning();
        });
        eventBus.$on(events.SESSION_TIMEOUT, () => {
            this.showAutoLoggedOutModal();
        });
    }

    beforeMount() {
        this.registerEventListeners();
    }

}

