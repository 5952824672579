// <copyright file="UserGroup.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { IGroupPermissionDetails, IUserGroup, IUserGroupUser } from '@/types/resource.types';

export default class UserGroup implements IUserGroup {
    public id: number;
    public name: string;
    public description: string;
    public permissions: IGroupPermissionDetails[] = [];
    public users: IUserGroupUser[] = [];
    public isDefault: boolean;
}
