// <copyright file="User.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { IUser, IUserGroupPermission, IUserGroupUser } from '@/types/resource.types';

export default class User implements IUser {
    id: number = 0;
    firstName: string = '';
    lastName: string = '';
    email: string = '';
    userGroups: IUserGroupUser[] = [];
    permissions: IUserGroupPermission[] = [];
    isAdmin: boolean = false;
    newPassword: string = '';
    verified: boolean = false;
}
