
import FileExaminationSummaryTable from '@/components/projects/summary/ProjectSummaryFilesTable.vue';
import ProjectDetailsSummary from '@/components/projects/summary/ProjectDetailsSummary.vue';
import ProjectFilesSummary from '@/components/projects/summary/ProjectFilesSummary.vue';
import projectService from '@/services/ProjectService';
import UsesResultsStore from '@/components/hoc/UsesResultsStore';
import { Component, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { ProjectStatus, IProject } from '@/types/resource.types';

@Component({
    name: 'ProjectSummaryView',
    components: {
        FileExaminationSummaryTable,
        ProjectDetailsSummary,
        ProjectFilesSummary
    }
})
export default class ProjectSummaryView extends UsesResultsStore {
    loading: boolean = false;
    projectId: number = 0;
    timer: ReturnType<typeof setInterval>;
    /* Methods
    ============================================*/

    async confirmCancel(): Promise<boolean | undefined> {
        return await this.$confirm(`
            Are you sure you want to delete this project? Any associated files will also be deleted.
            This action cannot be undone.`
        ,{
            icon: 'mdi-alert',
            title: 'Delete Project?'
        });
    }
    
    async cancelProject() {
        try {
            if(!(await this.confirmCancel())) return;
            this.loading = true;
            await projectService.delete(this.projectId);
            this.$router.push(this.$paths.PROJECTS);
        } catch(e) {
            this.$logger.logError(e);
        } finally {
            this.loading = false;
        }
    }
    get isComplete(){
        if(this.projectSummary?.project?.status == null) return false;
        return this.projectSummary?.project?.status == ProjectStatus.Complete
        || this.projectSummary?.project?.status == ProjectStatus.Error;
    }
    /* Lifecyce Hooks
    ============================================*/

    beforeDestroy(){
        clearInterval(this.timer);
    }
    beforeMount() {
        let projectId = parseInt(this.$route.params?.id);
        if(!projectId) return;
        this.projectId = projectId;
        this.loadProject(projectId);
        console.log("Is Complete " + this.isComplete);
        if(!this.isComplete){
            this.timer = setInterval(() =>{
                    console.log(this.projectSummary);
                    this.loadProjectSummary(this.projectId);
                },60000)
        }
    }

    /* Watchers
    ============================================*/

    @Watch('$route')
    onRouteChanged($routeNew: Route) {
        let id = parseInt($routeNew?.params?.id);
        if(id === this.project?.id) return;
        this.loadProject(id);
    }
    @Watch('projectSummary')
    onProjectChanged() {
        if(this.isComplete){
            console.log("Clearing interval");
            clearInterval(this.timer);
        }
    }

}

