
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { FileStatus } from '@/types/resource.types';

@Component({
    name: 'FileStatus',
})
export default class FileStatusChip extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Number, required: true})
    readonly status: FileStatus;

    @Prop({type: Boolean, required: false})
    readonly small: boolean;

    /* Data
    ============================================*/

    get color(): string {
        switch(this.status) {
            case FileStatus.Uploading:
            case FileStatus.Downloading:
            case FileStatus.Processing:
            case FileStatus.UploadComplete:
            case FileStatus.DownloadComplete:
                return 'primary';
            case FileStatus.ProcessingComplete:
                return 'success';
            case FileStatus.UploadError:
                return 'error';
            case FileStatus.ProcessingCompleteUnsupported:
                return 'warning';
            default:
                return '';
        }
    }

    get icon(): string {
        switch(this.status) {
            case FileStatus.ProcessingComplete:
                return 'mdi-check-circle';
            case FileStatus.Uploading:
            case FileStatus.Downloading:
            case FileStatus.Processing:
            case FileStatus.UploadComplete:
            case FileStatus.DownloadComplete:
                return 'mdi-progress-clock';
            case FileStatus.Error:
                return 'mdi-alert';
            case FileStatus.Draft:
                return 'mdi-file-edit';
            case FileStatus.ProcessingCompleteUnsupported:
                return 'mdi-alert';
            default:
                return '';
        }
    }

    get statusText(): string {
        switch(this.status) {
            case FileStatus.Uploading:
                return 'Uploading';
            case FileStatus.Downloading:
            case FileStatus.DownloadComplete:
            case FileStatus.UploadComplete:
            case FileStatus.Processing:
                return 'Processing';
            case FileStatus.ProcessingComplete:
                return 'Complete';
            case FileStatus.Error:
                return 'Error';
            case FileStatus.ProcessingCompleteUnsupported:
                return this.small ? 'Unsupported' : 'Unsupported Format';
            case FileStatus.Draft:
                return 'Pending';
            default:
                return 'Draft';
        }
    }

}

