
import eventBus from '@/events/eventBus';
import events from '@/events/events';
import Vue from 'vue';
import { appConfig } from '@/config/app.config';
import { Component, Prop } from 'vue-property-decorator';
import { IServerError } from '@/types/http.types';

@Component({
    name: 'ServerErrorModal',
})
export default class ServerErrorModal extends Vue {

    /* Data
    ============================================*/

    modalOpen: boolean = false;
    serverError: IServerError | null = null;

    /* Methods
    ============================================*/

    closeModal() {
        this.modalOpen = false;
    }

    /* Lifecycle Hooks
    ============================================*/

    mounted() {
        if(appConfig.IS_DEV) {
            eventBus.$on(events.SERVER_ERROR, (error: IServerError) => {
                this.serverError = error;
                this.modalOpen = true;
            });
        }
    }

}

