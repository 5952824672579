
import FileUploadForm from './FileUploadForm.vue';
import projectService from '@/services/ProjectService';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { IProject } from '@/types/resource.types';
import { Sync } from 'vuex-pathify';

@Component({
    name: 'UploadFileMetadata',
    components: {
        FileUploadForm
    }
})
export default class UploadFileMetadata extends Vue {

    /* Props
    ============================================*/

    @Sync('create-project/project')
    project: IProject;

    @Sync('create-project/uploading')
    uploading: boolean;

    /* Data
    ============================================*/

    abortController: AbortController | null = null;
    error: string | null = null;
    fileList: File[] = [];

    /* Methods
    ============================================*/

    cancelUploads() {
        this.abortController?.abort();
        this.uploading = false;
    }

    updateFileList(list: FileList) {
        let newList = [...this.fileList];
        for(let i=0; i<list.length; i++) {
            if(newList.find(x => x.name === list[i].name )) continue;
            newList.push(list[i]);
        }
        this.fileList = newList;
    }

    async uploadFiles() {
        try {
            this.error = null;
            this.uploading = true;
            let tasks = this.fileList.map(file => {
                return projectService.uploadProjectFileMetadata(
                    this.project.id,
                    file,
                    this.abortController?.signal
                );
            });
            let files = await Promise.all(tasks);
            this.$emit('uploadComplete', files);
            this.$emit('click:close');
        } catch(e) {
            this.error = `
                There was a problem while processing the upload.
                This error has been logged for review. Please try again later
            `;
            this.$logger.logError(e);
        }
        this.uploading = false;
    }

    /* Lifecycle Hooks
    ============================================*/

    created() {
        try {
            this.abortController = new AbortController();
            if(!this.abortController?.signal) throw 'No Abort Signal';
        } catch {
            this.$logger.logWarning('User does not have AbortController support');
        }
    }

    /* Watchers
    ============================================*/

}

