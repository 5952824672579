// <copyright file="DeviceService.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { BackendService } from './BackendService';
import { IDeviceList } from '@/types/resource.types';

export class DeviceService extends BackendService {

    private _baseUrl: string = '/device';

    public async getDeviceList(): Promise<IDeviceList> {
        let res = await this.jsonClient.get<IDeviceList>(`${this._baseUrl}/list`);
        return res.data;
    }
    public async getDeviceListCsv() : Promise<string> {
        let res = await this.jsonClient.get(`${this._baseUrl}/list/csv`);
        return res.dataText ? res.dataText.toString() : '';
    }
    public async getGenerationListCsv() : Promise<string> {
        let res = await this.jsonClient.get(`${this._baseUrl}/generation/list/csv`);
        return res.dataText ? res.dataText.toString() : '';
    }
    public async getDeviceGenerationListCsv() : Promise<string> {
        let res = await this.jsonClient.get(`${this._baseUrl}/device-generation/list/csv`);
        return res.dataText ? res.dataText.toString() : '';
    }

}

export default new DeviceService();
