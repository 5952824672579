
import UserGroupManagement from '@/components/users/UserGroupManagement.vue';
import UserGroupUserManagement from '@/components/users/UserGroupUserManagement.vue';
import UserManagement from '@/components/users/UserManagement.vue';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Get } from 'vuex-pathify';
import { IUserGroup } from '@/types/resource.types';

@Component({
    name: 'UsersView',
    components: {
        UserManagement,
        UserGroupManagement,
        UserGroupUserManagement,
    }
})
export default class UsersView extends Vue {

    /* Computed
    ============================================*/

    @Get('userGroups')
    readonly userGroups: IUserGroup[];

    /* Data
    ============================================*/

    tab: string | null = null;

    /* Methods
    ============================================*/

    mounted() {
        this.$store.dispatch('getPermissions');
        this.$store.dispatch('getUserGroups');
    }

}
