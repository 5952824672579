
import PaginatedRequest from '@/models/PaginatedRequest';
import ProjectsTable from '@/components/projects/ProjectsTable.vue';
import UserGroupSelector from '@/components/users/UserGroupSelector.vue';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { IPaginatedRequest } from '@/types/services.types';
import { paths } from '@/router/route-paths';
import { Permission } from '@/types/resource.types';

@Component({
    name: 'ProjectsView',
    components: {
        ProjectsTable,
        UserGroupSelector
    }
})
export default class ProjectsView extends Vue {

    /* Computed
    ============================================*/

    get canCreateProjects(): boolean {
        return this.$hasPermission(Permission.EditProjects);
    }

    /* Data
    ============================================*/

    initialRequest: IPaginatedRequest | null = null;

    /* Methods
    ============================================*/

    createRequest() {
        let req = new PaginatedRequest();
        req.orderBy = 'submitted';
        req.desc = true;
        this.initialRequest = req;
    }

    createProject() {
        this.$router.push(paths.PROJECT_CREATE);
    }

    /* Lifecycle Hooks
    ============================================*/

    beforeMount() {
        this.createRequest();
    }

}

