
import authOidService from '@/services/AuthOidService';
import authService from '@/services/AuthService';
import UpdatePasswordModal from './UpdatePasswordModal.vue';
import UserEditModal from '@/components/users/UserEditModal.vue';
import userService from '@/services/UserService';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { IUser } from '@/types/resource.types';
import { jsonCopy } from '@/utils/json.utils';
import { paths } from '@/router/route-paths';

@Component({
    name: 'AccountSettingsView',
    components: {
        UserEditModal,
        UpdatePasswordModal
    }
})
export default class AccountSettingsView extends Vue {

    /* Data
    ============================================*/

    projectNotifications: boolean = false;
    fileNotifications: boolean = false;
    referenceNotifications: boolean = false;
    showPasswordModal: boolean = false;
    showUserModal: boolean = false;
    user: IUser | null = null;

    /* Methods
    ============================================*/

    editUser() {
        this.user = jsonCopy(this.$user);
        this.showUserModal = true;
    }

    async savePreferences() {
        if(!this.user?.preferences) return;
        try {
            this.$loading = true;
            await userService.updatePreferences(this.user.preferences);
            let updatedUser = await authService.getCurrentUser();
            this.$store.commit('SET_USER', updatedUser);
            this.$toast.success('Your preferences have been successfully updated.', {
                icon: 'mdi-check-circle'
            });
        } catch(e) {
            this.$logger.logError(e);
            this.$toast.error('Failed to save preferences, this error has been logged for review', {
                icon: 'mdi-check-circle'
            });
        } finally {
            this.$loading = false;
        }

    }

    async logout(): Promise<void> {
        this.$loading = true;
        try {
    	    let x = await authService.signOut();
    	    await authOidService.logout();
        } catch(e) {
            this.$logger.logError(e);
        }
    	//window.location.href = paths.HOME;
    }

    /* Lifecycle Hooks
    ============================================*/

    created() {
        this.user = jsonCopy(this.$user);
    }
}
