// <copyright file="ProjectFileRating.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { IProjectFileRating } from '@/types/resource.types';

export default class ProjectFileRating implements IProjectFileRating {
    id: number = 0;
    projectFileId: number;
    userId: number;
    rating: number = 0;
    description: string;
    created: string;

    constructor(userId: number, projectFileId: number) {
        this.userId = userId;
        this.projectFileId = projectFileId;
    }
}
