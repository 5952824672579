// <copyright file="create-project.store.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { ActionTree, Module } from 'vuex';
import { IProject } from '@/types/resource.types';
import { IRootState } from '@/types/store.types';
import { make } from 'vuex-pathify';

const state: ICreateProjectState = {
    editing: false,
    fileList: [],
    project: null,
    uploading: false
};

const actions: ActionTree<ICreateProjectState, IRootState> = {

};

export default {
    namespaced: true,
    state,
    mutations: make.mutations(state),
    actions,
    getters: {}
} as Module<ICreateProjectState, IRootState>;


export interface ICreateProjectState {
    editing: boolean,
    fileList: File[],
    project: IProject | null;
    uploading: boolean
}
