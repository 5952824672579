
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { SignatureMatchType } from '@/types/resource.types';

@Component({
    name: 'SignatureMatchTypeChip',
})
export default class SignatureMatchTypeChip extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Number, required: false})
    readonly matchType: SignatureMatchType;

    /* Data
    ============================================*/

    get color(): string {
        switch(this.matchType) {
            case SignatureMatchType.FULL_MATCH:
                return 'success';
            default:
                return '';
        }
    }

    get icon(): string {
        switch(this.matchType) {
            case SignatureMatchType.FULL_MATCH:
                return 'mdi-check';
            default:
                return '';
        }
    }

    get display():boolean{
        return this.matchType == SignatureMatchType.FULL_MATCH;
    }

    /* Computed
    ============================================*/

    /* Methods
    ============================================*/

    /* Lifecycle Hooks
    ============================================*/

    /* Watchers
    ============================================*/

}

