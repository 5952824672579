
import Vue from 'vue';
import { AnyObject } from '@/types/generic.types';
import {
    Bold,
    BulletList,
    Code,
    Heading,
    History,
    HorizontalRule,
    Italic,
    Link,
    ListItem,
    OrderedList,
    Paragraph,
    Strike,
    TiptapVuetify,
    Underline
} from 'tiptap-vuetify';
import { Component, Prop } from 'vue-property-decorator';
import { Extension } from 'tiptap';

@Component({
    name: 'MxTextEditor',
    components: {
        TiptapVuetify
    }
})
export default class MxTextEditor extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Array, required: false, default: () => []})
    readonly extensions: Extension[];

    @Prop({type: Boolean, required: false})
    readonly minimal: boolean;

    @Prop({type: String, required: true})
    readonly value: string;

    /* Data
    ============================================*/

    extensionsMinimal: Extension[] = [
        Bold,
        Italic,
        Strike,
        Underline,
        ListItem,
        BulletList,
        OrderedList,
        Link,
    ];

    extensionsAll: (Extension | AnyObject[])[] = [
        Paragraph,
        [Heading, {
            options: {
                levels: [1, 2, 3, 4, 5]
            }
        }],
        Bold,
        Italic,
        Strike,
        Underline,
        Code,
        BulletList,
        ListItem,
        OrderedList,
        History,
        HorizontalRule,
        Link,
    ]

    /* Computed
    ============================================*/

    get appliedExtensions(): any[] {
        if(this.extensions?.length) return this.extensions;
        if(this.minimal) return this.extensionsMinimal;
        return this.extensionsAll;
    }

}

