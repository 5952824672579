// <copyright file="TraceLog.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { ITraceLog } from '@/types/log.types';

/**
 * In-memory trace log history class
 */
export class TraceLog implements ITraceLog {
    private _log: string[] = [];
    private readonly _logLimit = 50;

    addLog(log: string): void {
        this._log.push(`${new Date().toISOString()}: ${log}`);
        if(this._log.length > this._logLimit) {
            this._log.shift();
        }
    }

    getLog(): string[] {
        return [...this._log];
    }

    empty(): void {
        this._log = [];
    }
}

export default new TraceLog();
