// <copyright file="HasDataTable.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import Component from 'vue-class-component';
import Vue from 'vue';

@Component
export default class HasDataTable extends Vue {

    /* Methods
    ============================================*/

    /**
     * Find any datatable children and calls reload method
     */
    reloadTable(): void {
        this.$children.forEach((child: any) => {
            if(child && typeof child.reloadTableData === 'function') {
                child.reloadTableData();
            }
        });
    }

}
