
import PaginatedRequest from '@/models/PaginatedRequest';
import { AnyObject } from '@/types/generic.types';
import { appendQueryParams } from '@/utils/string.utils';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { createDataOptionsFromRequest, createRequestFromDataOptions } from '@/utils/data.utils';
import { createRequestFromQueryString, mergeRequests } from '@/utils/router.utils';
import { DataOptions } from 'vuetify';
import { IPaginatedRequest, IPaginatedResult } from '@/types/services.types';
import { VDataTable } from 'vuetify/lib';

/**
 * Higher order component for common table mixins
 */
@Component({
    name: 'MedexDataTable'
})
export default class MedexDataTable extends VDataTable {

    /* Props
    ============================================*/

    @Prop({type: [String, Number], required: false, default: '1'})
    readonly elevation: string | number;

    @Prop({type: Array, required: false, default() {
        return [10, 15, 25, 50];
    }})
    readonly pageOptions: number[];

    @Prop({type: Object, required: false})
    readonly result: IPaginatedResult<any>;

    @Prop({type: Boolean, required: false})
    readonly searchable: boolean;

    @Prop({type: String, required: false, default: ''})
    readonly title: string;

    @Prop({type: Boolean, required: false})
    readonly useQueryString: boolean;

    @Prop({type: Object, required: false})
    readonly initialRequest: IPaginatedRequest;

    @Prop({type: Object, required: false})
    readonly queryParameters?: AnyObject;
    
    @Prop({type: Boolean, required: false, default: true})
    readonly clearInterval: boolean;


    /* Data
    ============================================*/

    searchTimer: ReturnType<typeof setTimeout>;
    tableOptions: DataOptions | null = null;
    request: IPaginatedRequest = new PaginatedRequest();
    timer: ReturnType<typeof setInterval>;

    /* Computed
    ============================================*/

    get compedFooterProps(): AnyObject {
        if(this.$props.footerProps) return this.$props.footerProps;
        return {
            'items-per-page-options': this.pageOptions
        };
    }

    get otherProps(): Record<string, any> {
        return {
            ...this.$props,
            value: undefined
        };
    }

    get queryOverridesPerPageValue(): boolean {
        if(!this.useQueryString) return false;
        return Object.keys(this.$route.query)
            .map(x => x.toLowerCase())
            .find(x => x === 'perpage') !== undefined;
    }

    get results(): any[]  {
        if(!this.result) return [];
        return this.result.results;
    }

    get total() {
        return this.result?.totalCount ?? 0;
    }

    /* Methods
    ============================================*/

    initTable(): void {
        let req: IPaginatedRequest = this.useQueryString
            ? createRequestFromQueryString(this.$route.query)
            : new PaginatedRequest();
        if(this.initialRequest) {
            req = this.useQueryString
                ? mergeRequests(this.initialRequest, req)
                : mergeRequests(req, this.initialRequest);
        }
        this.request = req;
        this.tableOptions = createDataOptionsFromRequest(req);
    }

    filter() {
        if(!this.tableOptions) return;
        let req = createRequestFromDataOptions(this.tableOptions);
        req.keyword = this.request.keyword;
        req.groupId = this.request.groupId;
        this.request = req;
        this.reloadTableData();
    }

    /**
     * Delays filtering for a few ms to allow for additional keystrokes
     */
    filterByKeyword() {
        if(this.searchTimer) clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
            if(this.tableOptions == null) return;
            this.tableOptions.page = 1;
            this.filter();
        }, 400);
    }

    reloadTableData() {
        // TODO: Find a better way to filter out '0' vlaues for id-based properties
        if(this.request.groupId == 0) {
            this.request.groupId = undefined;
        }
        // Force request watcher
        this.request = {...this.request};
        this.$emit('filter', this.request);
    }

    setTableOptions(options: DataOptions) {
        this.tableOptions = options;
        this.filter();
    }

    /* Lifecycle Hooks
    ============================================*/

    beforeMount() {
        this.initTable();
    }
    
    created(){
        if(!this.clearInterval){
            this.timer = setInterval(() =>{
                    this.reloadTableData();},37000)
        }
    }
    /* Watchers
    ============================================*/

    @Watch('request', { deep: true })
    onRequestChanged(newRequest: IPaginatedRequest) {
        if(!newRequest || !this.useQueryString) return;
        let url: string;
        if(this.queryParameters) {
            url = appendQueryParams(this.$route.path, {...this.queryParameters, ...newRequest});
        } else {
            url = appendQueryParams(this.$route.path, newRequest);
        }
        history.pushState({}, '', url);
    }

    @Watch('perPage')
    onPerPageChange(perPage: number) {
        if(perPage !== this.request.perPage) {
            this.request.perPage = perPage;
            this.reloadTableData();
        }
    }

    @Watch('clearInterval')
    onClearIntervalChange() {
        this.reloadTableData();
        clearInterval(this.timer);
    }

    @Watch('request.groupId')
    onGroupIdChanged(groupId: number, oldGroupId: number) {
        if(groupId === oldGroupId) return;
        this.reloadTableData();
    }

    @Watch('request.keyword')
    onKeywordChanged(keywordNew: string, keywordOld: string) {
        if(keywordNew === keywordOld) return;
        this.filterByKeyword();
    }

}
