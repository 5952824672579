
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IProjectFileC2PA} from '@/types/resource.types';
import MxModal from '@/components/global/MxModal.vue';
import fileService from '@/services/ProjectFileService';

@Component({
    name: 'FileC2PAResult',
    components: {
        MxModal
    }
})
export default class FileC2PAResult extends Vue {

    /* Props
    ============================================*/
    @Prop({type: Object, required: false})
    readonly c2pa: IProjectFileC2PA;

    /* Computed
    ============================================*/

    /* Data
    ============================================*/
    showModal :boolean = false;
    manifest : String =  "";

    /* Methods
    ============================================*/
    
    async loadManifest(){
        try{
            this.$loading = true;
            this.manifest = await fileService.getC2PAManifest(this.c2pa.projectFileId);
            this.showModal = true;
        } 
        catch(e) {
            this.$logger.logError(e);
        } finally {
            this.$loading = false;
        }
    }
    
    get showResult(){
        return this.c2pa  && this.c2pa.hasManifest;
    }
    get icon(){
        if(!this.c2pa) return '';
        if(this.c2pa.hasValidManifest) return 'mdi-certificate';
        if(this.c2pa.hasManifest) return 'mdi-chemical-weapon';
        return '';
    }
    get type(){
        if(!this.c2pa) return '';
        if(this.c2pa.hasValidManifest) return '';
        if(this.c2pa.hasManifest) return '';
        return '';
    }

}

