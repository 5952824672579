// <copyright file="main.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import './sass/global.scss';
import 'tiptap-vuetify/dist/main.css';
import App from './App.vue';
import MedexPlugins from '@/plugins/MedexPlugins';
import router from './router';
import store from './store';
import Vue from 'vue';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import VueFilterDateParse from '@vuejs-community/vue-filter-date-parse';
import vuetify from './plugins/vuetify';
import VuetifyToast from '@/plugins/VuetifyToast';
import { appConfig } from './config/app.config';

if (!appConfig.IS_DEV && location.protocol !== 'https:') {
    // TODO: temporary, set this in nginx config
    location.replace(`https:${location.href.substring(location.protocol.length)}`);
} else {
    Vue.use(VueFilterDateParse);
    Vue.use(VueFilterDateFormat);
    Vue.use(MedexPlugins);
    Vue.use(VuetifyToast);
    Vue.config.productionTip = false;

    new Vue({
        router,
        store,
        vuetify,
        render: h => h(App)
    }).$mount('#app');
}
