// <copyright file="app.config.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

const appConfig = {
    AIRBRAKE_PROJECT_ID: 318597,
    AIRBRAKE_PROJECT_KEY: '6aca0366a98bce2d6ead2d81ecc29d93',
    BACKEND_URL: process.env.VUE_APP_BACKEND_URL,
    AUTH_URL: process.env.VUE_APP_AUTH_URL ? process.env.VUE_APP_AUTH_URL.toString() : '',
    FRONTEND_URL: process.env.VUE_APP_FRONTEND_URL?.toString(),
    ENV: process.env.NODE_ENV,
    IS_PROD: process.env.NODE_ENV === 'production',
    IS_DEV: process.env.NODE_ENV === 'development',
    IS_STAGING: process.env.NODE_ENV === 'staging',
    UPLOADS: {
        MAX_CHUNK_SIZE_MB: parseInt(process.env.VUE_APP_UPLOADS_MAX_CHUNK_SIZE_MB || '10'),
        MAX_FILES: parseInt(process.env.VUE_APP_UPLOADS_MAX_FILES || '50'),
        MAX_PARALLEL_REQUESTS: parseInt(process.env.VUE_APP_UPLOADS_MAX_PARALLEL_REQUESTS || '12')
    }
};

export { appConfig };
