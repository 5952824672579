
import SupportRequestForm from './SupportRequestForm.vue';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
    name: 'HelpPageView',
    components: {
        SupportRequestForm
    }
})
export default class HelpPageView extends Vue {}

