// <copyright file="BackendMultipartFormRequestFactory.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { appConfig } from '@/config/app.config';
import { IFetchRequestFactory } from '@/types/http.types';

export default class BackendMultipartFormRequestFactory implements IFetchRequestFactory {

    create(url: string, method: string, body?: FormData): Request {
        if(url.indexOf('://') < 0) url = appConfig.BACKEND_URL + url;
        return new Request(url, {
            credentials: 'include',
            method,
            body,
            mode: 'cors'
        });
    }

}
