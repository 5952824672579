
import HasForm from '../hoc/HasForm';
import MxTextEditor from '@/components/global/MxTextEditor.vue';
import supportService from '@/services/SupportService';
import { Component } from 'vue-property-decorator';
import { ISupportRequest } from '@/types/resource.types';
import { SupportRequest } from '@/models/SupportRequest';

@Component({
    name: 'SupportRequestForm',
    components: {
        MxTextEditor
    }
})
export default class SupportRequestForm extends HasForm {

    /* Data
    ============================================*/

    request: ISupportRequest | null = new SupportRequest();

    /* Methods
    ============================================*/

    resetRequest() {
        this.request = null; // Force a remount to reset vuetify form validation
        this.$nextTick(() => {
            this.request = new SupportRequest();
        });
    }

    async submit() {
        if(!this.request || !this.isValid()) return;
        this.request.userId = this.$user.id;
        try {
            this.$loading = true;
            await supportService.sendSupportRequest(this.request);
            this.resetRequest();
            this.$toast.success('Support request successfully sent', {
                icon: 'mdi-check'
            });
        } catch(e) {
            this.$toast.error('Your request failed to send. An administrator was notified');
        } finally {
            this.$loading = false;
        }
    }

    isValid(): boolean {
        if(!this.request?.body) {
            this.$toast.error('Please enter a message body', {
                icon: 'mdi-alert'
            });
            return false;
        }
        return this.validateForm();
    }

    mounted() {
        (this.$refs as any)._subjectField?.focus();
    }

}

