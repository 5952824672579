// <copyright file="AuthService.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { BackendService } from './BackendService';
import { IAuthResponse, ISigninRequest, ISignupRequest } from '@/types/services.types';
import { ISubscriber } from '@/types/resource.types';
import { IDeserializedFetchResponse } from '@/types/http.types';

export class AuthService extends BackendService {

    readonly path = '/auth';

    public async signIn(req: ISigninRequest): Promise<IAuthResponse> {
        let res = await this.authClient.post<IAuthResponse>(`${this.path}/sign-in`, req);
        return res.data;
    }

    public async signUp(req: ISignupRequest): Promise<IAuthResponse> {
        let res = await this.jsonClient.post<IAuthResponse>(`${this.path}/sign-up`, req);
        return res.data;
    }

    public async signOut(): Promise<boolean> {
        let res = await this.jsonClient.post<boolean>(`${this.path}/sign-out`);
        return res.data;
    }
/*
    public async getCurrentUser(): Promise<IAuthResponse> {
        let res = await this.jsonClient.get<IAuthResponse>(`${this.path}/current-user`);
        return res.data;
    }
*/
    public async getCurrentUser(): Promise<IDeserializedFetchResponse<IAuthResponse>> {
        let res = await this.jsonClient.get<IAuthResponse>(`${this.path}/current-user`);
        return res;
    }
    public async getSubscriber(): Promise<ISubscriber> {
        let res = await this.jsonClient.get<ISubscriber>('/subscriber');
        return res.data;
    }

    public async sendForgotPasswordEmail(email: string): Promise<void> {
        email = encodeURIComponent(email);
        await this.jsonClient.get(`${this.path}/forgot-password?email=${email}`);
    }

    public async resetPassword(userId: number, token: string, newPassword: string): Promise<void> {
        await this.jsonClient.post(`${this.path}/reset-password`, { userId, token, newPassword });
    }

}

// Default "singleton" instance
export default new AuthService();
