// <copyright file="ClientDownloadService.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { BackendService } from './BackendService';
import { IClientDownload } from '@/types/resource.types';

export class ClientDownloadService extends BackendService {

    public async getClientDownload(): Promise<IClientDownload> {
        let res = await this.jsonClient.get<IClientDownload>('/download/client');
        return res.data;
    }
    public async getClientKey(): Promise<string|undefined> {
        let res = await this.jsonClient.get<string>('/download/key');
        return res.dataText;
    }
    public async getClientLicense(): Promise<string|undefined> {
        let res = await this.jsonClient.get<string>('/download/license');
        return res.dataText;
    }

}

export default new ClientDownloadService();
