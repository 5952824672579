
import fileService from '@/services/ProjectFileService';
import projectService from '@/services/ProjectService';
import UploadProjectFilesModal from './upload/UploadProjectFilesModal.vue';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { IProject, IProjectFile } from '@/types/resource.types';
import { paths } from '@/router/route-paths';
import { Sync } from 'vuex-pathify';

@Component({
    name: 'CreateProjectDetails',
    components: {
        UploadProjectFilesModal
    }
})
export default class CreateProjectDetails extends Vue {

    /* Data
    ============================================*/

    loading: boolean = false;
    paths = paths;
    showUploadModal: boolean = false;

    /* Computed
    ============================================*/

    @Sync('create-project/editing')
    editing: boolean;

    @Sync('create-project/project')
    project: IProject;

    get hasFiles() {
        return !!this.project?.files?.length;
    }

    /* Methods
    ============================================*/

    async cancelProject() {
        try {
            if(!(await this.confirmCancel())) return;
            this.loading = true;
            await projectService.delete(this.project.id);
            this.$router.push(paths.PROJECTS);
        } catch(e) {
            this.$logger.logError(e);
        } finally {
            this.loading = false;
        }
    }

    async confirmCancel(): Promise<boolean | undefined> {
        return await this.$confirm(`
            Are you sure you want to delete this project? Any associated files will also be deleted.
            This action cannot be undone.`
        ,{
            icon: 'mdi-alert',
            title: 'Delete Project?'
        });
    }

    async deleteFile(file: IProjectFile) {
        let fileName = file.originalFilename || file.filename;
        let confirmed = await this.$confirm(`
            Are you sure you want to remove ${fileName} from this project?`
        ,{
            icon: 'mdi-alert',
            title: 'Confirm Delete'
        });
        if(!confirmed) return;
        try {
            this.loading = true;
            await fileService.delete(file.id);
            this.project = await projectService.get(this.project.id);
        } catch(e) {
            this.$toast.error('An error occurred while deleting this file. Please try again.');
            this.$logger.logError(e);
        } finally {
            this.loading = false;
        }
    }

    async handleUploadComplete(files: IProjectFile[]) {
        try {
            this.project = await projectService.get(this.project.id);
        } catch(e) {
            this.$logger.logWarning('Failed to re-load project after uploading files', e);
            this.project.files = this.project.files || [];
            this.project.files.concat(files);
        }
    }

    async submitProject() {
        try {
            this.loading = true;
            await projectService.submitProject(this.project);
            this.$router.push(paths.PROJECTS);
        } catch(e) {
            this.$toast.error('There was a problem submitting this project. Please try again');
        } finally {
            this.loading = false;
        }
    }

}

