// <copyright file="string.utils.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { AnyObject } from '@/types/generic.types';
import { hasOwnProperty } from './object.utils';

/**
 * Appends parameters to a url query string
 * @param url The url to append to
 * @param params Name/value record of parameters to append
 */
export function appendQueryParams(
    url: string,
    params: AnyObject | Record<string, string | number>
) : string {
    for(let key in params) {
        url = appendQueryParam(url, key, params[key]);
    }
    return url;
}

/**
 * Appends a parameter to a url query string
 * @param url The url to append to
 * @param param The name of the parameter
 * @param value The value of the parameter
 */
export function appendQueryParam(
    url: string,
    param: string,
    value: string | number | boolean | any[]
): string {
    if(!isValidQueryParamValue(value)) return url;
    if(url.indexOf('?') === -1) url += '?';
    else url += '&';
    url += `${param}=${paramterizeForQueryString(value)}`;
    return url;
}

/**
 * Stringifies a value for a query parameter
 * @param value The value to paramterize
 */
export function paramterizeForQueryString(value: any): string {
    if(Array.isArray(value)) {
        return value.map(paramterizeForQueryString).join(',');
    }
    try {
        return value.toString();
    } catch(e) {
        return JSON.stringify(value);
    }
}

export function isValidQueryParamValue(value: any): boolean {
    return (
        (typeof value === 'string' && !!value) ||
        typeof value === 'boolean' ||
        typeof value === 'number' ||
        Array.isArray(value)
    );
}
