// <copyright file="route-paths.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

const paths = {
    ACCOUNT_SETTINGS: '/settings',
    DEVICE_LIST: '/devices',
    PROJECTS: '/projects',
    PROJECT_CREATE: '/projects/create',
    PROJECT_DRAFT(projectId: string | number) {
        return `/projects/draft/${projectId}`;
    },
    PROJECT_SUMMARY(projectId: string | number) {
        return `/projects/summary/${projectId}`;
    },
    FILES: '/files',
    FILE_ANALYSIS(fileId: string | number) {
        return `/files/${fileId}`;
    },
    FILE_ANALYSIS_REPORT(fileId: string | number) {
        return `/files/${fileId}/report`;
    },
    HELP: '/support',
    HOME: '/',
    INVITE: '/invite',
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    UNAUTHORIZED: '/unauthorized',
    USERS: '/users',
    CLIENT_DOWNLOAD: '/client-download',
    WILDCARD: '*'
};

export { paths };
