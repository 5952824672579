
import DashboardAppInfo from '@/components/dashboard/DashboardAppInfo.vue';
import DashboardCaseInfo from '@/components/dashboard/DashboardCaseInfo.vue';
import PaginatedRequest from '@/models/PaginatedRequest';
import ProjectFilesTableSmall from '@/components/files/ProjectFilesTableSmall.vue';
import ProjectsTableSmall from '@/components/projects/ProjectsTableSmall.vue';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { IPaginatedRequest } from '@/types/services.types';
import { Permission } from '@/types/resource.types';

@Component({
    name: 'DashboardView',
    components: {
        DashboardAppInfo,
        DashboardCaseInfo,
        ProjectFilesTableSmall,
        ProjectsTableSmall
    }
})
export default class DashboardView extends Vue {

    /* Data
    ============================================*/

    initialFilesRequest: IPaginatedRequest | null = null;
    initialProjectsRequest: IPaginatedRequest | null = null;

    /* Methods
    ============================================*/

    get canCreateProjects(): boolean {
        return this.$hasPermission(Permission.EditProjects);
    }

    createRequests() {
        let req = new PaginatedRequest();
        req.orderBy = 'created';
        req.desc = true;
        req.perPage = 5;
        this.initialFilesRequest = { ...req };
        this.initialProjectsRequest = { ...req };
    }

    /* Lifecycle Hooks
    ============================================*/

    beforeMount() {
        this.createRequests();
    }
}

