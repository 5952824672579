// <copyright file="AppLogger.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

/* eslint-disable no-console */
import airbrake from './airbrake';
import traceLog from './TraceLog';
import { AnyObject } from '@/types/generic.types';
import { appConfig } from '@/config/app.config';
import { IAppLogger, LogSeverity } from '@/types/log.types';

export class AppLogger implements IAppLogger {

    trace(message: string, metadata?: any): void {
        if(appConfig.IS_DEV) {
            console.log(`****DEBUG TRACE****: ${message}`, metadata || '');
        }
        traceLog.addLog(message);
    }

    logError(
        error: string | Error,
        severity: LogSeverity = 'error',
        metadata?: AnyObject
    ): void {
        if(appConfig.IS_DEV) {
            console.error(error, metadata);
        }
        this.notifyAirbrake(error, severity, metadata);
    }

    logInfo(info: string, metadata?: AnyObject): void {
        if(appConfig.IS_DEV) {
            console.log(`****INFO****: ${info}`, metadata);
        }
        this.notifyAirbrake(info, 'info', metadata);
    }

    logWarning(warning: string | Error, metadata?: AnyObject): void {
        if(appConfig.IS_DEV) {
            console.warn(warning, metadata);
        }
        this.notifyAirbrake(warning, 'warning', metadata);
    }

    private notifyAirbrake(
        error: string | Error,
        severity: LogSeverity,
        metadata?: AnyObject
    ): void {
        if(appConfig.IS_DEV) return; // Don't log to airbrake in development
        airbrake.notify({
            error,
            context: { severity },
            params: {
                ...metadata,
                traceLog: traceLog.getLog()
            }
        });
    }

}

export default new AppLogger();
