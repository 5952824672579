// <copyright file="interactive-report.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

/* eslint-disable @typescript-eslint/explicit-function-return-type */

/**
 * Legacy javascript from Medex demo application for interactive reports
 * @param {*} containerElement
 */
export function handleInteractiveReportHtml(containerElement: any) {
    function toggle() {
	  var e = this;
	  //e = window.event ? event.srcElement : e.target;
	  if (e.className && e.className.indexOf('control') != -1) {
            var parent = e.parentNode;
            var ns = parent.nextElementSibling;
            if(!ns?.children) return;
            var c = ns.children;
            for (let i = 0; i < c.length; i++) {
                if (c[i].style.display !== 'table-cell') {
                    e.innerHTML = '-';
                    c[i].style.display = 'table-cell';
                } else {
                    //x.style.display = 'none';
                    e.innerHTML = '+';
                    c[i].style.display = 'none' ;
                }
            }
	  }
    };
    function resetAll() {

        var rows = containerElement.getElementsByTagName('td');

        for (var i = 0; i < rows.length; i++) {

		  var e = rows[i];
		  //e = window.event ? event.srcElement : e.target;
		  if (e.className && e.className.indexOf('control') != -1) {
                var oparent = e.parentNode;
                var parent = e.parentNode;
                var ns = parent?.nextElementSibling;
                if(ns)
                {
                    var c = ns.children;
                    var t;
                    for (t = 0; t < c.length; t++) {
                        if (c[t].style.display !== 'table-cell') {
                        } else {
					  e.innerHTML = '+';
					  c[t].style.display = 'none' ;
                        }
                    }
                }
		  }

        }

        var errorElem = containerElement.querySelector('#errorValues');
        containerElement.querySelector('#initErrorValues').style.display = 'block';
        errorElem.innerHTML = '';
        errorElem.style.display = 'none';

        var valElem = containerElement.querySelector('#valValues');
        containerElement.querySelector('#initValValues').style.display = 'block';
        valElem.innerHTML = '';
        valElem.style.display = 'none';

    };
    function showData()
    {
        var e = this;
        if(e.parentNode.className.indexOf('block-row')===-1)
        {
            return;
        }
        var rows = containerElement.getElementsByTagName('tr');
        var cells = containerElement.getElementsByTagName('td');
        //containerElement.querySelector("#dataValues").innerHTML = "";

        for (var i = 0; i < rows.length; i++) {
            rows[i].classList.remove('selected-row');
        }

        for (var i = 0; i < cells.length; i++) {
            cells[i].classList.remove('selected-row');
        }

        e.parentNode.className += ' selected-row';

        var list = e.parentNode.dataset.list;
        var dataList = JSON.parse(list);


        var html='<table class=\'table\'>';
        html += '<tr><th>Description</th><th>Value</th><th>Start</th><th>End</th></tr>';
        for (var i = 0; i < dataList.length; i++) {
            var errClass ='';
            if(dataList[i].ModError=='true') errClass+='mod-error';
            else if(dataList[i].ValError=='true') errClass+='val-error';
            html+='<tr class=\''+errClass+'\'>';
            html+='<td>' + dataList[i].Name + '</td>';
            html+='<td>' + dataList[i].Value + '</td>';
            html+='<td>' + dataList[i].Start + '</td>';
            html+='<td>' + dataList[i].End + '</td>';
            html+='</tr>';
        }
        html+='</table>';
        containerElement.querySelector('#dataValues').innerHTML = html;


        var errorList = e.parentNode.dataset.error;
        var errors = JSON.parse(errorList);

        var ehtml = '<table class=\'table\'>';
        ehtml += '<tr><th>Description</th><th>Start</th><th>End</th></tr>';
        for (var i = 0; i < errors.length; i++) {
            ehtml+='<tr>';
            ehtml+='<td>' + errors[i].Message + '</td>';
            ehtml+='<td>' + errors[i].Start + '</td>';
            ehtml+='<td>' + errors[i].End + '</td>';
            ehtml+='</tr>';
        }
        ehtml+='</table>';
        var errorElem = containerElement.querySelector('#errorValues');
        containerElement.querySelector('#initErrorValues').style.display = 'none';
        errorElem.innerHTML = ehtml;
        errorElem.style.display = 'block';


        var valList = e.parentNode.dataset.valid;
        var vals = JSON.parse(valList);

        var vhtml = '<table class=\'table\'>';
        vhtml += '<tr><th>Description</th><th>Start</th><th>End</th></tr>';
        for (var i = 0; i < vals.length; i++) {
            vhtml+='<tr>';
            vhtml+='<td>' + vals[i].Message + '</td>';
            vhtml+='<td>' + vals[i].Start + '</td>';
            vhtml+='<td>' + vals[i].End + '</td>';
            vhtml+='</tr>';
        }
        vhtml+='</table>';
        var valElem = containerElement.querySelector('#valValues');
        containerElement.querySelector('#initValValues').style.display = 'none';
        valElem.innerHTML = vhtml;
        valElem.style.display = 'block';
    };
    var elements = containerElement.getElementsByClassName('control');

    for (var i = 0; i < elements.length; i++) {
        elements[i].addEventListener('click', toggle);
    }


    var reset = containerElement.querySelector('#reset');
    reset.addEventListener('click', resetAll);

    var rows = containerElement.getElementsByTagName('td');

    for (var i = 0; i < rows.length; i++) {
        rows[i].addEventListener('click', showData);
    }
}
