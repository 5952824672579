
import FileFlagStatusChip from './FileFlagStatusChip.vue';
import fileService from '@/services/ProjectFileService';
import FileStatusChip from './FileStatusChip.vue';
import HasDataTable from '@/components/hoc/HasDataTable';
import MxDataTable from '@/components/global/MxDataTable.vue';
import UserGroupSelector from '@/components/users/UserGroupSelector.vue';
import { AnyObject } from '@/types/generic.types';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { FileAquisitionMethod, FileStatus, IProjectFile } from '@/types/resource.types';
import { IPaginatedRequest, IPaginatedResult } from '@/types/services.types';
import { paths } from '@/router/route-paths';

@Component({
    name: 'ProjectsTable',
    components: {
        FileStatusChip,
        FileFlagStatusChip,
        MxDataTable,
        UserGroupSelector
    }
})
export default class ProjectsTable extends HasDataTable {

    /* Props
    ============================================*/

    @Prop({type: Object, required: false})
    readonly initialRequest: IPaginatedRequest;

    /* Data
    ============================================*/

    processingComplete: FileStatus = FileStatus.ProcessingComplete;
    loadingFiles: boolean = false;
    filesResult: IPaginatedResult<IProjectFile> | null = null;
    userFilesOnly: boolean = this.$route.query.useronly !== 'false';
    queryParameters: AnyObject = {
        useronly: this.$route.query.useronly !== 'false'
    }

    /* Computed
    ============================================*/

    get headers() {
        return fileTableHeaders;
    }

    /* Methods
    ============================================*/

    goToFile(file: IProjectFile) {
        if(!file.id) return;
        if(file.status !== FileStatus.ProcessingComplete) return;
        this.$router.push(paths.FILE_ANALYSIS(file.id));
    }

    goToProject(file: IProjectFile) {
        if(file.status === FileStatus.Draft) {
            this.$router.push(paths.PROJECT_DRAFT(file.projectId));
        }
        this.$router.push(paths.PROJECT_SUMMARY(file.projectId));
    }

    async searchFiles(req: IPaginatedRequest) {
        try {
            this.loadingFiles = true;
            let filesResult = this.userFilesOnly
                ? await fileService.getForUser(this.$user.id, req)
                : await fileService.getList(req);
            this.filesResult = filesResult;
        } catch(e) {
            this.$logger.logError(e);
            this.$toast.error('There was a problem loading the file list. Please try again.');
        } finally {
            this.loadingFiles = false;
        }
    }

    getFileSource(aquisitionType: FileAquisitionMethod) {
        switch(aquisitionType) {
            case FileAquisitionMethod.DirectUpload:
                return 'Upload';
            case FileAquisitionMethod.DirectUrl:
                return 'Url';
            case FileAquisitionMethod.YouTubeDL:
                return 'YouTube';
            case FileAquisitionMethod.Facebook:
                return 'Facebook';
            default:
                return 'Direct Upload';
        }
    }

    setUserQueryParameter(useronly: boolean) {
        this.queryParameters = { useronly };
    }

    @Watch('userFilesOnly')
    onUserFilesOnlyChanged() {
        this.reloadTable();
    }

}

export const fileTableHeadersSmall: DataTableHeader<IProjectFile>[] = [
    {
        text: 'Uploaded',
        value: 'created'
    },
    {
        text: 'Filename',
        value: 'originalFilename',
        width: 300
    },
    {
        align: 'end',
        text: 'Flag Status',
        value: 'flagStatus'
    }
];

export const fileTableHeaders: DataTableHeader<IProjectFile>[] = [
    {
        text: 'Uploaded',
        value: 'created',
        width: 120
    },
    {
        text: 'File ID',
        value: 'id',
        width: 90
    },
    {
        align: 'center',
        text: 'File',
        value: 'actions',
        sortable: false
    },
    {
        align: 'center',
        text: 'Project',
        value: 'projectId',
        sortable: false,
        width: 130
    },
    {
        text: 'Filename',
        value: 'originalFilename'
    },
    // {
    //     text: 'Source',
    //     value: 'aquisitionType'
    // },
    {
        text: 'Flag Status',
        value: 'flagStatus',
        sortable: true,
        width: 120
    },
    {
        text: 'Status',
        value: 'status',
        width: 150
    }
];

