// <copyright file="ResourceService.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { appendQueryParams } from '@/utils/string.utils';
import { BackendService } from './BackendService';
import { IPaginatedRequest, IPaginatedResult } from '@/types/services.types';

/**
 * Base service class for handling REST requests for a single resource type
 */
export default abstract class ResourceService<T> extends BackendService {

    protected readonly path: string;

    constructor(resourceControllerPath: string) {
        super();
        this.path = resourceControllerPath;
    }

    public async getList(query: IPaginatedRequest): Promise<IPaginatedResult<T>> {
        let res = await this.jsonClient.get<IPaginatedResult<T>>(appendQueryParams(this.path, query));
        return res.data;
    }

    public async get(id: number | string): Promise<T> {
        let res = await this.jsonClient.get<T>(`${this.path}/${id}`);
        return res.data;
    }

    public async create(data: T): Promise<T> {
        let res = await this.jsonClient.post<T>(this.path, data);
        return res.data;
    }

    public async update(id: number | string, data: T): Promise<T> {
        let res = await this.jsonClient.put<T>(`${this.path}/${id}`, data);
        return res.data;
    }

    public async delete(id: number | string): Promise<boolean> {
        let res = await this.jsonClient.delete(`${this.path}/${id}`);
        return res.ok;
    }

}
