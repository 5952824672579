// <copyright file="ProjectFileComment.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { IProjectFileComment } from '@/types/resource.types';

export default class ProjectFileComment implements IProjectFileComment {
    id: number = 0;
    projectFileId: number;
    userId: number;
    body: string = '';
    created: string;
    edited?: string | undefined;

    constructor(userId: number, projectFileId: number) {
        this.userId = userId;
        this.projectFileId = projectFileId;
    }
}
