
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
    name: 'FileUploadForm',
})
export default class FileUploadForm extends Vue {

    @Prop({type: String, required: false, default: 'video/*'})
    readonly accept: string;

    /* Data
    ============================================*/

    isSaving: boolean = false;

    /* Methods
    ============================================*/

    handleInputChange(event: HTMLInputEvent) {
        if (!event.target?.files) return;
        this.$emit('change', event.target.files);
    }

}

