
import PaginatedRequest from '@/models/PaginatedRequest';
import ProjectFilesTable from '@/components/files/ProjectFilesTable.vue';
import UserGroupSelector from '@/components/users/UserGroupSelector.vue';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { IPaginatedRequest } from '@/types/services.types';

@Component({
    name: 'ProjectFilesView',
    components: {
        ProjectFilesTable,
        UserGroupSelector
    }
})
export default class ProjectFilesView extends Vue {

    /* Data
    ============================================*/

    initialRequest: IPaginatedRequest | null = null;

    /* Methods
    ============================================*/

    createRequest() {
        let req = new PaginatedRequest();
        req.orderBy = 'created';
        req.desc = true;
        this.initialRequest = req;
    }

    /* Lifecycle Hooks
    ============================================*/

    beforeMount() {
        this.createRequest();
    }
}

