// <copyright file="SupportRequest.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { ISupportRequest } from '@/types/resource.types';

export class SupportRequest implements ISupportRequest {
    userId: number = 0;
    subject: string = '';
    body: string = '';
}
