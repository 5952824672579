
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { IMenuItem } from '@/types/router.types';

@Component({
    name: 'MainNavigationItem',
})
export default class MainNavigationItem extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Object as () => IMenuItem, required: true})
    readonly item: IMenuItem

    /* Getters
    ============================================*/

    get isActive(): boolean {
    	return this.$route.path === this.item.path;
    }

    get target(): string {
        if(this.item?.externalUrl) return '_blank';
        return '_self';
    }

}

