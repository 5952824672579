// <copyright file="ProjectFileUploadQueue.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { appConfig } from '@/config/app.config';

/**
 * Class for keeping track of the active number of upload requests
 */
export default class ProjectFileUploadQueue {
    private activeRequests: number = 0;

    getActiveRequests(): number {
        return this.activeRequests;
    }

    increment(): void {
        this.activeRequests++;
    }

    decrement(): void {
        this.activeRequests--;
    }

    isFull(): boolean {
        return this.activeRequests > appConfig.UPLOADS.MAX_PARALLEL_REQUESTS;
    }

    clear(): void {
        this.activeRequests = 0;
    }
}
