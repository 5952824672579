
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
    name: 'DashboardCaseInfo',
})
export default class DashboardCaseInfo extends Vue {

    /* Props
    ============================================*/

    /* Data
    ============================================*/

    requestsAvailable = 10;
    requestsUsed = 7;

    /* Computed
    ============================================*/

    get percent() {
        return Math.floor((this.requestsUsed / this.requestsAvailable) * 100);
    }

    /* Methods
    ============================================*/

    /* Lifecycle Hooks
    ============================================*/

    /* Watchers
    ============================================*/

}

