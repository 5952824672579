
import Breadcrumbs from '@/components/navigation/Breadcrumbs.vue';
import DesktopAppBar from '@/components/navigation/DesktopAppBar.vue';
import GlobalErrorDialogs from '@/components/error/GlobalErrorDialogs.vue';
import MainNavigation from '@/components/navigation/MainNavigation.vue';
import MxLoader from '@/components/global/MxLoader.vue';
import ServerErrorModal from '@/components/error/ServerErrorModal.vue';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { IAuthorizedRoute } from './types/router.types';
import { IUser } from './types/resource.types';

@Component({
    name: 'App',
    components: {
        Breadcrumbs,
        DesktopAppBar,
        GlobalErrorDialogs,
        MainNavigation,
        MxLoader,
        ServerErrorModal
    }
})
export default class App extends Vue {

    $refs!: {
        nav: MainNavigation
    }

    $route!: IAuthorizedRoute

    /* Methods
    ============================================*/

    async loadRootData() {
        if(!this.$user) return;
        try {
            this.$loading = true;
            await this.$store.dispatch('loadRootData');
        } catch(e) {
            this.$logger.logError(e);
        } finally {
            this.$loading = false;
        }
    }

    toggleDrawer() {
        this.$refs.nav.toggle();
    }

    /* Watchers
    ============================================*/

    @Watch('$user')
    onUserChanged(newUser: IUser, oldUser: IUser) {
        if(!newUser) return;
        if(oldUser?.id !== newUser?.id) {
            this.loadRootData();
        }
    }

}

