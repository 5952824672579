// <copyright file="MedexPlugins.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import * as validationRules from '@/utils/validation.utils';
import logger from '@/log/AppLogger';
import pluralize from 'pluralize';
import { isValidDateObject } from '@/utils/object.utils';
import { paths } from '@/router/route-paths';
import { Permission } from '@/types/resource.types';
import { userHasGroupPermission, userHasPermission } from '@/utils/auth.utils';

export default function init(Vue: Vue.VueConstructor): void {

    // Include global validation rules
    Vue.prototype.$validation = validationRules;

    // Include router paths
    Vue.prototype.$paths = paths;

    // Include global logger
    Vue.prototype.$logger = logger;
    Vue.config.errorHandler = function (err, _vm, info) {
        logger.logError(err, 'error', { info });
    };

    Vue.filter('pluralize', function(value: string, length: number) {
        if(!value) return '';
        return pluralize(value, length);
    });

    Vue.filter('toUtcString', function(value: string | Date) {
        if(typeof value === 'string') {
            value = new Date(value);
        }
        if(isValidDateObject(value)) {
            return (value as Date)
                .toUTCString()
                .split(', ')[1]
                .replace('GMT', 'UTC');
        }
        return '';
    });

    Vue.filter('localeDateString', function(value: string | Date) {
        if(typeof value === 'string') {
            let date = new Date(value);
            return date.toLocaleDateString();
        }
        if(isValidDateObject(value)) {
            return value.toLocaleDateString();
        }
        return '';
    });

    Vue.mixin({
        computed: {
            $user: { // Include user state globally
                get() {
                    return this.$store?.state?.user;
                }
            },
            $hasAdvancedSubscription: {
                get() {
                    return this.$store?.getters?.hasAdvancedSubscription ?? false;
                }
            },
            $loading: { // Convenience property for displaying global loader
                get() {
                    return this.$store.state?.showGlobalLoader;
                },
                set(val: boolean) {
                    if(!this.$store) return;
                    this.$store.commit('SET_SHOW_GLOBAL_LOADER', val);
                }
            }
        },
        methods: {
            $hasPermission(permission: Permission) {
                if(!this.$user) return false;
                return userHasPermission(this.$user, permission);
            },
            $hasGroupPermission(groupId: number, permission: Permission) {
                if(!this.$user) return false;
                return userHasGroupPermission(this.$user, groupId, permission);
            }
        }
    });
}
