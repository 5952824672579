// <copyright file="ProjectFileUploader.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import CryptoJS from 'crypto-js';
import logger from '@/log/AppLogger';
import ProjectFileChunk from '@/models/ProjectFileChunk';
import projectFileService from '@/services/ProjectFileService';
import ProjectFileUploadQueue from './ProjectFileUploadQueue';
import { appConfig } from '@/config/app.config';

import {
    FileChunkStatus,
    FileUploadProgress,
    FileUploadStatus,
    IProjectFile
} from '@/types/resource.types';
import { FileReaderState } from '@/types/file.types';

export default class ProjectFileUploader {

    private abortController: AbortController | null = null;
    private chunkList: ProjectFileChunk[] = [];
    private started: boolean = false;
    private status: FileUploadStatus;
    private hashIndex: number = 0;
    private uploadQueue: ProjectFileUploadQueue;
    private sendChunksInterval: ReturnType<typeof setInterval> | null = null;
    private readonly hasher = CryptoJS.algo.SHA256.create();
    private readonly chunkSize: number = appConfig.UPLOADS.MAX_CHUNK_SIZE_MB * 1000 * 1000;
    private readonly file: File;
    private readonly projectFile: IProjectFile;
    private readonly maxRetries: number = 5;
    private readonly retryDelayMs: number = 5000;
    private readonly totalChunks: number;
    private onCompleteOrErrorCallback: () => any;

    constructor(file: File, projectfile: IProjectFile, uploadQueue: ProjectFileUploadQueue) {
        this.status = FileUploadStatus.NotStarted;
        this.file = file;
        this.projectFile = projectfile;
        this.totalChunks = Math.ceil(this.file.size / (this.chunkSize));
        this.uploadQueue = uploadQueue;
        this._handleOffline = this._handleOffline.bind(this);
        this._handleOnline = this._handleOnline.bind(this);
        try {
            this.abortController = new AbortController();
            if(!this.abortController?.signal) throw 'No Abort Signal';
        } catch {
            this._trace('User does not have AbortController support');
        }
    }

    /**
     * Get the file upload progress
     */
    public getProgress(): FileUploadProgress {
        return {
            currentChunk: this._getChunksComplete() + 1,
            totalChunks: this.totalChunks,
            filename: this.file.name,
            percent: this._getPercentComplete(),
            status: this.status,
        };
    }

    /**
     * Get the project file
     */
    public getProjectFile(): IProjectFile {
        return this.projectFile;
    }

    /**
     * Get the upload status
     */
    public getStatus(): FileUploadStatus {
        return this.status;
    }

    /**
     * Set a callback function when the upload fails or completes
     */
    public onCompleteOrError(onCompleteOrErrorCallback: () => any): void {
        this.onCompleteOrErrorCallback = onCompleteOrErrorCallback;
    }

    /**
     * Attach connection listeners and begin upload
     */
    public start(): void {
        if(this.started) return;
        this.started = true;
        this._createChunkList();
        this._attachConnectionEvents();
        this._trace('Starting upload of ' + this.file.name);
        this._startChunkQueue();
    }

    /**
     * Cancels the upload
     */
    public cancel(): void {
        if(this.sendChunksInterval) clearInterval(this.sendChunksInterval);
        this.status = FileUploadStatus.Canceled;
        this._removeConnectionEvents();
        this.abortController?.abort();
        this.onCompleteOrErrorCallback();
    }

    /*============================================================
     == Private Methods
    /============================================================*/

    private _attachConnectionEvents(): void {
        window.addEventListener('online', this._handleOnline);
        window.addEventListener('offline', this._handleOffline);
    }

    private _removeConnectionEvents(): void {
        window.removeEventListener('online', this._handleOnline);
        window.removeEventListener('offline', this._handleOffline);
    }

    private _handleOffline(): void {
        if(this.status !== FileUploadStatus.Uploading) return;
        this.status = FileUploadStatus.Offline;
        if(this.sendChunksInterval) clearInterval(this.sendChunksInterval);
        this._trace('User\'s internet connection is offline');
    }

    private _handleOnline(): void {
        if(this.status !== FileUploadStatus.Offline) return;
        this._trace('User\'s internet connection is back online');
        this.status = FileUploadStatus.Uploading;
        this._startChunkQueue();
    }

    private _startChunkQueue(): void {
        if(this.sendChunksInterval) clearInterval(this.sendChunksInterval);
        this.sendChunksInterval = setInterval(() => {
            this._sendChunks();
        }, 500);
    }

    /* Chunk Handlers
    ============================================*/

    private _createChunkList(): void {
        let self = this;
        self.chunkList = [];
        for(let i=0; i<self.totalChunks; i++) {
            let chunk = new ProjectFileChunk(self.projectFile);
            chunk.part = i + 1; // Chunk parts are not zero-based
            chunk.parts = self.totalChunks;
            chunk.status = FileChunkStatus.Queued;
            self.chunkList[i] = chunk;
        }
    }

    private _getNextChunk(): Promise<ProjectFileChunk | null> {
        // Prioritize chunks in retry state
        let chunk = this.chunkList.find(x =>x.status === FileChunkStatus.Retrying);
        if(!chunk) chunk = this.chunkList.find(x => x.status === FileChunkStatus.Queued);
        if(!chunk) return Promise.resolve(null);
        // Don't read chunk if data has already been read/set
        if(chunk.data) return Promise.resolve(chunk);
        return this._readChunk(chunk);
    }

    /**
     * Get portion of the file of x bytes corresponding to chunkSize
     */
    private _readChunk(chunk: ProjectFileChunk): Promise<ProjectFileChunk | null> {
        let self = this;
        return new Promise(resolve => {
            let length = self.totalChunks === 1 ? self.file.size : self.chunkSize;
            let start = length * (chunk.part - 1);
            let reader = new FileReader();

            reader.onload = () => {
                let result = reader.result as ArrayBuffer;
                let data = new Blob([result], { type: 'application/octet-stream' });
                chunk.data = data;
                self._hashChunk(chunk, result);
                resolve(chunk);
            };

            reader.onerror = () => {
                chunk.status = FileChunkStatus.Error;
                logger.logError('File reader failed to read chunk', 'error', {
                    readerError: reader.error,
                    fileId: this.projectFile?.id ?? 'unknown',
                    fileName: this.file.name
                });
                this._abortUpload();
                resolve(null);
            };

            if(reader.readyState != FileReaderState.DONE) {
                reader.abort();
            }

            reader.readAsArrayBuffer(self.file.slice(start, start + length));
        });
    }

    /**
     * Manage the whole upload by calling getChunk & sendChunk
     * handle errors & retries and dispatch events
     */
    private async _sendChunks(): Promise<void> {
        if(this._canSendChunks() === false) return;

        this.status = FileUploadStatus.Uploading;

        if(this.uploadQueue.isFull() === false) {
            let chunk = await this._getNextChunk();
            if(chunk) {
                this._sendChunk(chunk);
            }
        }

        if(this._canCompleteUpload()) {
            if(this.sendChunksInterval) clearInterval(this.sendChunksInterval);
            this._completeUpload();
        }
    }

    private async _sendChunk(chunk: ProjectFileChunk): Promise<void> {
        if(this._canUploadChunk(chunk) === false) return;
        try {
            chunk.status = FileChunkStatus.Uploading;
            await projectFileService.uploadFileChunk(
                chunk,
                this.uploadQueue,
                this.abortController?.signal
            );
            chunk.status = FileChunkStatus.Complete;
            this._trace(`Chunk ${chunk.part} of ${this.totalChunks} complete`);
        } catch(e) {
            this._retryChunk(chunk);
        }
    }

    private async _completeUpload(): Promise<void> {
        try {
            if(!this._canCompleteUpload()) return;
            this.status = FileUploadStatus.Completing;
            await this._confirmFileIsHashed();
            await projectFileService.completeUpload(this.projectFile);
            this._trace('Upload complete.');
            this.status = FileUploadStatus.Complete;
            this._removeConnectionEvents();
            this.onCompleteOrErrorCallback();
        } catch(e) {
            this._abortUpload(e);
        }
    }

    /* SHA-256 Hashing
    ============================================*/

    private _hashChunk(chunk: ProjectFileChunk, data: ArrayBuffer): void {
        let self = this;
        let nextHashIndex = self.hashIndex + 1;

        // Ensure the chunks are hashed in order by comparing the part to the hashIndex
        if(chunk.part > nextHashIndex) {
            // If chunk is not in order, set a timeout to try again
            setTimeout(() => {
                self._hashChunk(chunk, data);
            }, 50);
            return;
        }

        if(chunk.part < nextHashIndex) return; // Chunk already hashed
        let buffer = CryptoJS.lib.WordArray.create(data as unknown as number[]);
        self.hasher.update(buffer);
        self.hashIndex++;

        // Check if this was the last chunk so that we can complete the hash
        if(chunk.part === chunk.parts) {
            self.projectFile.sha256Hash = self.hasher.finalize().toString();
            this._trace(`Hash complete: ${self.projectFile.sha256Hash}`);
        }
    }

    /**
     * Resolves once the projectFile.hash property is set by _hashChunk
     */
    private async _confirmFileIsHashed(): Promise<void> {
        this._trace('Checking file hashing status');
        return new Promise((resolve) => {
            if(this.projectFile.sha256Hash) {
                this._trace('Hash confirmed');
                resolve();
            }
            else {
                let hashRetryMs = 500;
                this._trace(`Hash not yet complete. Checking again in ${hashRetryMs}ms`);
                setTimeout(() => {
                    this._confirmFileIsHashed().then(resolve);
                }, hashRetryMs);
            }
        });
    }

    /* Error and Retry Handling
    ============================================*/

    private _abortUpload(error?: Error | string): void {
        this.status = FileUploadStatus.Error;
        this.abortController?.abort();
        this._removeConnectionEvents();
        if(this.sendChunksInterval) clearInterval(this.sendChunksInterval);
        this._trace('Error while uploading. Upload aborted');
        if(error) {
            logger.logError(error, 'error', {
                fileId: this.projectFile?.id ?? 'unknown'
            });
        }
        this.onCompleteOrErrorCallback();
    }

    private _retryChunk(chunk: ProjectFileChunk): void {
        if(chunk.retryCount > this.maxRetries) {
            chunk.status = FileChunkStatus.Error;
            this._abortUpload(`Chunk ${chunk.part} exceeded retry count`);
            return;
        }
        setTimeout(() => {
            chunk.retryCount++;
            chunk.status = FileChunkStatus.Retrying;
        }, this.retryDelayMs);
    }

    /* State Helpers
    ============================================*/

    private _canUploadChunk(chunk: ProjectFileChunk): boolean {
        return chunk.status === FileChunkStatus.Queued
            || chunk.status === FileChunkStatus.Retrying;
    }

    private _getPercentComplete(): number {
        let percent =  Math.round((100 / this.totalChunks) * this._getChunksComplete());;
        return percent > 0 ? percent : 1;
    }

    private _getChunksComplete(): number {
        return this.chunkList.filter(x =>
            x.status === FileChunkStatus.Complete
        ).length;
    }

    private _canCompleteUpload(): boolean {
        return this.status !== FileUploadStatus.Complete
            && this.status !== FileUploadStatus.Completing
            && this._getChunksComplete() >= this.totalChunks
            && this.status !== FileUploadStatus.Offline;
    }

    private _canSendChunks(): boolean {
        return this.status !== FileUploadStatus.Complete
            && this.status !== FileUploadStatus.Error
            && this.status !== FileUploadStatus.Canceled
            && this.status !== FileUploadStatus.Offline;
    }

    private _trace(message: string): void {
        logger.trace(`ProjectFile ${this.projectFile.id} - ${message}`);
    }

}
