
// <copyright file="file.utils.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>
import { IProjectFileChunk } from '@/types/resource.types';

export function createChunkFormData(chunk: IProjectFileChunk): FormData {
    let formData = new FormData();
    formData.append('file', chunk.data as Blob, chunk.filename);
    formData.append('part', chunk.part.toString());
    formData.append('parts', chunk.parts.toString());
    formData.append('filename', chunk.filename);
    formData.append('originalFilename', chunk.originalFilename);
    formData.append('fileId', chunk.fileId.toString());
    formData.append('uploadId', chunk.uploadId);
    return formData;
}
