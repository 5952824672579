
import MxModal from '@/components/global/MxModal.vue';
import userService from '@/services/UserService';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IChangePasswordResponse } from '@/types/services.types';
import { VForm } from '@/types/vue.types';

@Component({
    name: 'UserEditModal',
    components: {
        MxModal
    }
})
export default class UserEditModal extends Vue {

    $refs!: {
        _form: VForm;
    }

    @Prop({type: Boolean, required: false})
    readonly value: boolean;

    /* Data
    ============================================*/

    currentPassword: string = '';
    newPassword: string = '';
    loading: boolean = false;
    error: string | null = null;

    /* Methods
    ============================================*/

    closeModal(): void {
        this.$emit('input', false);
    }

    requiredRule(value: string): string | boolean {
        return this.$validation.required(value);
    }

    async submit(): Promise<void> {
        try {
            if(!this.$refs._form.validate()) return;
            this.loading = true;
            this.error = null;
            let res = await userService.updatePassword(
                this.$user.id,
                this.currentPassword,
                this.newPassword
            );
            this.handleResponse(res);
        } catch(e) {
            this.$toast.error('Something went wrong. Please try again.');
            this.$logger.logError(e);
        } finally {
            this.loading = false;
        }
    }

    handleResponse(res: IChangePasswordResponse) {
        if(res.succeeded) {
            this.closeModal();
            return;
        }
        if(res.errors?.length > 0) {
            this.error = res.errors[0].description;
        } else {
            this.error = 'Password update failed, please double check your information';
        }
    }

    passwordRule(value: string): boolean | string {
        if(!value) return 'Password is required';
        return this.$validation.password(value);
    }

    confirmPasswordRule(value: string): boolean | string {
        if(this.newPassword !== value) {
            return 'Passwords do not match';
        }
        return true;
    }

}

