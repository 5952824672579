// <copyright file="array.utils.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

/**
 * Groups an array of objects into an object by key
 * @param arr The array to group
 * @param key The key name to group by
 */
export function groupBy<T>(arr: any[], key: string): { [key: string]: T[] } {
    let groups: { [key: string]: T[] } = {};
    for(let i=0; i<arr.length; i++) {
        let val = arr[i][key];
        if(!val) continue;
        if(!Array.isArray(groups[val])) groups[arr[i][key]] = [];
        groups[key].push(arr[i]);
    }
    return groups;
}

export function searchStringArray(keyword: string, list: string[]): string[] {
    if(!keyword) return list;
    let q = keyword.toLowerCase();
    return list.filter(x => !!x && x.toLowerCase().indexOf(q) > - 1);
}
