
import FileFlagStatusChip from '@/components/files/FileFlagStatusChip.vue';
import FileRating from '@/components/files/FileRating.vue';
import FileResultCardCollapse from './FileResultCardCollapse.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IFileSummary, IProjectFile } from '@/types/resource.types';


@Component({
    name: 'FileSummary',
    components: {
        FileFlagStatusChip,
        FileResultCardCollapse,
        FileRating
    }
})
export default class FileSummary extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Object as () => IProjectFile, required: true})
    readonly file: IProjectFile;

    @Prop({type: Object as () => IFileSummary, required: true})
    readonly summary: IFileSummary;

    collapsed : Boolean = true;

    changeCollapsed(){
        if(this.collapsed == true) this.collapsed = false;
        else this.collapsed = true;
    }
}

