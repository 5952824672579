
import FileResultCard from './FileResultCard.vue';
import MxModal from '@/components/global/MxModal.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import BetaChip from '../BetaChip.vue';
import { FileFlagStatus,  IFileGenerationHistory, IProjectFile, IFileClassification } from '@/types/resource.types';

@Component({
    name: 'FileGenerationHistory',
    components: {
        FileResultCard,
        MxModal,
        BetaChip
    }
})
export default class FileGenerationHistory extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Object, required: false})
    readonly history: IFileGenerationHistory;

    @Prop({type: String, required: false})
    readonly classifiedBrand:String;

    @Prop({type: String, required: false})
    readonly classifiedModel:String;

    @Prop({type: Object, required: false})
    readonly classifiedLastGeneration:IFileClassification;

    @Prop({type: Object, required: true})
    readonly file: IProjectFile;

    /* Computed
    ============================================*/

   
    get fileIsFlagged(): boolean {
        return this.file?.flagStatus === FileFlagStatus.Flagged;
    }
    get lastGenerationDescription(): string | null{
        if(!this.classifiedLastGeneration) return null;
        return this.classifiedLastGeneration.predictedValue;
    }
    
    getPercentage(percent: number): string {
        if(!percent || percent < 0) return '0%';
        return (percent * 100).toFixed(2) + '%';
    }

}

