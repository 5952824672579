// <copyright file="file.types.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

export enum FileReaderState {
    EMPTY = 0,
    LOADING = 1,
    DONE = 2
}
