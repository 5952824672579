// <copyright file="object.utils.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { AnyObject } from '@/types/generic.types';

/**
 * Checks if value is an object and if it has a specific property
 * @param obj The value to assess
 * @param prop The property to check for
 */
export function hasOwnProperty(obj: any, prop: string): boolean {
    if(!obj || typeof obj !== 'object') return false;
    return Object.prototype.hasOwnProperty.call(obj, prop);
}

/**
 * Returns an the specified object key as a string
 * @param obj The object
 * @param expression Method that returns the relevant key
 */
export function getPropertyName<T extends AnyObject>(obj: T, expression: (obj: T) => any): string {
    let res: any = {};
    Object.keys(obj).map(k => { res[k] = () => k; });
    return expression(res)();
}

/**
 * Merges two objects of the same type for each defined value on the priority type
 * @param obj - The original object
 * @param priorityObj - The object to merge
 * @param strict - Does not count zeros and empty strings as defined
 */
export function mergeDefinedProperties<T extends object>(
    obj: T,
    priorityObj: T,
    strict: boolean = true
): T {
    let newObj: any = { ...obj };
    for(let [key, value] of Object.entries(priorityObj)) {
        if(typeof value === undefined) continue;
        if(typeof value === 'string' && !value && strict) continue;
        if(typeof value === 'number' && value === 0 && strict) continue;
        newObj[key] = (priorityObj as any)[key];
    }
    return newObj as T;
}

/**
 * Determines whether the given parameter is a valid date object
 * @param val - Any value
 * @returns {boolean} - Is a valid date object
 */
export function isValidDateObject(val: any): boolean {
    if(typeof val !== 'object') return false;
    return typeof val.getMonth === 'function';
}
