// <copyright file="index.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import routes from './routes';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { authorizationGuard, updateTitle } from './guards';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.afterEach(updateTitle);
router.beforeEach(authorizationGuard);

export default router;
