
import AquisitionMethodSelector from './AquisitionMethodSelector.vue';
import FileUploadForm from './FileUploadForm.vue';
import FileUploadProgressBar from './FileUploadProgressBar.vue';
import MxModal from '@/components/global/MxModal.vue';
import UploadFileMetadata from './UploadFileMetadata.vue';
import UploadProjectFiles from './UploadProjectFiles.vue';
import Vue from 'vue';
import { appConfig } from '@/config/app.config';
import { Component } from 'vue-property-decorator';
import {
    FileAquisitionMethod,
    IProject
} from '@/types/resource.types';
import { Get, Sync } from 'vuex-pathify';

@Component({
    name: 'UploadProjectFilesModal',
    components: {
        AquisitionMethodSelector,
        FileUploadForm,
        FileUploadProgressBar,
        MxModal,
        UploadFileMetadata,
        UploadProjectFiles
    }
})
export default class UploadProjectFilesModal extends Vue {

    /* Data
    ============================================*/

    aquisitionMethod: FileAquisitionMethod = FileAquisitionMethod.NotSet;
    notSet = FileAquisitionMethod.NotSet;
    directMethod = FileAquisitionMethod.DirectUpload;
    metadataMethod = FileAquisitionMethod.MetadataUpload;

    /* Computed
    ============================================*/

    @Get('create-project/project')
    readonly project: IProject;

    @Sync('create-project/fileList')
    fileList: File[];

    @Sync('create-project/uploading')
    uploading: boolean;

    get hasTooManyFiles(): boolean {
        return this.totalFiles > appConfig.UPLOADS.MAX_FILES;
    }

    get totalFiles(): number {
        let projectFileCount = this.project?.files?.length ?? 0;
        let fileCount = this.fileList?.length ?? 0;
        return projectFileCount + fileCount;
    }

    /* Methods
    ============================================*/

    closeModal(): void {
        this.$emit('close', false);
        this.fileList = [];
    }

}

