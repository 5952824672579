// <copyright file="events.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

export default {
    SESSION_TIMEOUT: 'SESSION_TIMEOUT',
    PERMISSION_ERROR: 'PERMISSION_ERROR',
    SERVER_ERROR: 'SERVER_ERROR'
};
