
import FileResultCard from './FileResultCard.vue';
import fileService from '@/services/ProjectFileService';
import MxInfoButton from '@/components/global/MxInfoButton.vue';
import MxModal from '@/components/global/MxModal.vue';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { handleInteractiveReportHtml } from '@/legacy/interactive-report';
import { IProjectFile } from '@/types/resource.types';
@Component({
    name: 'FileStructuralReportCard',
    components: {
        FileResultCard,
        MxInfoButton,
        MxModal
    }
})
export default class FileStructuralReportCard extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Object, required: false})
    readonly file: IProjectFile;
    @Prop({type: Boolean, required: false})
    loadData: boolean = false;

    /* Data
    ============================================*/

    reportHtml: string | null = '';
    reportClass: string = 'legacy-report-wrapper';
    showModal: boolean = false;

    /* Methods
    ============================================*/
    closeReport(){
        this.showModal = false;
        this.$emit('click:closeReport');
    }
    async loadReport() {
        if(this.reportHtml) {
            this.showModal = true;
            return;
        }
        try {
            this.$loading = true;
            let reportHtml = await fileService.getStructuralReport(this.file.id);
            this.showModal = true;
            this.parseReportHtml(reportHtml);
        } catch(e) {
            this.$logger.logError(e);
            this.$toast.error('Failed to load structural information. Please contact support');
        } finally {
            this.$loading = false;
        }
    }

    parseReportHtml(reportHtml: string) {
        let parser = new DOMParser();
        let doc = parser.parseFromString(reportHtml, 'text/html');
        let reportWrapper = doc.querySelector('.container-fluid > .wrap');
        this.reportHtml = reportWrapper?.outerHTML as string;
    }

    setupLegacyReport() {
        try {
            let el = window.document.getElementsByClassName(this.reportClass)[0];
            handleInteractiveReportHtml(el);
        } catch(e) {
            this.$logger.logError(e);
            this.$toast.error('Failed to load structural report. This error has been logged', {
                icon: 'mdi-alert',
            });
            this.showModal = false;
        }
    }
    async mounted(){
        console.log("Mounted");
        await this.loadReport();
    }
    /* Watchers
    ============================================*/

    @Watch('reportHtml')
    reportHtmlChanged(reportHtmlNew: string | null) {
        if(!reportHtmlNew) return;
        this.$nextTick(() => {
            this.setupLegacyReport();
        });
    }

}

