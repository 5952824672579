
import MxModal from '@/components/global/MxModal.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IFileStructure, IFileStructureData } from '@/types/resource.types';

@Component({
    name: 'FileStructureDataModal',
    components: {
        MxModal
    }
})
export default class FileStructureDataModal extends Vue {

    /* Props
    ============================================*/

    @Prop({ type: Object, required: true })
    readonly fileStructure: IFileStructure;

    @Prop({ type: Object, required: true })
    readonly fileStructureData: IFileStructureData;

    /* Data
    ============================================*/

    activeTab: number = 0;
    tabs: string[] = [
        'Text',
        'Hex',
        'Binary'
    ];

    /* Methods
    ============================================*/

    downloadData() {
        window.open(this.fileStructureData.url, '_blank');
    }

}

