// <copyright file="results.store.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { ActionTree, GetterTree, Module } from 'vuex';
import { IFileExaminationResult, IProject, IProjectFile, IProjectSummary } from '@/types/resource.types';
import { IRootState } from '@/types/store.types';
import { make } from 'vuex-pathify';

const state: IResultsState = {
    fileResult: null,
    projectSummary: null
};

const actions: ActionTree<IResultsState, IRootState> = {

};

const getters: GetterTree<IResultsState, any> = {
    file(state: IResultsState): IProjectFile | null {
        return state.fileResult?.file ?? null;
    },
    project(state: IResultsState): IProject | null {
        return state.projectSummary?.project ?? null;
    },
    groupId(state: IResultsState): number | undefined {
        return state.projectSummary?.project?.userGroupId;
    }
};

export default {
    namespaced: true,
    state,
    mutations: make.mutations(state),
    actions,
    getters
} as Module<IResultsState, IRootState>;


export interface IResultsState {
    fileResult: IFileExaminationResult | null;
    projectSummary: IProjectSummary | null;
}
