
import MxInfoButton from '@/components/global/MxInfoButton.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
    name: 'FileResultCardCollapse',
    components: {
        MxInfoButton
    }
})
export default class FileResultCardCollapse extends Vue {

    /* Props
    ============================================*/

    @Prop({type: String, required: true})
    readonly title: string;

    @Prop({type: Boolean, required: false})
    readonly loading: boolean;

    @Prop({type: Boolean, required: false})
    readonly noPadding: boolean;

    collapsed : Boolean = true;

    setCollapse(){
        this.collapsed = !this.collapsed;
    }
}

