// <copyright file="ProjectFile.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import { FileAquisitionMethod, FileFlagStatus, FileStatus, IProjectFile } from '@/types/resource.types';

export class ProjectFile implements IProjectFile {
    id: number;
    projectId: number;
    originalFilename: string;
    path: string;
    filename: string;
    aquisitionType: FileAquisitionMethod ;
    status: FileStatus;
    created: string;
    updated: string;
    uploadId: string;
    sha256Hash: string;
    flagStatus: FileFlagStatus.NotFlagged;
    signature1RefMatch: boolean = false;
    signature2RefMatch: boolean = false;
    embeddedModelDiscrepancy: boolean;

    constructor(projectId?: number, file?: File, type?: FileAquisitionMethod) {
        this.status = FileStatus.Draft;
        if(projectId) {
            this.projectId = projectId;
        }
        if(file) {
            this.filename = file.name;
            this.originalFilename = file.name;
        }
        if(type) {
            this.aquisitionType = type;
        }
    }

}
