// <copyright file="UserGroupService.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import ResourceService from './ResourceService';
import { appendQueryParams } from '@/utils/string.utils';
import { IAutocompleteResult, IUser, IUserGroup } from '@/types/resource.types';
import { IPaginatedRequest, IPaginatedResult } from '@/types/services.types';

export class UserGroupService extends ResourceService<IUserGroup> {

    constructor() {
        super('/usergroup');
    }

    public async getAll(): Promise<IUserGroup[]> {
        let res = await this.jsonClient.get<IUserGroup[]>('/usergroup');
        return res.data;
    }

    public async getUser(groupId: number, userId: number)
    : Promise<IUser> {
        let url = `/usergroup/${groupId}/user/${userId}`;
        let res = await this.jsonClient.fetch<IUser>(url);
        return res.data;
    }

    public async getUsers(query: IPaginatedRequest)
    : Promise<IPaginatedResult<IUser>> {
        let url = appendQueryParams(`/usergroup/${query.groupId}/user`, query);
        let res = await this.jsonClient.fetch<IPaginatedResult<IUser>>(url);
        return res.data;
    }

    public async addGroupUser(groupId: number, user: Partial<IUser>): Promise<void> {
        let url = `/usergroup/${groupId}/user/`;
        await this.jsonClient.post(url, user);
    }

    public async updateGroupUser(groupId: number, user: IUser): Promise<void> {
        let url = `/usergroup/${groupId}/user/${user.id}`;
        await this.jsonClient.put(url, user);
    }

    public async deleteGroupUser(groupId: number, userId: number): Promise<void> {
        await this.jsonClient.delete(`/usergroup/${groupId}/user/${userId}`);
    }

    public async searchAddableUsers(groupId: number, keyword: string): Promise<IAutocompleteResult[]> {
        keyword = encodeURIComponent(keyword);
        let url = `/usergroup/${groupId}/user/addable?keyword=${keyword}`;
        let res = await this.jsonClient.get<IAutocompleteResult[]>(url);
        return res.data;
    }

}

// Default "singleton" instance
export default new UserGroupService();
